import React, { useEffect, useRef, useState, useContext } from "react";
import "./ProductCustomise.css";
import { Button, Col, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import PhoneInput from "react-phone-input-2";
import { Uploader } from "uploader";
import { UploadButton } from "react-uploader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Breadcrumbs from "../Common-Component/Breadcrumbs/Breadcrumbs";
import {
  AddToCart,
  getCart,
  customiseAddTokart,
} from "../../utils/apis/customization/customization";
import { useParams } from "react-router-dom";
import parse from "html-react-parser";
import { Context } from "../../utils/context";
import Cookies from "js-cookie";
import { Link, useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import classNames from "classnames";
import { RegxExpression } from "../../utils/common";
import {
  getProductVariant,
  getVariant,
} from "../../utils/apis/Product/Product";
import { Material } from "../../utils/apis/master/Master";

const ProductCustomise = () => {
  const [value, setValue] = useState();
  const [selectedOption, setSelectedOption] = useState(null);

  const [customdesign, setCustomdesign] = useState(false);
  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];

  const handleClick = () => {
    setCustomdesign(true);
  };

  const uploader = Uploader({ apiKey: "free" });
  const uploadOptions = { multi: true };
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const { id } = useParams();
  const {
    signin,
    setSignin,
    shimmerLoader,
    setShimmerLoader,
    Select2Data,
    IMG_URL,
  } = useContext(Context);

  const [selectedFiles, setSelectedFiles] = useState([]);
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset,
    trigger,
  } = useForm();

  const [sellerId, setSellerId] = useState("");

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFiles([...selectedFiles, ...files]);
  };

  const handleRemoveImage = (index) => {
    const filteredFiles = selectedFiles.filter((_, i) => i !== index);
    setSelectedFiles(filteredFiles);
  };

  const [varient, setVarient] = useState();
  const existingCart = Cookies.get("customise_size");
  const [sizedata, setSize] = useState(
    existingCart ? JSON.parse(existingCart) : ""
  );
  const [total, setTotal] = useState(0);
  const [totalQuantity, setTotalQuantity] = useState(0);

  const calculateTotals = () => {
    let calculatedTotalPrice = 0;
    let calculatedTotalquantity = 0;
    if (sizedata?.size?.length > 0) {
      // Calculate total price and tax based on sizes and quantities
      sizedata?.size?.forEach((val1) => {
        if (val1 && val1.quantity !== 0) {
          calculatedTotalPrice +=
            parseFloat(varient?.s_price) * parseFloat(val1.quantity);
          calculatedTotalquantity += parseFloat(val1.quantity);
        }
      });
    } else {
      calculatedTotalPrice += parseFloat(varient?.s_price);
      calculatedTotalquantity += parseFloat(1);
    }
    const formattedTotalPrice = parseFloat(calculatedTotalPrice.toFixed(8));
    setTotalQuantity(calculatedTotalquantity);
    setTotal(calculatedTotalPrice);
  };

  useEffect(() => {
    calculateTotals();
  }, [sizedata?.length > 0]);

  const getSinglevarient = async () => {
    const res = await getVariant(id);
    if (res?.success) {
      setVarient(res?.data);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };
  useEffect(() => {
    getSinglevarient();
  }, [id]);

  const onSubmit = async (data) => {
    setShimmerLoader(true);
    const formData = new FormData();

    formData.append("product_id", varient?.product?.id);
    formData.append("variant_id", varient?.id);
    formData.append("material_id", data?.material_id?.value);
    formData.append("sizes", JSON.stringify(sizedata?.size));
    formData.append(
      "custom_price",
      varient?.product?.product_detail?.customise_price
    );
    formData.append("requirement", data?.requirement);
    formData.append("company_name", data?.company_name);
    formData.append("name", data?.name);
    formData.append("email", data?.email);
    formData.append("contact_no", data?.contact_no);
    if (selectedFiles.length > 0) {
      selectedFiles?.map((image, index) => {
        formData.append(`images[${index}]`, image);
      });
    }
    const res = await customiseAddTokart(formData);
    if (res?.success) {
      await Cookies.remove("customise_size");
      navigate("/mycart");
    } else {
      if (res?.message?.gst_number) {
        setError("gst_number", { message: res?.message?.gst_number });
      }
    }
  };

  const handleimageClick = (event) => {
    event.preventDefault();
    fileInputRef.current.click();
  };

  const [materialdata, setMaterialdata] = useState();
  const GetAllMaterial = async () => {
    const response = await Material();

    if (response?.success) {
      setMaterialdata(await Select2Data(response?.data, "material_id"));
    }
  };
  useEffect(() => {
    GetAllMaterial();
  }, []);

  const items = [
    { id: 1, text: "HOME", link: "/home" },
    { id: 2, text: "PROMOTIONAL PRODUCTS", link: "/shop-promotional" },
    {
      id: 3,
      text: "PRODUCT DETAILS",
      link: `/productDetails/${varient?.product?.id}`,
    },
    { id: 4, text: "CUSTOMISATION" },
  ];

  return (
    <>
      <section className="productCustomise">
        <div className="container">
          <Breadcrumbs items={items} />
          <div className="customiseMain">
            <div className="row">
              <div className="col-lg-3 col-md-5">
                <div className="customiseLeft">
                  <div className="productImg">
                    <img src={IMG_URL + varient?.product_images[0]?.image} />
                  </div>

                  <div className="productTitle">
                    <h3>
                      <strong>{varient?.product?.name}</strong>
                    </h3>
                  </div>

                  <div className="productdetailMain">
                    {/* {sizedata?.size
                                            ?.filter(val => val?.quantity > 0) // Filter out sizes with quantity 0
                                            .map((val, index) => (
                                                <div key={index} className='productdetail'>
                                                    <p>{val?.cloth_size_name} x {val?.quantity}</p>
                                                    <p>₹ {varient?.s_price *  (val?.quantity ?? 0)}</p>
                                                </div>
                                            ))
                                        } */}
                    {Array.isArray(sizedata?.size) &&
                    sizedata?.size.length > 0 ? (
                      sizedata?.size
                        ?.filter((val) => val?.quantity > 0) // Filter out sizes with quantity 0
                        .map((val, index) => (
                          <div key={index} className="productdetail">
                            <p>
                              {val?.cloth_size_name} x {val?.quantity}
                            </p>
                            <p>₹ {varient?.s_price * (val?.quantity ?? 0)}</p>
                          </div>
                        ))
                    ) : (
                      <div className="productdetail">
                        <p>₹ {varient?.s_price}</p>
                      </div>
                    )}

                    {/* { sizedata?.size.length>0 ? (
                                            sizedata?.size
                                            ?.filter(val => val?.quantity > 0) // Filter out sizes with quantity 0
                                            .map((val, index) => (
                                                <div key={index} className='productdetail'>
                                                    <p>{val?.cloth_size_name} x {val?.quantity}</p>
                                                    <p>₹ {varient?.s_price *  (val?.quantity ?? 0)}</p>
                                                </div>
                                            ))
                                            ) : (
                                                <div className='productdetail'>
                                                   
                                                    <p>₹ {varient?.s_price }</p>
                                                </div>
                                            )} */}
                    <div className="productdetail">
                      <div>
                        <p className="mb-0">SETUP CHARGES</p>
                        <span>(Applicable on your added image)</span>
                      </div>
                      <p>
                        ₹
                        {varient?.product?.product_detail?.customise_price *
                          (totalQuantity ?? 0)}
                      </p>
                    </div>
                    <div className="productdetail">
                      <p>
                        <strong>Total</strong>
                      </p>
                      <p>
                        {(isNaN(parseFloat(varient?.s_price))
                          ? 0
                          : parseFloat(varient?.s_price) +
                            (isNaN(
                              varient?.product?.product_detail?.customise_price
                            )
                              ? 0
                              : parseFloat(
                                  varient?.product?.product_detail
                                    ?.customise_price
                                ))) *
                          (isNaN(totalQuantity) ? 0 : totalQuantity)}
                      </p>
                      {/* <p><strong> ₹{parseFloat(total) + (parseFloat(varient?.product?.product_detail?.customise_price) * parseFloat(totalQuantity))}</strong></p> */}
                    </div>

                    <div className="addtoCartsec">
                      {/* <Link to={"/mycart"}> */}
                      <Button
                        className="addtocartbtn"
                        onClick={handleSubmit(onSubmit)}
                      >
                        Add to cart
                      </Button>
                      {/* </Link> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-9 col-md-7">
                <>
                  <div className="customiseRight">
                    <div className="customiseHead">
                      <h3>customise Your T-Shirt</h3>
                    </div>

                    <div className="formRow">
                      <Form onSubmit={handleSubmit(onSubmit)}>
                        {/* <div className='formRowhead'>
                                                    <h3>Nike Dri-FIT Legend Tee - Men's</h3>
                                                    <p>₹ 1,962.00</p>
                                                </div> */}
                        <Row>
                          <Col lg={6} md={6}>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Control
                                type="text"
                                name="name"
                                placeholder="Enter First Name"
                                {...register("name", {
                                  required: "name required",
                                })}
                                className={classNames("", {
                                  "is-invalid": errors?.name,
                                  "is-valid": getValues("name"),
                                })}
                                onKeyDown={(event) => {
                                  if (!RegxExpression.name.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                              />
                            </Form.Group>
                          </Col>
                          <Col lg={6} md={6}>
                            <Controller
                              name="contact_no"
                              control={control}
                              rules={{
                                required: "Field is required",
                                minLength: {
                                  value: 10,
                                  message:
                                    "Number should be at least 10 characters",
                                },
                              }}
                              render={({ field }) => (
                                <PhoneInput
                                  country={"in"}
                                  {...field}
                                  className={classNames("", {
                                    "is-invalid": errors?.contact_no,
                                    "is-valid": getValues("contact_no"),
                                  })}
                                  onChange={(value) => field.onChange(value)}
                                  type="text"
                                  placeholder="Enter Mobile Number"
                                  onKeyDown={(event) => {
                                    if (!RegxExpression.phone.test(event.key)) {
                                      if (event.key !== "Backspace") {
                                        event.preventDefault();
                                      }
                                    }
                                  }}
                                  maxLength={10}
                                />
                              )}
                            />
                            {errors?.contact_no && (
                              <sup className="text-danger">
                                {errors?.contact_no?.message}
                              </sup>
                            )}
                          </Col>
                          <Col lg={6} md={6}>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Control
                                type="text"
                                name="email"
                                placeholder="Enter Email"
                                {...register("email", {
                                  required: "Email is required",
                                  pattern: {
                                    value: RegxExpression.email,
                                    message: "Invalid email address",
                                  },
                                })}
                                className={classNames("", {
                                  "is-invalid": errors?.email,
                                  "is-valid": getValues("email"),
                                })}
                              />
                            </Form.Group>
                          </Col>
                          <Col lg={6} md={6}>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Control
                                type="text"
                                name="company_name"
                                placeholder="Enter Store Name"
                                {...register("company_name", {
                                  required: "company_name required",
                                })}
                                className={classNames("", {
                                  "is-invalid": errors?.company_name,
                                  "is-valid": getValues("company_name"),
                                })}
                              />
                            </Form.Group>
                          </Col>
                          <Col lg={6} md={6}>
                            <Controller
                              name="material_id" // name of the field
                              {...register("material_id", {
                                required: "Select material",
                              })}
                              control={control}
                              render={({ field }) => (
                                <Select
                                  styles={{
                                    control: (baseStyles) => ({
                                      ...baseStyles,
                                      borderColor: errors.material_id
                                        ? "red"
                                        : baseStyles,
                                    }),
                                  }}
                                  {...field}
                                  options={materialdata}
                                />
                              )}
                            />

                            {errors.material_id && (
                              <span className="text-danger">
                                {errors.material_id.message}
                              </span>
                            )}
                          </Col>
                        </Row>

                        <div className="customCheck">
                          <Form.Label className="plus-btn me-5">
                            <div
                              className="uploadDiv"
                              onClick={handleimageClick}
                            >
                              <FontAwesomeIcon icon="fa-solid fa-circle-plus" />
                              <p className="mb-1">Upload here</p>
                            </div>
                            <Form.Control
                              ref={fileInputRef}
                              type="file"
                              onChange={handleFileChange}
                              multiple
                              accept="image/"
                              style={{ display: "none" }}
                            />
                          </Form.Label>

                          <div className="image-holder">
                            {selectedFiles.length > 0 &&
                              selectedFiles.map((file, index) => (
                                <div className="file-img-holder" key={index}>
                                  <object
                                    className="img-logo"
                                    data={URL.createObjectURL(file)}
                                    type={file.type}
                                    width="59" // Set the width
                                    height="58" // Set the height
                                  />
                                  <div
                                    className="cross-overlay"
                                    onClick={() => handleRemoveImage(index)}
                                  >
                                    <img
                                      className="img-cross"
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/assest/image/Careers/crossIcon.png"
                                      }
                                      alt={`Cross Icon ${index + 1}`}
                                    />
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>

                        <div className="specialRequest">
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlTextarea1"
                          >
                            <Form.Label className="mb-0">
                              Add Special Requests
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="requirement"
                              placeholder="Enter requirement"
                              {...register("requirement", {
                                required: "requirement is required",
                              })}
                              className={classNames("", {
                                "is-invalid": errors?.requirement,
                                "is-valid": getValues("requirement"),
                              })}
                            />
                          </Form.Group>
                        </div>

                        <div className="addtocartsec text-center">
                          <p>(*Add Special Requests and Submit)</p>
                          {/* <Link to={"/mycart"}> */}
                          <Button className="btn" type="submit">
                            Submit & Add to Cart
                          </Button>

                          {/* </Link> */}
                        </div>
                      </Form>
                    </div>
                  </div>
                </>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProductCustomise;
