

import { getData } from "../../api";

// Blog
export const blog = async (page = '') => {
    try {
        return await getData(`/blog?page=${page}`);
    } catch (error) {
        console.log(error);
    }
};

export const blogDetails = async (id) => {
    try {
        return await getData(`/blog/${id}`);
    } catch (error) {
        console.log(error);
    }
};

// Faq

export const faqCategory = async (id) => {
    try {
        return await getData(`/faq`);
    } catch (error) {
        console.log(error);
    }
};

export const FaqCategoryData = async (id) => {
    try {
        return await getData(`/faq`);
    } catch (error) {
        console.log(error);
    }
}

export const FaqData = async (id) => {
    try {
        return await getData(`/faq/${id}`);
    } catch (error) {
        console.log(error);
    }
}

export const FaqFAqData = async (id) => {
    try {
        return await getData(`/faq/all/faq`);
    } catch (error) {
        console.log(error);
    }
}


