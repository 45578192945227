import React, { useEffect, useRef, useState, useContext } from "react";
import Table from "react-bootstrap/Table";
import "./ProdcuctOrder.css";
import Breadcrumbs from "../../Common-Component/Breadcrumbs/Breadcrumbs";
import {
  AddToCart,
  getCart,
  deleteCart,
} from "../../../utils/apis/addToCart/addToCart";
import { useParams } from "react-router-dom";
import parse from "html-react-parser";
import { Context } from "../../../utils/context";
import Cookies from "js-cookie";
import { Link, useNavigate } from "react-router-dom";
import { CartQuantity } from "../../../utils/apis/addToCart/addToCart";
const ProductOrder = ({
  data,
  setDeletecart,
  deletecart,
  setCart,
  cart,
  cartGet,
  withoutCart,
  calculateTotals,
}) => {
  const { IMG_URL, signin } = useContext(Context);

  const [quantity, setQuantity] = useState(1);
  const navigate = useNavigate();
  const handleMinus = (e) => {
    e.preventDefault();
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const handlePlus = (e) => {
    e.preventDefault();
    setQuantity(quantity + 1);
  };

  const getCartQuantity = async (id, type, size_id) => {
    // setDiscount(0);
    // setCoupon();
    // setCouponStatus(false);
    // setError('code', "");

    await CartQuantity(id, type, size_id);
    if (Cookies.get("coinz_kart_cart")) {
      if (JSON.parse(Cookies.get("coinz_kart_cart")).length === 0) {
        navigate("/shop-now");
      }
      setCart(JSON.parse(Cookies.get("coinz_kart_cart")));
      withoutCart();
    }
    if (signin) {
      await cartGet();
    }
    calculateTotals();
    // triggerCartUpdate()
  };
  return (
    <section className="product-order">
      <Table responsive>
        <thead>
          <tr>
            <th>PRODCUT/S</th>
            <th>QUANTITY</th>
            <th>PRICE</th>
            <th>SUBTOTAL</th>
          </tr>
        </thead>

        {signin ? (
          <>
            {data?.data?.map((val, index) => (
              <tbody className="tableBody">
                {/* <img src={process.env.PUBLIC_URL + "/assest/image/Careers/crossIcon.png"} className='crossIcon'/> */}
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/assest/image/Careers/crossIcon.png"
                  }
                  className="crossIcon"
                  onClick={() => setDeletecart({ id: val.id })}
                />
                <div className="row me-0 ms-0">
                  <div className="col-lg-4 col-md-3 col-8">
                    <div className="white-bgg me-4">
                      <img
                        src={
                          IMG_URL +
                          val?.product_variant?.product_images[0]?.image
                        }
                        className="pro-img"
                      />
                    </div>
                  </div>
                  <div className="col-lg-5 col-md-9 col-12">
                    <div className="text-start">
                      <p className="pro-name">{val?.product?.name}</p>
                      <p className="colour-name">
                        Colour : {val?.product_variant?.v_name}
                      </p>

                      {val?.cloth_size?.name && (
                        <p className="size-name">
                          Size : {val?.cloth_size?.name}
                        </p>
                      )}
                      {val?.customise?.name && (
                        <p className="size-name" style={{ color: "blue" }}>
                          customise
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                {/* <td>{val?.quantity || 0} Pcs</td> */}
                <td>
                  <div className="increment-decrement-holder">
                    <div className="quantity">
                      <div
                        href="#"
                        className="quantity__minus"
                        // onClick={handleMinus}
                        onClick={async () => {
                          await getCartQuantity(val?.id, "minus");
                          await cartGet();
                        }}
                      >
                        <span>-</span>
                      </div>
                      <input
                        name="quantity"
                        type="text"
                        className="quantity__input"
                        value={val?.quantity}
                        readOnly
                      />
                      <div
                        href="#"
                        className="quantity__plus"
                        onClick={async () => {
                          await getCartQuantity(val?.id, "add");
                          await cartGet();
                        }}
                        // onClick={handlePlus}
                      >
                        <span>+</span>
                      </div>
                    </div>
                  </div>
                </td>
                <td>₹ {val?.product_variant?.s_price}</td>
                <td>₹ {val?.product_variant?.s_price * val?.quantity}</td>
              </tbody>
            ))}
          </>
        ) : (
          <>
            {/* {data?.map((val, index) => { */}
            {Array.isArray(data) &&
              data.map((val, index) => {
                if (val?.sizes?.length > 0) {
                  return val.sizes
                    .filter((val1) => val1.quantity !== 0) // Filter sizes where quantity is not 0
                    .map((val1, index1) => (
                      <tbody className="tableBody">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assest/image/Careers/crossIcon.png"
                          }
                          className="crossIcon"
                          onClick={() =>
                            setDeletecart({
                              id: val.id,
                              size_id: val1?.cloth_size_id,
                            })
                          }
                          alt="Delete Item"
                        />
                        <div className="row">
                          <div className="col-lg-4 col-md-3 col-8">
                            <div className="white-bgg me-4">
                              <img
                                src={IMG_URL + val?.image}
                                className="pro-img"
                              />
                            </div>
                          </div>
                          <div className="col-lg-8 col-md-9 col-12">
                            <div className="text-start">
                              <p className="pro-name">{val?.name}</p>
                              <p className="colour-name">
                                Colour : {val?.v_name}
                              </p>

                              {val1?.cloth_size_name && (
                                <p className="size-name">
                                  Size : {val1?.cloth_size_id}
                                  {/* Size : {val1?.cloth_size_name} */}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>

                        <td>
                          <div className="increment-decrement-holder">
                            <div className="quantity">
                              <div
                                href="#"
                                className="quantity__minus"
                                onClick={async () => {
                                  await getCartQuantity(
                                    val?.id,
                                    "minus",
                                    val1?.cloth_size_id
                                  );
                                  await cartGet();
                                }}
                              >
                                <span>-</span>
                              </div>
                              <input
                                name="quantity"
                                type="text"
                                className="quantity__input"
                                value={val1?.quantity}
                                readOnly
                              />
                              <div
                                href="#"
                                className="quantity__plus"
                                onClick={async () => {
                                  await getCartQuantity(
                                    val?.id,
                                    "add",
                                    val1?.cloth_size_id
                                  );
                                  await cartGet();
                                }}
                              >
                                <span>+</span>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>₹ {val?.price}</td>
                        <td>₹ {val?.price * val1?.quantity}</td>
                      </tbody>
                    ));
                } else {
                  return (
                    <tbody className="tableBody">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assest/image/Careers/crossIcon.png"
                        }
                        className="crossIcon"
                        onClick={() => setDeletecart({ id: val?.id })}
                      />
                      <div className="row">
                        <div className="col-lg-4 col-md-3 col-8">
                          <div className="white-bgg me-4">
                            <img
                              src={IMG_URL + val?.image}
                              className="pro-img"
                            />
                          </div>
                        </div>
                        <div className="col-lg-8 col-md-9 col-12">
                          <div className="text-start">
                            <p className="pro-name">{val?.name}</p>
                            <p className="colour-name">
                              Colour : {val?.v_name}
                            </p>
                          </div>
                        </div>
                      </div>

                      <td>
                        <div className="increment-decrement-holder">
                          <div className="quantity">
                            <div
                              href="#"
                              className="quantity__minus"
                              onClick={async () => {
                                await getCartQuantity(val?.id, "minus");
                                await cartGet();
                              }}
                            >
                              <span>-</span>
                            </div>
                            <input
                              name="quantity"
                              type="text"
                              className="quantity__input"
                              value={val?.quantity}
                              readOnly
                            />
                            <div
                              href="#"
                              className="quantity__plus"
                              onClick={async () => {
                                await getCartQuantity(val?.id, "add");
                                await cartGet();
                              }}
                            >
                              <span>+</span>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>₹ {val?.price}</td>
                      <td>₹ {val?.price * val?.quantity}</td>
                    </tbody>
                  );
                }
              })}
          </>
        )}
      </Table>
    </section>
  );
};

export default ProductOrder;
