import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";

export const Category = async () => {
  try {
    return await getData(`/without-login/allcategories`);
  } catch (error) {
    console.log(error);
  }
};

export const SubCategory = async (id) => {
  try {
    return await getData(`/without-login/allsubcategories/${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const singleSubCategory = async (id) => {
  try {
    return await getData(`/without-login/subcategory/${id}`);
  } catch (error) {
    console.log(error);
  }
};
export const ChildSubCategory = async (id) => {
  try {
    return await getData(`/without-login/allchildsubcategories/${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const popularCategory = async () => {
  try {
    return await getData(`/without-login/all-pupular-category`);
  } catch (error) {
    console.log(error);
  }
};

export const Products = async (page = '', category_id = "", sub_category_id = "", child_sub_category_id = "", seller_id = "", brand_id = "", material_id = "", occasion_id = "", price_value = "", sustainable = "", promotional = "", product_id = '', productName = '', occationId = '', custom = '',quantity='',noMinQty='',sortOption='') => {

  try {
    let url = `/product?page=${page}`;

    // Append parameters based on their presence
    if (productName) {
      url += `&productName=${encodeURIComponent(productName)}`;
    }
    if (promotional) {
      url += `&promotional=${encodeURIComponent(promotional)}`;
    }
    if (sustainable) {
      url += `&sustainable=${encodeURIComponent(sustainable)}`;
    }
    if (category_id) {
      url += `&category_id=${encodeURIComponent(category_id)}`;
    }
    if (sub_category_id) {
      url += `&sub_category_id=${encodeURIComponent(sub_category_id)}`;
    }
    if (child_sub_category_id) {
      url += `&child_sub_category_id=${encodeURIComponent(child_sub_category_id)}`;
    }
    if (seller_id) {
      url += `&seller_id=${encodeURIComponent(seller_id)}`;
    }
    if (brand_id) {
      url += `&brand_id=${encodeURIComponent(brand_id)}`;
    }
    if (material_id) {
      url += `&material_id=${encodeURIComponent(material_id)}`;
    }
    if (occasion_id) {
      url += `&occasion_id=${encodeURIComponent(occasion_id)}`;
    }
    if (price_value) {
      const { min, max } = price_value;
      if (min !== undefined && max !== undefined) {
        url += `&minprice=${encodeURIComponent(min)}&maxprice=${encodeURIComponent(max)}`;
      }
    }
    if (product_id) {
      url += `&product_id=${encodeURIComponent(product_id)}`;
    }
    if (occationId) {
      url += `&occasion_id=${encodeURIComponent(occationId)}`;
    }
    if (custom) {
      url += `&custom=${encodeURIComponent(custom)}`;
    }
    if (quantity) {
      url += `&quantity=${encodeURIComponent(quantity)}`;
    }
    if (noMinQty) {
      url += `&noMinQty=${encodeURIComponent(noMinQty)}`;
    }
    if (sortOption) {
      url += `&sortOption=${encodeURIComponent(sortOption)}`;
    }

    // Return the result from the API call
    return await getData(url);
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};


// export const Products = async (page = '', category_id = "", sub_category_id = "", child_sub_category_id = "", seller_id = "", brand_id = "", material_id = "", occasion_id = "", price_value = "", sustainable = "", promotional = "", product_id = '', productName, occationId,custom) => {

//   try {
//     let url = `/product?page=${page}`;
//     if (productName) {
//       url += `&productName=${productName}`;
//     } else
//       if (promotional) {
//         url += `&promotional=${promotional}`;
//       } else if (sustainable) {
//         url += `&sustainable=${sustainable}`;
//       } else
//         if (category_id && sub_category_id && child_sub_category_id && seller_id) {
//           url += `&category_id=${category_id}&sub_category_id=${sub_category_id}&child_sub_category_id=${child_sub_category_id}&seller_id=${seller_id}`;
//         } else if (brand_id && sub_category_id) {
//           url += `&sub_category_id=${sub_category_id}&brand_id=${brand_id}`;
//         } else if (occasion_id && sub_category_id) {
//           url += `&sub_category_id=${sub_category_id}&occasion_id=${occasion_id}`;
//         } else if (material_id && sub_category_id) {
//           url += `&sub_category_id=${sub_category_id}&material_id=${material_id}`;
//         } else if (price_value && sub_category_id) {
//           url += `&sub_category_id=${sub_category_id}&minprice=${price_value?.min}&maxprice=${price_value?.max}`;
//         }
//         else if (category_id) {
//           url += `&category_id=${category_id}`;
//         } else if (sub_category_id) {
//           url += `&sub_category_id=${sub_category_id}`;
//         } else if (child_sub_category_id) {
//           url += `&child_sub_category_id=${child_sub_category_id}`;
//         } else if (product_id) {
//           // product_id.forEach(id => queryParams.push(`product_id=${id}`));
//           url += `&product_id=${product_id}`;
//         } else if (occationId) {
//           url += `&occasion_id=${occationId}`;
//         }
//         else if (custom) {
//           url += `&custom=${custom}`;
//         }


//     if (seller_id) {
//       url += `&seller_id=${seller_id}`;
//     }
//     return await getData(url);
//   } catch (error) {
//     console.log(error);
//   }
// };

export const getProductDetail = async (id) => {
  try {
    return await getData(`/without-login/product/${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const getCategoryProduct = async (id) => {
  try {
    return await getData(`/product/category-product/${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const getAllCategoryProduct = async (id) => {
  try {
    return await getData(`/product/category-product`);
  } catch (error) {
    console.log(error);
  }
};

export const getProductVariant = async (id) => {
  try {
    return await getData(`/product/variants/${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const getVariant = async (id) => {
  try {
    return await getData(`/product/variant/${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const allProductReviewGet = async (id) => {
  try {
    return await getData(`/without-login/home/show/${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const productReviewGet = async (id) => {
  try {
    return await getData(`/review/show/${id}`);
  } catch (error) {
    console.log(error);
  }
}; 

export const productReviewPost = async (data) => {
  try {
    return await postData(`/review/add`, data);
  } catch (error) {
    console.log(error);
  }
};


export const productList = async () => {
  try {
    return await getData(`/without-login/product`);
  } catch (error) {
    console.log(error);
  }
};


