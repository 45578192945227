import React from "react";
import { Button, Offcanvas } from "react-bootstrap";
import "./Cookies_popup_modal.css"; // Make sure this is the correct path to your CSS file

const Cookies_popup_modal = ({
  text,
  onHide,
  show,
  onAcceptCookies,
  setIsCookieAccepted,
  isCookieAccepted,
  ...props
}) => {
  const handleAcceptClick = () => {
    localStorage.setItem('cookieAccepted', 'true');
    setIsCookieAccepted(true);
    onAcceptCookies(); // Handle acceptance of cookies
    onHide(); // Hide the offcanvas
  };

  return (
    <>
      <Offcanvas
        {...props}
        show={show}
        onHide={onHide}
        placement="bottom"
        className="cookies-offcanvas logOutOffcanvas"
      >
        <Offcanvas.Body>
          <section className="LogoutMain">
            <div className="logoutinfo text-center">
              <p>
                We use cookies to enhance your browsing experience, serve
                personalized ads or content, and analyze our traffic. By
                clicking "Accept All", you consent to our use of cookies .{" "}
                {/* <span>Cookie Policy</span> */}
              </p>
            </div>
            <div className="yesNobtnMain">
              <Button className="btn yesNobtn" onClick={handleAcceptClick}>
                Accept All
              </Button>
              {/* Uncomment this if you want a reject button */}
              <Button className="btn yesNobtn" onClick={onHide}>
                Reject All
              </Button>
            </div>
          </section>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default Cookies_popup_modal;
