import React, { useState, useContext } from 'react';
import { Button, Form } from 'react-bootstrap';
// import './Login.css';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import { Link } from 'react-router-dom';
import SuccessfullyModal from '../../Common-Component/Commonmodal/SuccessfullyModal/SuccessfullyModal';
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import classNames from "classnames";
import { RegxExpression } from "../../../utils/common";
import { CheckExist, emailOtpGet } from "../../../utils/apis/myAccount/myAccount";
import { ChangePasswordWL, emailOtpVerify,ResetPassword } from "../../../utils/apis/myAccount/myAccount";
import { Context } from "../../../utils/context";
import PasswordChecklist from "react-password-checklist";
const ForgetPassword = ({email}) => {

    const {
        control,
        register,
        handleSubmit,
        getValues,
        setError,
        clearErrors,
        formState: { errors },
        reset,
        trigger,
    } = useForm();
    const [newpass, setNewpass] = useState(true);
    const [password, setPassword] = useState("");
    const [passwordAgain, setPasswordAgain] = useState("");
    const [resetModal, setResetmodal] = useState(false);
    const resetClose = () => {
        setResetmodal(true);
        setTimeout(() => {
            setResetmodal(false);
            window.location = "/home"
        }, 3000)
    }


    const [showPassword, setShowPassword] = useState(false);
    const [showConformPassword, setShowConformPassword] = useState(false);
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);

    };
    const toggleConformPasswordVisibility = () => {

        setShowConformPassword(!showConformPassword)
    };

    const onSubmit = async (data) => {      
        if (password === passwordAgain) {
            data.email = email
            const res = await ResetPassword(data);
            if (res?.success) {
                resetClose();
            } else {

            }
        } else {
            setError("re_password", { message: "Password Does not match" });
        }


    };
    return (
        <div>
            <div className='setNewpass'>
                <h3>Set New Password</h3>
            </div>
            <p className='firstparatxt'>Your new password must be different to previously used password.</p>
            <Form className='resetForm'>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Enter Password</Form.Label>
                    <div className='password-container'>
                        <Form.Control
                            type={showPassword ? "text" : "password"}
                            placeholder="Enter Password"
                            name="password"
                            {...register("password", {
                                required: "Password is required",
                                minLength: {
                                    value: 8,
                                    message:
                                        "Password must be at least 8 characters long",
                                },
                                pattern: {
                                    value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                                    message:
                                        "Password must contain at least one uppercase letter, one lowercase letter, and one number",
                                },
                            })}
                            onChange={(e) => setPassword(e.target.value)}
                        />


                        <span className="password-icon" onClick={togglePasswordVisibility}> {showPassword ? "🙈" : "👁️"}</span>
                    </div>
                </Form.Group>

                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Confirm New Password</Form.Label>
                    <div className='password-container'>
                        {/* <Form.Control type="email" placeholder="Enter Password to Confirm" />
                        <span className="password-icon">👁️</span> */}
                        <Form.Control
                            type={showConformPassword ? "text" : "password"}
                            placeholder="Re-Enter Password"
                            name="re_password"
                            {...register("re_password", {
                                required: "Re-Password is required",
                            })}
                            onChange={(e) => setPasswordAgain(e.target.value)}
                        />
                        {/* {errors?.re_password && (
                            <sup className="text-danger">
                                {errors?.re_password?.message}
                            </sup>
                        )} */}
                           <span className="password-icon" onClick={toggleConformPasswordVisibility}> {showConformPassword ? "🙈" : "👁️"}</span>
                        {password && (
                            <PasswordChecklist
                                rules={[
                                    "minLength",
                                    "specialChar",
                                    "number",
                                    "capital",
                                    "match",
                                ]}
                                minLength={8}
                                value={password}
                                valueAgain={passwordAgain}
                                onChange={(isValid) => { }}
                                style={{
                                    fontSize: "10px",
                                    padding: "4px",
                                    margin: "0",
                                }}
                            />
                        )}
                      
                        <div
                            className="password-icon"
                            onClick={toggleConformPasswordVisibility}
                        >
                            {showConformPassword ? "🙈" : "👁️"}
                        </div>
                    </div>
                </Form.Group>

                <div className='submitbtnsec text-center'>
                    <Button type="submit" className="btn" onClick={handleSubmit(onSubmit)}>Reset Password</Button>
                </div>

                {/* <div className='canceltxtsec text-center'>
                    <p>Didn't receive code? <span style={{ color: "#234C9E" }}>Click to resend</span></p>
                </div> */}

            </Form>
            <SuccessfullyModal show={resetModal} onHide={() => setResetmodal(false)} text={"Password Reseted Successfully"} />

        </div>

    );
};

export default ForgetPassword;
