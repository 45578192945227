import React from 'react'
import CustomizableProduct from '../CustomizableProduct/CustomizableProduct'

const SustainableProduct = () => {
    
    return (
        <>
            <CustomizableProduct heading={"Promotional Product"} promotional={"promotional"}/>
        </>
    )
}

export default SustainableProduct