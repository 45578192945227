import React, { useEffect, useRef, useState, useContext } from 'react'
import CustomizableProduct from '../CustomizableProduct/CustomizableProduct'
import {Products} from "../../utils/apis/Product/Product";
import { useParams } from 'react-router-dom';
import parse from "html-react-parser";
import { Context } from "../../utils/context";
const SustainableProduct = () => {
    const [data, setData] = useState();
    const { id } = useParams();
    const {  IMG_URL } = useContext(Context);
    const getAllData = async () => {
        const res = await Products('',id);
        if (res?.success) {
            setData(res?.data);
        } else {
            console.error('Error fetching  data:', res?.error);
        }
    }
  
    useEffect(() => {
        getAllData();
    }, []);
    return (
        <>
            <CustomizableProduct heading={"Sustainable Product"} sustainable={"sustainable"}  />
        </>
    )
}

export default SustainableProduct