import React from 'react'
import CustomizableProduct from '../CustomizableProduct/CustomizableProduct'

const FestiveGifts = () => {
    return (
        <>
            <CustomizableProduct heading={"Festive Gifts"}/>
        </>
    )
}

export default FestiveGifts