import React, { useEffect, useState,useContext } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Popular_Product_Card from '../../Common-Component/Popular_Product_Card/Popular_Product_Card'
import { Link } from 'react-router-dom'
import { sustainable_products } from '../../../utils/apis/Home/Homeapi'
import { Context } from "../../../utils/context";
const SustainableProduct = ({sustainProduct}) => {
   
    const { IMG_URL } = useContext(Context);
    const [length, setLength] = useState(2)
   
    return (
        <>
            <section className='promotional-product'>
                <Container>
                    <Row>
                        <div className='heading-holder'>
                            <div className='d-flex'>
                                <h2 className='first-heading'>Shop</h2>
                                <h3 className='second-heading'>Sustainable Products</h3>
                            </div>
                            <span className='text-para'>Empower Your Workspace: Stationery That Inspires Excellence</span>
                        </div>
                        {sustainProduct && sustainProduct.length > 0 && sustainProduct[0] && sustainProduct[0][0] && sustainProduct[0][0].product_variants && (
                                <Col lg={6}>
                                    <Popular_Product_Card
                                        className="main-section"
                                        image={IMG_URL + sustainProduct[0][0].product_variants[0]?.first_image}
                                        imgClassName="seller-img"
                                        content="VIEW ALL"
                                        textClassName="local-text"
                                        overlaycolor="bg-overlay"
                                        link="/sustainable-product"
                                    />
                                </Col>
                            )}
                        <Col lg={6}>
                            <Row>
                            {sustainProduct?.slice(0, 2).map((row, rowIndex) => (
                                        <React.Fragment key={rowIndex}>
                                            {row.map((product, colIndex) => (                                                 
                                                <Col key={colIndex} lg={6} md={6} sm={6} xs={6}>
                                                    {/* {product?.product_variants?.map((variant, variantIndex) => ( */}
                                                    <Popular_Product_Card
                                                        key={colIndex}
                                                        className="main-section"
                                                        image={IMG_URL + product?.product_variants[0]?.first_image}
                                                        imgClassName="seller-img"
                                                        overlaycolor="bg-overlay-one"
                                                        mainimagesec="image-box"
                                                        icon="fa-solid fa-arrow-right"
                                                        link={`/productDetails/${product?.id}?type=Sustainable Products`}
                                                    />
                                                    {/* ))} */}
                                                </Col>
                                            ))}
                                        </React.Fragment>
                                    ))}
                                {/* <Col lg={6} md={6} sm={6} xs={6}>
                                    <Popular_Product_Card
                                        className="main-section "
                                        image={process.env.PUBLIC_URL + "/assest/image/home/image-jacket.png"}
                                        imgClassName="seller-img"
                                        overlaycolor="bg-overlay-one"
                                        mainimagesec="image-box"
                                        icon="fa-solid fa-arrow-right"
                                    />
                                    <Popular_Product_Card
                                        className="main-section "
                                        image={process.env.PUBLIC_URL + "/assest/image/home/image-mug.png"}
                                        imgClassName="seller-img"
                                        overlaycolor="bg-overlay-one"
                                        mainimagesec="image-box"
                                        icon="fa-solid fa-arrow-right"
                                    />

                                </Col>
                                <Col lg={6} md={6} sm={6} xs={6}>
                                    <Popular_Product_Card
                                        className="main-section "
                                        image={process.env.PUBLIC_URL + "/assest/image/home/iamge-lap.png"}
                                        imgClassName="seller-img"
                                        overlaycolor="bg-overlay-one"
                                        mainimagesec="image-box"
                                        icon="fa-solid fa-arrow-right"
                                    />
                                    <Popular_Product_Card
                                        className="main-section "
                                        image={process.env.PUBLIC_URL + "/assest/image/home/image-jacket.png"}
                                        imgClassName="seller-img"
                                        overlaycolor="bg-overlay-one"
                                        mainimagesec="image-box"
                                        icon="fa-solid fa-arrow-right"
                                    />
                                </Col> */}
                            </Row>

                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default SustainableProduct
