import React, { useEffect, useRef, useState, useContext } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import './AllCategory.css'
import { Link } from 'react-router-dom'
import AOS from "aos";
import "aos/dist/aos.css";
import { Context } from "../../../utils/context";
import { allCategory } from "../../../utils/apis/master/Master";

const AllCategory = ({data}) => {
    useEffect(() => {
        AOS.init({ once: true });
    }, []);

    const { IMG_URL } = useContext(Context);
    

   
    return (
        <>
            <section className='all-category-home'>
                <Container>
                    <Row>
                        <Col lg={4} md={4} data-aos="fade-right"
                            data-aos-duration="3000" >
                            <Row>
                                <Col lg={6} md={6}>
                                    {data?.[0] && (
                                        <Link to={`/all-category/${data[0].id}`}>
                                            <div className='category-div cat-seecc-first'>
                                                <div className='image-holder'>
                                                    {/* <img className='category-image ' src={data?.[0]?.image ? `${IMG_URL}${data[0].image}` : 'default_image_url'} /> */}
                                                    {data?.[5]?.image ? (
                                                        <img className='category-image' src={`${IMG_URL}${data[5].image}`} alt="Category" />
                                                    ) : null}
                                                </div>
                                                <div className='text-holder'>
                                                    <p>{data?.[5]?.name || 'Default Name'}</p>
                                                </div>
                                                <div className='overlay-color'></div>
                                            </div>
                                        </Link>

                                    )}
                                </Col>
                                <Col lg={6} md={6}>
                                    {data?.[1] && (
                                        <Link to={`/all-category/${data[1].id}`}>
                                            <div className='category-div cat-seecc-second mb-3'>
                                                <div className='image-holder'>
                                                    {data?.[1]?.image ? (
                                                        <img className='category-image' src={`${IMG_URL}${data[1].image}`} alt="Category" />
                                                    ) : null}
                                                    {/* <img className='category-image' src={data?.[1]?.image ? `${IMG_URL}${data[1].image}` : 'default_image_url'} /> */}
                                                </div>
                                                <div className='overlay-color'></div>
                                                <div className='text-holder'>
                                                    <p>{data?.[1]?.name || 'Default Name'}</p>
                                                </div>
                                            </div>
                                        </Link>

                                    )}
                                    {data?.[3] && (
                                        <Link to={`/all-category/${data[3].id}`}>
                                            <div className='category-div'>
                                                <div className='image-holder'>
                                                    {data?.[3]?.image ? (
                                                        <img className='category-image' src={`${IMG_URL}${data[3].image}`} alt="Category" />
                                                    ) : null}
                                                    {/* <img className='category-image ' src={data?.[3]?.image ? `${IMG_URL}${data[3].image}` : 'default_image_url'} /> */}
                                                </div>
                                                <div className='overlay-color'></div>
                                                <div className='text-holder'>
                                                    <p>{data?.[3]?.name || 'Default Name'}</p>
                                                </div>
                                            </div>
                                        </Link>

                                    )}
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={4} md={4}>
                            {/* <Link to={"/all-category"}> */}
                            {data?.[0] && (
                                <Link to={`/all-category/${data[0].id}`}>
                                    <div className='category-div mb-3'>
                                        <div className='second-div'>
                                            <div className='image-holder'>
                                                <img className='category-image image-2' src={data?.[0]?.image ? `${IMG_URL}${data[0].image}` : 'default_image_url'} alt="Logo" />
                                            </div>
                                            <div className='overlay-color'></div>
                                            <div className='text-holder'>
                                                <p>{data?.[0]?.name || 'Default Name'}</p>
                                            </div>
                                        </div>
                                    </div>
                                </Link>

                            )}
                            {/* <Link to={`/all-category/${data[3]?.id}`}>
                                <div className='category-div mb-3'>
                                    <div className='second-div'>
                                        <div className='image-holder'>
                                            <img className='category-image image-2' src={data?.[3]?.image ? `${IMG_URL}${data[3].image}` : 'default_image_url'} alt="Logo" />
                                        </div>
                                        <div className='overlay-color'></div>
                                        <div className='text-holder'>
                                            <p>{data?.[3]?.name || 'Default Name'}</p>
                                        </div>
                                    </div>
                                </div>

                            </Link> */}
                        </Col>
                        <Col lg={4} md={4} data-aos="fade-left"
                            data-aos-duration="3000">
                            <Row>
                                <Col lg={6} md={6}>
                                    {data?.[2] && (
                                        <Link to={`/all-category/${data[2].id}`}>
                                            <div className='category-div cat-seecc-second mb-3'>
                                                <div className='image-holder'>
                                                    {data?.[2]?.image ? (
                                                        <img className='category-image' src={`${IMG_URL}${data[2].image}`} alt="Category" />
                                                    ) : null}
                                                    {/* <img className='category-image' src={data?.[4]?.image ? `${IMG_URL}${data[4].image}` : 'default_image_url'} alt="Logo" /> */}
                                                </div>
                                                <div className='overlay-color'></div>
                                                <div className='text-holder'>
                                                    <p>{data?.[2]?.name || 'Default Name'}</p>
                                                </div>
                                            </div>
                                        </Link>

                                    )}
                                    {data?.[4] && (
                                        <Link to={`/all-category/${data[4].id}`}>
                                            <div className='category-div'>
                                                <div className='image-holder'>
                                                    {data?.[4]?.image ? (
                                                        <img className='category-image' src={`${IMG_URL}${data[4].image}`} alt="Category" />
                                                    ) : null}
                                                    {/* <img className='category-image' src={data?.[4]?.image ? `${IMG_URL}${data[5].image}` : 'default_image_url'} alt="Logo" /> */}
                                                </div>
                                                <div className='overlay-color'></div>
                                                <div className='text-holder'>
                                                    <p>{data?.[4]?.name || 'Default Name'}</p>
                                                </div>
                                            </div>
                                        </Link>

                                    )}

                                </Col>
                                <Col lg={6} md={6}>
                                    {data?.[6] && (
                                        <Link to={`/all-category/${data[6].id}`}>
                                            <div className='category-div cat-seecc-first'>
                                                <div className='image-holder'>
                                                    {data?.[6]?.image ? (
                                                        <img className='category-image' src={`${IMG_URL}${data[6].image}`} alt="Category" />
                                                    ) : null}
                                                    {/* <img className='category-image' src={data?.[6]?.image ? `${IMG_URL}${data[6].image}` : 'default_image_url'} alt="Logo" /> */}
                                                </div>
                                                <div className='overlay-color'></div>
                                                <div className='text-holder'>
                                                    <p>{data?.[6]?.name || 'Default Name'}</p>
                                                </div>
                                            </div>
                                        </Link>
                                    )}
                                </Col>

                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className='all-category-home-mobile'>
                <Container>
                    <Row>
                        {data?.map((val, index) =>
                            <Col md={6} xs={6}>
                                 <Link to={`/all-category/${val.id}`}>
                                <div className='category-div cat-seecc-second mb-3'>
                                    <div className='image-holder'>
                                        <img className='category-image' src={IMG_URL + val?.image} alt="Logo" />
                                    </div>
                                    <div className='overlay-color'></div>
                                    <div className='text-holder'>
                                        <p>{val?.name}</p>
                                    </div>
                                </div>
                                </Link>

                            </Col>
                        )}
                        {/* <Col md={6} xs={6}>
                            <div className='category-div cat-seecc-second mb-3'>
                                <div className='image-holder'>
                                    <img className='category-image' src={process.env.PUBLIC_URL + '/assest/image/home/image-2.png'} alt="Logo" />
                                </div>
                                <div className='overlay-color'></div>
                                <div className='text-holder'>
                                    <p>NEW  <br /> HIRING</p>
                                </div>
                            </div>

                        </Col>
                        <Col md={6} xs={6}>
                            <div className='category-div cat-seecc-second mb-3'>
                                <div className='image-holder'>
                                    <img className='category-image' src={process.env.PUBLIC_URL + '/assest/image/home/image-1.png'} alt="Logo" />
                                </div>
                                <div className='overlay-color'></div>
                                <div className='text-holder'>
                                    <p>NEW  <br /> HIRING</p>
                                </div>
                            </div>

                        </Col>
                        <Col md={6} xs={6}>
                            <div className='category-div cat-seecc-second mb-3'>
                                <div className='image-holder'>
                                    <img className='category-image' src={process.env.PUBLIC_URL + '/assest/image/home/image-3.png'} alt="Logo" />
                                </div>
                                <div className='overlay-color'></div>
                                <div className='text-holder'>
                                    <p>NEW  <br /> HIRING</p>
                                </div>
                            </div>

                        </Col>
                        <Col md={6} xs={6}>
                            <div className='category-div cat-seecc-second mb-3'>
                                <div className='image-holder'>
                                    <img className='category-image' src={process.env.PUBLIC_URL + '/assest/image/home/image-4.png'} alt="Logo" />
                                </div>
                                <div className='overlay-color'></div>
                                <div className='text-holder'>
                                    <p>NEW  <br /> HIRING</p>
                                </div>
                            </div>

                        </Col> */}
                    </Row>


                </Container>
            </section>
        </>
    )
}

export default AllCategory
