import React, { useEffect, useRef, useState, useContext } from 'react'
import "./RelevantNews.css"
import Breadcrumbs from '../Common-Component/Breadcrumbs/Breadcrumbs'
import { Link } from 'react-router-dom'
import { LatestNews, LatestNewsDetails } from "../../utils/apis/LatestNews/LatestNews";
import { useParams } from 'react-router-dom';
import parse from "html-react-parser";
import { Context } from "../../utils/context";
const RelevantNews = () => {
    const items = [
        { id: 1, text: "HOME" ,link:"/home"},
        { id: 1, text: "News" ,link:"/relevantNews"},
    ]

    const [data, setData] = useState();
    const { id } = useParams();
    const { IMG_URL, formatDate } = useContext(Context);
    const getAllData = async () => {
        const res = await LatestNews();
        if (res?.success) {
            setData(res?.data);
        } else {
            console.error('Error fetching  data:', res?.error);
        }
    }



    useEffect(() => {
        getAllData();

    }, []);
    return (
        <>
            <section className='RelevantNews'>
                <div className='container'>
                    <Breadcrumbs items={items} />

                    <div className='row'>
                        {data?.data?.length > 0 ? (
                            <>
                                <div className='col-lg-7'>
                                    <div className='articlmainn'>
                                        <div className='articleshead'>
                                            <h3>
                                                <span>Latest Updates</span>
                                                <span className='nevermiss'>You Never Want to Miss</span>
                                            </h3>

                                        </div>
                                        {/* <Link to={"/relevantInner"}> */}
                                        <Link to={`/relevantInner/${data?.data[0]?.id}`}>
                                            <div className='articlesImg1'>
                                                <img src={IMG_URL + data?.data[0]?.image} />
                                                <div className='overlay-color'></div>
                                                <div className='imageinfo1'>
                                                    <h3>{data?.data[0]?.name}</h3>
                                                    <p> {typeof data?.data[0]?.description === 'string' ? parse(data?.data[0]?.description) : ""}</p>
                                                    <div className='minute-text-holder'>
                                                      
                                                    <p>By <span style={{ color: "#234C9E" }}>{data?.data[0]?.by_name}</span></p>
                                                    <p>{new Date(data?.data[0]?.createdAt).toLocaleTimeString([], { minute: '2-digit' })} minute ago</p>

                                                    </div>
                                                </div>

                                            </div>
                                        </Link>
                                    </div>

                                </div>
                                <div className='col-lg-5'>
                                    <div className='row'>
                                        {data?.data?.map((val, index) =>
                                            <div className='col-lg-6'>
                                                <Link to={`/relevantInner/${val?.id}`}>
                                                    <div className='articlesImg2'>
                                                        <img src={IMG_URL + val?.image} />

                                                        <div className='imageinfo2'>
                                                            <span>{formatDate(val?.updatedAt)}</span>
                                                            <p>{val?.name}</p>
                                                            <p>By <span style={{ color: "#234C9E" }}>{val?.by_name}</span>
                                                                {/* and <span style={{ color: "#234C9E" }}>William</span> */}
                                                            </p>
                                                        </div>
                                                    </div>

                                                </Link>

                                            </div>
                                        )}
                                        {/* <div className='col-lg-6'>
                                    <div className='articlesImg2'>
                                        <img src={process.env.PUBLIC_URL + "/assest/image/home/articlesImg1.png"} />
                                        <div className='imageinfo2'>
                                            <span>01 JUN 2024</span>
                                            <p>Gift a piece of history with our timeless coin collections, each one a tangible...</p>
                                            <p>By <span style={{ color: "#234C9E" }}>Samantha</span>  and <span style={{ color: "#234C9E" }}>William</span> </p>
                                        </div>
                                    </div>

                                </div>

                                <div className='col-lg-6'>
                                    <div className='articlesImg2'>
                                        <img src={process.env.PUBLIC_URL + "/assest/image/home/articlesImg1.png"} />
                                        <div className='imageinfo2'>
                                            <span>01 JUN 2024</span>
                                            <p>Gift a piece of history with our timeless coin collections, each one a tangible...</p>
                                            <p>By <span style={{ color: "#234C9E" }}>Samantha</span>  and <span style={{ color: "#234C9E" }}>William</span> </p>
                                        </div>
                                    </div>

                                </div>

                                <div className='col-lg-6'>
                                    <div className='articlesImg2'>
                                        <img src={process.env.PUBLIC_URL + "/assest/image/home/articlesImg1.png"} />
                                        <div className='imageinfo2'>
                                            <span>01 JUN 2024</span>
                                            <p>Gift a piece of history with our timeless coin collections, each one a tangible...</p>
                                            <p>By <span style={{ color: "#234C9E" }}>Samantha</span>  and <span style={{ color: "#234C9E" }}>William</span> </p>
                                        </div>
                                    </div>

                                </div> */}
                                    </div>

                                </div>
                            </>
                        ) : (
                            <div className='col-12'>
                                <p>No data available</p>
                            </div>
                        )}
                    </div>
                </div>
            </section>
        </>
    )
}

export default RelevantNews