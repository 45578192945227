import React from "react";
import "./Popup_holder.css"; // Add styles for the popup
import Waiting from "./Animation/Waiting";

const Popup_holder = ({ text, onClose }) => {
  return (
    <div className="popup-overlay" onClick={onClose}>
      <div className="popup-content">
      <Waiting/>
        <h2 className="popup-title">{text}</h2>
        <div className="popup-body">
          <p>We are preparing something amazing for you. Stay tuned!</p>
        </div>
      </div>
    </div>
  );
};

export default Popup_holder;
