import React, { useState, useEffect, useContext } from "react";
import Form from 'react-bootstrap/Form';
import { Col, Row } from 'react-bootstrap';
import Cookies from "js-cookie";
import { useForm, Controller } from "react-hook-form";
import classNames from "classnames";
import { Context } from "../../../utils/context";
import { AddToCart, getCart, deleteCart } from "../../../utils/apis/addToCart/addToCart";
const Shipping_method = ({ handleNext, totalGiftInduasalPrice,handlePrevious, giftdataArray, setGiftDataarray, giftdata, setGiftData, setGiftOptionsIndividualItems, giftOptionsIndividualItems, setGiftOptionsEntireOrder, giftOptionsEntireOrder, setCurrentStep, currentStep }) => {
   
    const {
        cartData
      } = useContext(Context);

      const [cart, setCart] = useState([]);
      const cartGet = async () => {
          const res = await getCart();
          if (res?.success) {
              setCart(res);
          } else {
              console.error('Error fetching data:', res?.error);
          }
      };
  
      useEffect(() => {
          cartGet();
      }, [giftdataArray.length < 0]);

    const {
        control,
        register,
        handleSubmit,
        getValues,
        setError,
        clearErrors,
        formState: { errors },
        reset,
        trigger, setValue,
    } = useForm();

    const [sellerId, setSellerId] = useState("");

    const onSubmit = async (data) => {
       
        setGiftData(data)
        setGiftDataarray([])
        handleNext()
    
    };
    if (giftdata) {
        reset(giftdata)
    }





    return (
        <>
            <section className='stepp-inner-main'>
                <Row>
                    <Col xl={8}>
                        <div className='shippingMethod'>
                            <div className='giftShipping'>
                                <h3>GIFT OPTIONS FOR THE ENTIRE ORDER</h3>
                                <h3>Flat Rate : ₹ {cart?.message?.giftprice}</h3>
                                {/* <Controller
                                    name="gift_status"
                                    control={control}
                                    rules={{ validate: value => value === 1 || "Please check this box if you want to proceed." }} // Validation rule
                                    defaultValue={0}
                                    render={({ field }) => (
                                        <Form.Check
                                            type="checkbox"
                                            checked={field.value === 1}
                                            label="Add gift options for the Entire Order"
                                            className="checkNews"
                                            onChange={(e) => field.onChange(e.target.checked ? 1 : 0)}
                                            
                                        />
                                        
                                    )}
                                /> */}
                                <Controller
                                    name="gift_status"
                                    control={control}
                                    defaultValue={0}
                                    rules={{ validate: value => value === 1 || "Please check this box if you want to proceed." }} // Validation rule
                                    render={({ field, fieldState: { error } }) => (
                                        <>
                                            <Form.Check
                                                type="checkbox"
                                                checked={field.value === 1}
                                                label="Add gift options for the Entire Order"
                                                className="checkNews"
                                                onChange={(e) => field.onChange(e.target.checked ? 1 : 0)}
                                                isInvalid={!!error} // Check if there's an error
                                            />
                                            {error && (
                                                <div className="invalid-feedback">
                                                    {error.message} {/* Display the validation message */}
                                                </div>
                                            )}
                                        </>
                                    )}
                                />



                                <Form className='mt-3'>
                                    <Row className='shippindmethodform'>
                                        <Col xl={6} md={6}>
                                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                <Form.Label>From</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="from"
                                                    // placeholder="Enter from"
                                                    {...register("from", {
                                                        required: "from required",

                                                    })}
                                                    className={classNames("", {
                                                        "is-invalid": errors?.from,
                                                        "is-valid": getValues("from"),
                                                    })}

                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col xl={6} md={6}>
                                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                <Form.Label>To</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="to"
                                                    placeholder="Enter to Name"
                                                    {...register("to", {
                                                        required: "to  required",

                                                    })}
                                                    className={classNames("", {
                                                        "is-invalid": errors?.to,
                                                        "is-valid": getValues("to"),
                                                    })}

                                                />
                                            </Form.Group>
                                        </Col>

                                        <Col xl={12}>
                                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                <Form.Label>Message</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="message"
                                                    placeholder="Enter message"
                                                    {...register("message", {
                                                        required: "message required",

                                                    })}
                                                    className={classNames("", {
                                                        "is-invalid": errors?.message,
                                                        "is-valid": getValues("message"),
                                                    })}

                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Form>
                            </div>
                        </div>
                    </Col>
                </Row>
                <div className="save-btn-div mt-5">
                    <button className="back-btn" onClick={() => setCurrentStep('shippingMethod')}> Back </button>
                    {/* <button className="continue-btn" onClick={handleNext}> Continue </button> */}
                    <button className="continue-btn" onClick={handleSubmit(onSubmit)}> Continue </button>
                </div>
            </section>
        </>
    );
};

export default Shipping_method;
