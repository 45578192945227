import React, { useEffect, useRef, useState, useContext } from "react";
import "./GiftCustomization.css";
import { Button, Col, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Select from "react-select";
import { Uploader } from "uploader";
import { UploadButton } from "react-uploader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Card from "react-bootstrap/Card";
import Talktous from "../Common-Component/Offcanvas/Talktous/Talktous";
import Common_Button from "../Common-Component/Common_Button/Common_Button";
import Breadcrumbs from "../Common-Component/Breadcrumbs/Breadcrumbs";
import SuccessfullyModal from "../Common-Component/Commonmodal/SuccessfullyModal/SuccessfullyModal";
import { useParams } from "react-router-dom";
import parse from "html-react-parser";
import { Context } from "../../utils/context";
import Cookies from "js-cookie";
import { Link, useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import classNames from "classnames";
import { RegxExpression } from "../../utils/common";
import { CustomizationForm } from "../../utils/apis/form/form";
import { Material } from "../../utils/apis/master/Master";

const GiftCustomization = () => {
  const [value, setValue] = useState();
  const [selectedOption, setSelectedOption] = useState(null);
  const [customise, setcustomise] = useState(null);

  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];

  const uploader = Uploader({ apiKey: "free" });
  const uploadOptions = { multi: true };

  const customizationSteps = [
    { title: "Choose", text: "A Customizable Gift" },
    { title: "Upload", text: "Your Artwork" },
    { title: "Order", text: "With Confidence" },
  ];

  const sampleGoods = [
    {
      title: "customised Sample",
      description: "This product decorated with your logo or artwork",
      additionalInfo: [
        { heading: "Receive within", text: "2 to 48 hrs" },
        {
          heading: "Recommended If:",
          text:
            "you’re still ideating or want to share with your team for approval",
        },
      ],
      icon: "pdf.png",
    },
    {
      title: "Random Logo Sample",
      description: "This product decorated with a random company’s logo/design",
      additionalInfo: [
        { heading: "Receive within", text: "2 to 48 hrs" },
        {
          heading: "Recommended If:",
          text:
            "you’re still ideating or want to share with your team for approval",
        },
      ],
      icon: "molecule.png",
    },
    {
      title: "customised Sample",
      description: "This product decorated with your logo or artwork",
      additionalInfo: [
        { heading: "Receive within", text: "2 to 48 hrs" },
        {
          heading: "Recommended If:",
          text:
            "you’re still ideating or want to share with your team for approval",
        },
      ],
      icon: "pdf.png",
    },
  ];

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const items = [
    { id: 1, text: "HOME", link: "/home" },
    { id: 1, text: "GIFT CUSTOMIZATION" },
  ];

  const customiseClose = () => {
    setcustomise(true);
    setTimeout(() => {
      setcustomise(false);
      window.location.pathname = "/home";
    }, 3000);
  };
  const {
    signin,
    setSignin,
    shimmerLoader,
    setShimmerLoader,
    Select2Data,
    IMG_URL,
  } = useContext(Context);
  const fileInputRef = useRef(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset,
    trigger,
  } = useForm();

  const onSubmit = async (data) => {
    setShimmerLoader(true);

    const formData = new FormData();

    formData.append("material_id", data?.material_id?.value);

    formData.append("requirement", data?.requirement);
    formData.append("company_name", data?.company_name);
    formData.append("name", data?.name);
    formData.append("email", data?.email);
    formData.append("contact_no", data?.contact_no);
    if (selectedFiles.length > 0) {
      selectedFiles?.map((image, index) => {
        formData.append(`images[${index}]`, image);
      });
    }
    const res = await CustomizationForm(formData);
    if (res?.success) {
      customiseClose();
      // await Cookies.remove('customise_size');
      // navigate('/mycart');
    } else {
      if (res?.message?.gst_number) {
        setError("gst_number", { message: res?.message?.gst_number });
      }
    }
  };
  const [materialdata, setMaterialdata] = useState();
  const GetAllMaterial = async () => {
    const response = await Material();

    if (response?.success) {
      setMaterialdata(await Select2Data(response?.data, "material_id"));
    }
  };
  useEffect(() => {
    GetAllMaterial();
  }, []);
  const handleimageClick = (event) => {
    event.preventDefault();
    fileInputRef.current.click();
  };
  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFiles([...selectedFiles, ...files]);
  };

  const handleRemoveImage = (index) => {
    const filteredFiles = selectedFiles.filter((_, i) => i !== index);
    setSelectedFiles(filteredFiles);
  };
  return (
    <>
      <section className="GiftCustomization">
        <div className="imgMain">
          <img
            src={`${process.env.PUBLIC_URL}/assest/image/Careers/banner.png`}
            className="bannerImg"
            alt="Banner"
          />
          <div className="container">
            <div className="breadcrumsec">
              <div>
                <Breadcrumbs items={items} />
              </div>
            </div>
          </div>
          <div className="container">
            <div className="customizingWorking">
              <Row>
                <Col xl={6} md={6}>
                  <div>
                    <div className="customisehead">
                      <h3>
                        How <br />
                        <b>Customization Works</b>
                      </h3>
                    </div>
                    <div className="text-center">
                      <img
                        src={`${process.env.PUBLIC_URL}/assest/image/Careers/workingImg.png`}
                        className="workingImg"
                        alt="Working"
                      />
                    </div>
                  </div>
                </Col>
                <Col xl={6} md={6}>
                  {customizationSteps.map((step, index) => (
                    <div className="custImgmain" key={index}>
                      <img
                        src={`${process.env.PUBLIC_URL}/assest/image/Careers/customiseWork.png`}
                        className="rightSideimg"
                        alt={step.title}
                      />
                      <div className="custImgTxt">
                        <h3>{step.title}</h3>
                        <p>{step.text}</p>
                      </div>
                    </div>
                  ))}
                </Col>
              </Row>
            </div>
          </div>

          <div className="container">
            <div className="formMain">
              <div className="custoHead">
                <h3>Customization Form</h3>
              </div>
              {/* <Form>
                                <Row>
                                    <Col lg={6} md={6}>
                                        <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
                                            <Form.Control type='text' placeholder='Enter Name' />
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6} md={6}>
                                        <PhoneInput
                                            country='in'
                                            value={value}
                                            onChange={setValue}
                                            placeholder='Enter Contact no'
                                            className='mb-3'
                                        />
                                    </Col>
                                    <Col lg={6} md={6}>
                                        <Form.Group className='mb-3' controlId='exampleForm.ControlInput2'>
                                            <Form.Control type='email' placeholder='Enter Email Id' />
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6} md={6}>
                                        <Form.Group className='mb-3' controlId='exampleForm.ControlInput3'>
                                            <Form.Control type='text' placeholder='Enter Company Name' />
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6} md={6}>
                                        <Select
                                            defaultValue={selectedOption}
                                            onChange={setSelectedOption}
                                            options={options}
                                            className='mb-3'
                                            classNamePrefix='manualSelect'
                                            placeholder="Material"
                                        />
                                    </Col>
                                </Row>

                                <div className='Addproduct'>
                                    <div className='fggggg'>
                                        <p>Add Product</p>
                                    </div>
                                    <UploadButton
                                        uploader={uploader}
                                        options={uploadOptions}
                                        onComplete={(files) => alert(files.map(x => x.fileUrl).join('\n'))}
                                    >
                                        {({ onClick }) => (
                                            <button className='uploadDiv' onClick={onClick}>
                                                <FontAwesomeIcon icon='fa-solid fa-circle-plus' />
                                                <p className='mb-1'>Upload here</p>
                                            </button>
                                        )}
                                    </UploadButton>
                                </div>

                                <div className='checkLabel'>
                                    <Form.Check
                                        type='radio'
                                        label={
                                            <div>
                                                <p className='mb-0'><b>Add Custom Design</b></p>
                                                <p className='mb-0'>(Add Logo / Artwork)</p>
                                            </div>
                                        }
                                    />
                                </div>

                                <div className='mt-5'>
                                    <Form.Group className='mb-3' controlId='exampleForm.ControlTextarea1'>
                                        <Form.Label><b>Add Special Requests</b></Form.Label>
                                        <Form.Control as='textarea' />
                                    </Form.Group>
                                </div>

                                <div className='submitbtnsec text-center'>
                                    <Common_Button text={"Submit"} className='btn submitbtn' onClick={customiseClose} />
                                </div>
                            </Form> */}
              <Form onSubmit={handleSubmit(onSubmit)}>
                {/* <div className='formRowhead'>
                                                    <h3>Nike Dri-FIT Legend Tee - Men's</h3>
                                                    <p>₹ 1,962.00</p>
                                                </div> */}
                <Row>
                  <Col lg={6} md={6}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Control
                        type="text"
                        name="name"
                        placeholder="Enter First Name"
                        {...register("name", {
                          required: "name required",
                        })}
                        className={classNames("", {
                          "is-invalid": errors?.name,
                          "is-valid": getValues("name"),
                        })}
                        onKeyDown={(event) => {
                          if (!RegxExpression.name.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6} md={6}>
                    <Controller
                      className="mb-3"
                      name="contact_no"
                      control={control}
                      rules={{
                        validate: (value) =>
                          value || getValues("email")
                            ? true
                            : "Mobile Number or Email is required",
                        pattern: {
                          value: /^\+?\d{1,4}[-\s]?\d{10}$/,
                          message:
                            "Invalid number format. Please enter a valid number with an optional country code",
                        },
                      }}
                      render={({ field }) => (
                        <PhoneInput
                          country={"in"}
                          {...field}
                          className={classNames("", {
                            "is-invalid": errors?.contact_no,
                            "is-valid": getValues("contact_no"),
                          })}
                          onChange={(value) => field.onChange(value)}
                          type="text"
                          placeholder="Enter Mobile Number"
                          onKeyDown={(event) => {
                            if (!RegxExpression.phone.test(event.key)) {
                              if (event.key !== "Backspace") {
                                event.preventDefault();
                              }
                            }
                          }}
                          maxLength={10}
                        />
                      )}
                    />
                    {errors?.contact_no && (
                      <sup className="text-danger">
                        {errors?.contact_no?.message}
                      </sup>
                    )}
                  </Col>
                  <Col lg={6} md={6}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Control
                        type="text"
                        name="email"
                        placeholder="Enter Email"
                        {...register("email", {
                          validate: (value) =>
                            value || getValues("contact_no")
                              ? true
                              : "Email or Mobile Number is required",
                          pattern: {
                            value: RegxExpression?.email,
                            message: "Invalid email address",
                          },
                        })}
                        className={classNames("", {
                          "is-invalid": errors?.email,
                          "is-valid": getValues("email"),
                        })}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6} md={6}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Control
                        type="text"
                        name="company_name"
                        placeholder="Enter Store Name"
                        {...register("company_name", {
                          required: "company_name required",
                        })}
                        className={classNames("", {
                          "is-invalid": errors?.company_name,
                          "is-valid": getValues("company_name"),
                        })}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6} md={6} className="mb-3">
                    <Controller
                      name="material_id" // name of the field
                      {...register("material_id", {
                        required: "Select material",
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.material_id
                                ? "red"
                                : baseStyles,
                            }),
                          }}
                          {...field}
                          options={materialdata}
                        />
                      )}
                    />

                    {errors.material_id && (
                      <span className="text-danger">
                        {errors.material_id.message}
                      </span>
                    )}
                  </Col>
                </Row>

                <div className="customCheck">
                  <Form.Label className="plus-btn me-5">
                    <div className="uploadDiv" onClick={handleimageClick}>
                      <FontAwesomeIcon icon="fa-solid fa-circle-plus" />
                      <p className="mb-1">Upload here</p>
                    </div>
                    <Form.Control
                      ref={fileInputRef}
                      type="file"
                      onChange={handleFileChange}
                      multiple
                      accept="image/"
                      style={{ display: "none" }}
                    />
                  </Form.Label>

                  <div className="image-holder">
                    {selectedFiles.length > 0 &&
                      selectedFiles.map((file, index) => (
                        <div className="file-img-holder" key={index}>
                          <object
                            className="img-logo"
                            data={URL.createObjectURL(file)}
                            type={file.type}
                            width="59" // Set the width
                            height="58" // Set the height
                          />
                          <div
                            className="cross-overlay"
                            onClick={() => handleRemoveImage(index)}
                          >
                            <img
                              className="img-cross"
                              src={
                                process.env.PUBLIC_URL +
                                "/assest/image/Careers/crossIcon.png"
                              }
                              alt={`Cross Icon ${index + 1}`}
                            />
                          </div>
                        </div>
                      ))}
                  </div>
                </div>

                <div className="specialRequest">
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label className="mb-0">
                      Add Special Requests
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="requirement"
                      placeholder="Enter requirement"
                      {...register("requirement", {
                        required: "requirement is required",
                      })}
                      className={classNames("", {
                        "is-invalid": errors?.requirement,
                        "is-valid": getValues("requirement"),
                      })}
                    />
                  </Form.Group>
                </div>

                <div className="addtocartsec text-center">
                  {/* <p>(*Add Special Requests and Submit)</p> */}
                  {/* <Link to={"/mycart"}> */}
                  <Button className="btn" type="submit">
                    Submit
                  </Button>

                  {/* </Link> */}
                </div>
              </Form>
            </div>
          </div>

          <div className="NeedHelp">
            <div className="container">
              <div className="Needhelpheading">
                <h3>
                  <strong>Need Help</strong> With Gifting Programs?
                </h3>
                <div className="Talktousbtn">
                  <Common_Button
                    text="TALK TO US"
                    className="talk-us"
                    onClick={handleShow}
                  />
                </div>
              </div>
            </div>
          </div>

          <Talktous
            show={show}
            handleClose={handleClose}
            placement="end"
            name="end"
          />

          <div className="Samplegoods">
            <div className="container">
              <div className="sampleHead">
                <h3>
                  Sample The Goods <br />
                  <b>Before Sending</b>
                </h3>
              </div>
              <div className="sampleRowMain">
                <Row>
                  {sampleGoods.map((good, index) => (
                    <Col xl={4} lg={4} md={6} key={index}>
                      <Card className="samplecard">
                        <div className="sampleHeadinginfo">
                          <h3>{good.title}</h3>
                          <p>{good.description}</p>
                        </div>
                        <div className="downside">
                          {good.additionalInfo.map((info, idx) => (
                            <div className="d-flex cardtxt" key={idx}>
                              <img
                                src={`${process.env.PUBLIC_URL}/assest/image/Careers/clock.png`}
                                className="clockImg"
                                alt="Clock"
                              />
                              <div>
                                <h3>{info.heading}</h3>
                                <p>{info.text}</p>
                              </div>
                            </div>
                          ))}
                        </div>
                        <div className="iconCircle">
                          <img
                            src={`${process.env.PUBLIC_URL}/assest/image/Careers/${good.icon}`}
                            alt={good.title}
                          />
                        </div>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </div>
            </div>
          </div>
        </div>
        <SuccessfullyModal
          show={customise}
          onHide={() => setcustomise(false)}
          text={"Form Submited Successfully"}
        />
      </section>
    </>
  );
};

export default GiftCustomization;
