import Cookies from "js-cookie";
import { v4 as uuidv4 } from "uuid";
import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";

import { Context, variant } from "../../context";
import { useContext } from "react";

import { getProductDetail, getVariant } from "../Product/Product";

export const customiseAddTokart = async (data) => {
  try {
    const res = await postData(`/customise/add`, data);

    return res;
  } catch (error) {
    console.log(error);
  }
};
export const loginCart = async () => {
  let cartItems = [];
  const existingCart = Cookies.get("coinz_kart_cart");
  if (existingCart) {
    cartItems = JSON.parse(existingCart);
  }
  if (cartItems.length > 0) {
    await Promise.all(
      cartItems.map(async (value) => {
        const data = await postData(`/cart/add`, {
          product_id: value?.product_id,
          variant_id: value?.variant_id,
          sizes: value.sizes,
        });
      })
    );
  }
  await Cookies.remove("coinz_kart_cart");
};

const logCartData = () => {
  const existingCart = Cookies.get("coinz_kart_cart");
  if (existingCart) {
    const cartItems = JSON.parse(existingCart);
  } else {
    console.log("Cart is empty");
  }
};

export const AddToCart = async (d) => {
  try {
    if (Cookies.get("coinz_kart_security")) {
      const res = await postData(`/cart/add`, {
        product_id: d.product_id,
        variant_id: d.variant_id,
        sizes: d.size,
      });
      return true;
    } else {
      let cartItems = [];

      const existingCart = Cookies.get("coinz_kart_cart");
      if (existingCart) {
        cartItems = JSON.parse(existingCart);
      }

      // Check if the product with the same variant_id already exists
      const existingItemIndex = cartItems.findIndex(
        (item) =>
          item.product_id === d.product_id && item.variant_id === d.variant_id
      );

      const res = await getVariant(d?.variant_id);
      const res1 = await getProductDetail(d?.product_id);

      if (existingItemIndex !== -1) {
        // If it exists, remove the item from the cart
        cartItems.splice(existingItemIndex, 1);
      }
      const newItem = {
        id: uuidv4(), // Generate a unique ID
        product_id: d?.product_id,
        variant_id: d?.variant_id,
        v_name: res?.data?.v_name,
        name: res1?.data?.name,
        image: res?.data?.product_images[0]?.image,
        price: res?.data?.s_price,
        tax_percent: res1?.data?.product_detail?.tax_percentage?.name,
        sizes: d.size,
      };
      cartItems.push(newItem);

      Cookies.set("coinz_kart_cart", JSON.stringify(cartItems), { expires: 7 }); // Setting the cookie with an expiration of 7 days
    }
    logCartData();

    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const CartQuantity = async (id, type, variant_id) => {
  try {
    if (Cookies.get("coinz_kart_security")) {
      const res = await postData(`/cart/quantity`, {
        cart_id: id,
        type: type,
      });
      return true;
    } else {
      let cartItems = [];

      const existingCart = Cookies.get("coinz_kart_cart");
      if (existingCart) {
        cartItems = JSON.parse(existingCart);
      }

      const existingItemIndex = cartItems.findIndex((item) => item.id === id);
      if (existingItemIndex === -1) {
      } else {
        if (cartItems[existingItemIndex]?.quantity === 1 && type === "minus") {
          cartItems.splice(existingItemIndex, 1);
        } else {
          if (type === "minus") {
            cartItems[existingItemIndex].quantity -= 1;
          } else {
            cartItems[existingItemIndex].quantity += 1;
          }
        }
        Cookies.set("coinz_kart_cart", JSON.stringify(cartItems));
      }
    }
    return true;
  } catch (error) {
    console.log(error);
  }
};

export const getCart = async () => {
  try {
    return await getData(`/cart/show`);
  } catch (error) {
    console.log(error);
  }
};

export const deleteCart = async ({ id, size_id }) => {
  try {
    if (Cookies.get("coinz_kart_security")) {
      // If user is authenticated, delete directly from server
      return await deleteData(`/cart/${id}`);
    } else {
      // If user is not authenticated, handle deletion from local cookie
      let cartItems = [];
      const existingCart = Cookies.get("coinz_kart_cart");
      cartItems = JSON.parse(existingCart);
      if (existingCart && size_id) {
        const itemToDelete = cartItems.find((item) => item.id === id);

        if (itemToDelete) {
          if (size_id && itemToDelete.sizes.length > 1) {
            // Remove the specific cloth_size_id from sizes array
            itemToDelete.sizes = itemToDelete.sizes.filter(
              (size) => size.cloth_size_id !== size_id
            );
          } else {
            // Remove the entire item from cart
            cartItems = cartItems.filter((item) => item.id !== id);
          }

          Cookies.set("coinz_kart_cart", JSON.stringify(cartItems), {
            expires: 7,
          });
        }
      } else {
        const updatedCartItems = cartItems.filter((item) => item.id !== id);
        Cookies.set("coinz_kart_cart", JSON.stringify(updatedCartItems), {
          expires: 7,
        });
      }
    }

    // After updating cart, log the current cart data
    logCartData();
  } catch (error) {
    console.log(error);
  }
};

export const getCartCheck = async (data) => {
  try {
    if (Cookies.get("coinz_kart_security")) {
      const queryParams = new URLSearchParams(data).toString();
      return await getData(`/cart/getCart?${queryParams}`);
    } else {
      let cartItems = [];
      const existingCart = Cookies.get("coinz_kart_cart");
      if (existingCart) {
        cartItems = JSON.parse(existingCart);
      }
      const existingItemIndex = cartItems.findIndex(
        (item) =>
          item.product_id == data?.product_id &&
          item.variant_id == data?.variant_id
      );
      if (existingItemIndex === -1) {
        return { success: false, cartItem: cartItems[existingItemIndex] };
      } else {
        return { success: true, cartItem: cartItems[existingItemIndex] };
      }
    }
  } catch (error) {
    console.log(error);
  }
};
