import React, { useEffect, useState, useContext } from 'react'
import './ShopByoccasion.css'
import { Col, Container, Row } from 'react-bootstrap'
import Popular_Product_Card from '../Common-Component/Popular_Product_Card/Popular_Product_Card';
import { OccasionProducts } from '../../utils/apis/Home/Homeapi';
import Breadcrumbs from '../Common-Component/Breadcrumbs/Breadcrumbs';
import { Context } from "../../utils/context";
const ShopByoccasion = () => {
    const [promoProduct, setPromoproduct] = useState([]);
    const { IMG_URL } = useContext(Context);
    const getPromotional = async () => {
        const res = await OccasionProducts();
        if (res?.success) {
            setPromoproduct(res?.data)
        }
    }

    useEffect(() => {
        getPromotional();
    }, [])

    const items = [
        { id: 1, text: "HOME", link: "/home" },
        { id: 1, text: "SHOP BY OCCASION" },
    ]
    return (
        <>
            <section className='promotional-product'>
                <Container>
                    <Breadcrumbs items={items} />
                    {promoProduct && promoProduct.length > 0 ? (
                        promoProduct.map((val, image) => (
                            <Row className='mb-5'>
                                <div className='heading-holder'>
                                    <div className='d-flex'>
                                        <h3 className='second-heading'>{val?.name}</h3>
                                    </div>

                                </div>
                                <Col lg={6}>
                                    <Row>
                                        {/* <Col lg={6} md={6} sm={6} xs={6}> */}
                                        {val?.product_occasions?.slice(1, 3).map((row, rowIndex) => (
                                            <React.Fragment key={rowIndex}>
                                                {row?.map((val1, colIndex) => (
                                                    <Col key={colIndex} lg={6} md={6} sm={6} xs={6}>

                                                        <Popular_Product_Card
                                                            key={colIndex}
                                                            className="main-section"
                                                            image={IMG_URL + val1?.product_detail?.product?.product_variants[0]?.product_images[0]?.image}
                                                            imgClassName="seller-img"
                                                            overlaycolor="bg-overlay-one"
                                                            mainimagesec="image-box"
                                                            icon="fa-solid fa-arrow-right"
                                                            link={`/productDetails/${val1?.product_detail?.product?.id}`}

                                                        />

                                                    </Col>
                                                ))}
                                            </React.Fragment>
                                        ))}

                                    </Row>
                                </Col>
                                {val && val?.product_occasions?.length > 0 && val?.product_occasions[0] && val?.product_occasions[0][0] && val?.product_occasions[0][0].product_detail?.product?.product_variants && (
                                    <Col lg={6}>
                                        <Popular_Product_Card
                                            className="main-section"
                                            image={IMG_URL + val?.product_occasions[0][0].product_detail?.product?.product_variants[0]?.product_images[0]?.image}
                                            imgClassName="seller-img"
                                            content="VIEW ALL"
                                            textClassName="local-text"
                                            overlaycolor="bg-overlay"
                                            link={`/product/${val?.id}`}
                                        />
                                    </Col>
                                )}

                            </Row>))
                    ) : (
                        <div className="no-data-message" style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%', // Adjust as needed
                            textAlign: 'center',
                          }}>
                            <h3>No products available</h3>
                        </div>
                    )}

                    {/* <Row className='mb-5'>
                        <div className='heading-holder'>
                            <div className='d-flex'>
                                <h3 className='second-heading'>Birthday</h3>
                            </div>

                        </div>
                        <Col lg={6}>
                            <Popular_Product_Card
                                className="main-section "
                                image={process.env.PUBLIC_URL + "/assest/image/home/image-asse.png"}
                                imgClassName="seller-img"
                                content="VIEW ALL"
                                textClassName="local-text"
                                overlaycolor="bg-overlay"
                                link="/promotional-product"
                            />
                        </Col>
                        <Col lg={6}>
                            <Row>
                                <Col lg={6} md={6} sm={6} xs={6}>
                                    <Popular_Product_Card
                                        className="main-section "
                                        image={process.env.PUBLIC_URL + "/assest/image/home/image-jacket.png"}
                                        imgClassName="seller-img"
                                        overlaycolor="bg-overlay-one"
                                        mainimagesec="image-box"
                                        icon="fa-solid fa-arrow-right"
                                    />
                                    <Popular_Product_Card
                                        className="main-section "
                                        image={process.env.PUBLIC_URL + "/assest/image/home/image-mug.png"}
                                        imgClassName="seller-img"
                                        overlaycolor="bg-overlay-one"
                                        mainimagesec="image-box"
                                        icon="fa-solid fa-arrow-right"
                                    />

                                </Col>  
                                <Col lg={6} md={6} sm={6} xs={6}>
                                    <Popular_Product_Card
                                        className="main-section "
                                        image={process.env.PUBLIC_URL + "/assest/image/home/iamge-lap.png"}
                                        imgClassName="seller-img"
                                        overlaycolor="bg-overlay-one"
                                        mainimagesec="image-box"
                                        icon="fa-solid fa-arrow-right"
                                    />
                                    <Popular_Product_Card
                                        className="main-section "
                                        image={process.env.PUBLIC_URL + "/assest/image/home/image-jacket.png"}
                                        imgClassName="seller-img"
                                        overlaycolor="bg-overlay-one"
                                        mainimagesec="image-box"
                                        icon="fa-solid fa-arrow-right"
                                    />
                                </Col>
                            </Row>
                        </Col>

                    </Row>

                    <Row className='mb-5'>
                        <div className='heading-holder'>
                            <div className='d-flex'>
                                <h3 className='second-heading'>Work Anniversary</h3>
                            </div>

                        </div>
                        <Col lg={6}>
                            <Row>
                                <Col lg={6} md={6} sm={6} xs={6}>
                                    <Popular_Product_Card
                                        className="main-section "
                                        image={process.env.PUBLIC_URL + "/assest/image/home/image-jacket.png"}
                                        imgClassName="seller-img"
                                        overlaycolor="bg-overlay-one"
                                        mainimagesec="image-box"
                                        icon="fa-solid fa-arrow-right"
                                    />
                                    <Popular_Product_Card
                                        className="main-section "
                                        image={process.env.PUBLIC_URL + "/assest/image/home/image-mug.png"}
                                        imgClassName="seller-img"
                                        overlaycolor="bg-overlay-one"
                                        mainimagesec="image-box"
                                        icon="fa-solid fa-arrow-right"
                                    />

                                </Col>
                                <Col lg={6} md={6} sm={6} xs={6}>
                                    <Popular_Product_Card
                                        className="main-section "
                                        image={process.env.PUBLIC_URL + "/assest/image/home/iamge-lap.png"}
                                        imgClassName="seller-img"
                                        overlaycolor="bg-overlay-one"
                                        mainimagesec="image-box"
                                        icon="fa-solid fa-arrow-right"
                                    />
                                    <Popular_Product_Card
                                        className="main-section "
                                        image={process.env.PUBLIC_URL + "/assest/image/home/image-jacket.png"}
                                        imgClassName="seller-img"
                                        overlaycolor="bg-overlay-one"
                                        mainimagesec="image-box"
                                        icon="fa-solid fa-arrow-right"
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={6}>
                            <Popular_Product_Card
                                className="main-section "
                                image={process.env.PUBLIC_URL + "/assest/image/home/image-asse.png"}
                                imgClassName="seller-img"
                                content="VIEW ALL"
                                textClassName="local-text"
                                overlaycolor="bg-overlay"
                                link="/promotional-product"
                            />
                        </Col>
                    </Row>

                    <Row>
                        <div className='heading-holder'>
                            <div className='d-flex'>
                                <h3 className='second-heading'>Gratitude</h3>
                            </div>

                        </div>
                        <Col lg={6}>
                            <Popular_Product_Card
                                className="main-section "
                                image={process.env.PUBLIC_URL + "/assest/image/home/image-asse.png"}
                                imgClassName="seller-img"
                                content="VIEW ALL"
                                textClassName="local-text"
                                overlaycolor="bg-overlay"
                                link="/promotional-product"
                            />
                        </Col>
                        <Col lg={6}>
                            <Row>
                                <Col lg={6} md={6} sm={6} xs={6}>
                                    <Popular_Product_Card
                                        className="main-section "
                                        image={process.env.PUBLIC_URL + "/assest/image/home/image-jacket.png"}
                                        imgClassName="seller-img"
                                        overlaycolor="bg-overlay-one"
                                        mainimagesec="image-box"
                                        icon="fa-solid fa-arrow-right"
                                    />
                                    <Popular_Product_Card
                                        className="main-section "
                                        image={process.env.PUBLIC_URL + "/assest/image/home/image-mug.png"}
                                        imgClassName="seller-img"
                                        overlaycolor="bg-overlay-one"
                                        mainimagesec="image-box"
                                        icon="fa-solid fa-arrow-right"
                                    />

                                </Col>
                                <Col lg={6} md={6} sm={6} xs={6}>
                                    <Popular_Product_Card
                                        className="main-section "
                                        image={process.env.PUBLIC_URL + "/assest/image/home/iamge-lap.png"}
                                        imgClassName="seller-img"
                                        overlaycolor="bg-overlay-one"
                                        mainimagesec="image-box"
                                        icon="fa-solid fa-arrow-right"
                                    />
                                    <Popular_Product_Card
                                        className="main-section "
                                        image={process.env.PUBLIC_URL + "/assest/image/home/image-jacket.png"}
                                        imgClassName="seller-img"
                                        overlaycolor="bg-overlay-one"
                                        mainimagesec="image-box"
                                        icon="fa-solid fa-arrow-right"
                                    />
                                </Col>
                            </Row>
                        </Col>

                    </Row> */}
                </Container>
            </section>
        </>
    )
}

export default ShopByoccasion
