import React, { useState, useEffect, useContext } from "react";
import Modal from 'react-bootstrap/Modal';
import "./ViewTrackmodal.css";
import { Container, Row, Col, Image, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getSingleOrder } from "../../../../utils/apis/orderProcess/orderProcess";
import { Context } from "../../../../utils/context";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
const ViewTrackmodal = (props) => {
    const {
        signin,
        setSignin, shimmerLoader, setShimmerLoader, IMG_URL, formatDate } = useContext(Context);
    const [orderData, setOrderData] = useState()
    const orderGet = async () => {

        const res = await getSingleOrder(props.id);
        if (res?.success) {
            setOrderData(res?.data)
        } else {
            console.error('Error fetching  data:', res?.error);
        }
    }

    var stepPercentage = 0;

    if (props.currentStep === 1) {
        stepPercentage = 0;
    } else if (props.currentStep === 2) {
        stepPercentage = 50;
    } else if (props.currentStep === 3) {
        stepPercentage = 100;
    } else {
        stepPercentage = 0;
    }

    useEffect(() => {
        orderGet();
    }, [props.id]);

    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="viewtrackmodalmain"
            >

                <Modal.Body>
                    <div className='text-end'>
                        <FontAwesomeIcon icon="fa-solid fa-xmark" onClick={props.onHide} className='crossIcon' />
                    </div>

                    <section className='viewtrackmodal'>
                        <Container className="order-details-container">
                            <Row>
                                <Col xl={4} lg={5} md={6} sm={6}>
                                    <div className='purchasedSec'>
                                        <p>Ordered Date :{formatDate(orderData?.createdAt)}</p>
                                        <h4>Purchased Item {orderData?.order?.quantity}</h4>
                                        <h4>₹ {orderData?.total_amount}</h4>
                                    </div>
                                </Col>
                                <Col xl={4} lg={5} md={6} sm={6}>
                                    <div className='paymentsec'>
                                        <p>Order ID :{orderData?.invoice_number}</p>
                                        <p>Payment Type : {orderData?.order?.payment_mode}</p>
                                        <p>Payment Status : Paid</p>

                                    </div>
                                </Col>
                            </Row>

                            <div className='trackproductImage'>
                                <img src={IMG_URL + orderData?.product_variant?.product_images[0]?.image} className="product-image" />
                            </div>


                            <div className='orderstatushead'>
                                <h5>Order Status</h5>
                                {/* <div className="order-status">
                                    {orderData?.order_histories?.map((val,index)=>
                                    <div className="status-step completed">
                                        <div className='d-flex'>
                                            <div className="status-icon"></div>
                                            <div className='blueee-borderrr'></div>
                                        </div>
                                        <p>{val?.order_status?.order_status}</p>
                                        <p>{formatDate(val?.createdAt)}</p>
                                    </div>
                                    )}
                                    <div className="status-step completed">
                                        <div className='d-flex'>
                                            <div className="status-icon"></div>
                                            <div className='blueee-borderrr'></div>
                                        </div>
                                        <p>Processing</p>
                                        <p>20 Feb</p>
                                    </div>
                                    <div className="status-step completed">
                                        <div className='d-flex'>
                                            <div className="status-icon"></div>
                                            <div className='blueee-borderrr'></div>
                                        </div>
                                        <p>Shipped</p>
                                        <p>21 Feb</p>
                                    </div>
                                    <div className="status-step">
                                        <div className="status-icon"></div>
                                        <p>Delivered</p>
                                        <p>21 Feb</p>
                                    </div>
                                </div> */}

                                <div className="stepper-div">

                                    {/* <ProgressBar percent={stepPercentage}> */}
                                    {orderData?.return_order ? (
                                        // Render the ProgressBar for return orders
                                        <ProgressBar percent={orderData?.return_order?.return_status_id === 1 ? 0 : orderData?.return_order?.return_status_id === 2 ? 34 : orderData?.return_order?.return_status_id === 3 ? 68 : orderData?.return_order?.return_status_id === 4 ? 100 : 0}>
                                        <Step>
                                            {({ accomplished, index }) => (
                                                <div
                                                    className={`indexedStep ${accomplished ? "accomplished" : null
                                                        }`}
                                                >
                                                    <div className="range-text-div">
                                                       <h6 className="placed">Return Requested</h6>
                 {/* <p className="date">20 Dec</p> */}
                                                    </div>
                                                </div>
                                            )}
                                        </Step>
                                        <Step>
                                            {({ accomplished, index }) => (
                                                <div
                                                    className={`indexedStep ${accomplished ? "accomplished" : null
                                                        }`}
                                                >
                                                    <div className="range-text-div">
                                                       <h6 className="placed">Return Approved</h6>
                  {/*<p className="date">20 Dec</p> */}
                                                    </div>
                                                </div>
                                            )}
                                        </Step>

                                        <Step>
                                            {({ accomplished, index }) => (
                                                <div
                                                    className={`indexedStep ${accomplished ? "accomplished" : null
                                                        }`}
                                                >
                                                    <div className="range-text-div">
                                                      <h6 className="placed">Product Picked</h6>
                   {/*<p className="date">Est. 30 Dec</p> */}
                                                    </div>
                                                </div>
                                            )}
                                        </Step>

                                        <Step>
                                            {({ accomplished, index }) => (
                                                <div
                                                    className={`indexedStep ${accomplished ? "accomplished" : null
                                                        }`}
                                                >
                                                    <div className="range-text-div">
                                                        <h6 className="placed">Refund Process</h6>
                 {/*<p className="date">Est. 30 Dec</p> */}
                                                    </div>
                                                </div>
                                            )}
                                        </Step>
                                    </ProgressBar>
                                        
                                    ) : (
                                        // Render the original ProgressBar for order status
                                        <ProgressBar percent={orderData?.order_status?.id === 1 ? 0 : orderData?.order_status?.id === 2 ? 34 : orderData?.order_status?.id === 3 ? 68 : orderData?.order_status?.id === 4 ? 100 : 0}>
                                            <Step>
                                                {({ accomplished, index }) => (
                                                    <div
                                                        className={`indexedStep ${accomplished ? "accomplished" : null
                                                            }`}
                                                    >
                                                        <div className="range-text-div">
                                                           <h6 className="placed">Order Placed</h6>
                     {/* <p className="date">20 Dec</p> */}
                                                        </div>
                                                    </div>
                                                )}
                                            </Step>
                                            <Step>
                                                {({ accomplished, index }) => (
                                                    <div
                                                        className={`indexedStep ${accomplished ? "accomplished" : null
                                                            }`}
                                                    >
                                                        <div className="range-text-div">
                                                           <h6 className="placed">Processing</h6>
                      {/*<p className="date">20 Dec</p> */}
                                                        </div>
                                                    </div>
                                                )}
                                            </Step>

                                            <Step>
                                                {({ accomplished, index }) => (
                                                    <div
                                                        className={`indexedStep ${accomplished ? "accomplished" : null
                                                            }`}
                                                    >
                                                        <div className="range-text-div">
                                                          <h6 className="placed">Shipped</h6>
                       {/*<p className="date">Est. 30 Dec</p> */}
                                                        </div>
                                                    </div>
                                                )}
                                            </Step>

                                            <Step>
                                                {({ accomplished, index }) => (
                                                    <div
                                                        className={`indexedStep ${accomplished ? "accomplished" : null
                                                            }`}
                                                    >
                                                        <div className="range-text-div">
                                                            <h6 className="placed">Delivered</h6>
                     {/*<p className="date">Est. 30 Dec</p> */}
                                                        </div>
                                                    </div>
                                                )}
                                            </Step>
                                        </ProgressBar>
                                    )}
                                </div>

                            </div>



                            <Row>
                                <Col md={12} className="return-button-container">
                                    <Button className="return-button" onClick={props.onHide}>Close</Button>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                </Modal.Body>

            </Modal>
        </>
    )
}

export default ViewTrackmodal