import React, { useEffect, useRef, useState, useContext } from "react";
import "./Careers.css";
import { Button, Col, Row } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ResumeModal from "../Common-Component/Commonmodal/ResumeModal/ResumeModal";
import { Context } from "../../utils/context";
import { blog } from "../../utils/apis/Blog/Blog";
import parse from "html-react-parser";
import { Link, useNavigate } from "react-router-dom";
import { Jobsdata } from "../../utils/apis/carreer/career";
// const jobListings = [
//     {
//         title: '1. Jr. H.R. Executive',
//         description:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
//         details: {
//             pay: '₹ 2,00,000 - ₹ 7,00,000 per annum',
//             shift: 'Day-Shift',
//             location: 'Bavdhan, Pune',
//             experience: '1 - 3 years',
//             language: 'English, Hindi, Marathi',
//             jobType: 'Full Time',
//             vacancy: '1',
//             skills: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
//             deadline: '20/10/2023',
//             qualification: 'Graduated, Certification in Ui Ux design',
//         },
//         contact: {
//             name: 'Deepti Deshmukh (HR Manager)',
//             company: 'Mittal Craftworks Pvt. Ltd.',
//             phone: '+91 9876543210',
//             email: 'employer@mittalcraftworks.com',
//         },
//         responsibilities: [
//             'Lorem Ipsum is simply dummy text of the printing and typesetting industry',
//             'Lorem Ipsum is simply dummy text of the printing and typesetting industry',
//             'Lorem Ipsum is simply dummy text of the printing and typesetting industry',
//             'Lorem Ipsum is simply dummy text of the printing and typesetting industry',
//             'Lorem Ipsum is simply dummy text of the printing and typesetting industry',
//         ],
//     },
//     {
//         title: '2. Sr. Marketing Executive',
//         description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
//         details: {
//             pay: '₹ 2,00,000 - ₹ 7,00,000 per annum',
//             shift: 'Day-Shift',
//             location: 'Bavdhan, Pune',
//             experience: '1 - 3 years',
//             language: 'English, Hindi, Marathi',
//             jobType: 'Full Time',
//             vacancy: '1',
//             skills: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
//             deadline: '20/10/2023',
//             qualification: 'Graduated, Certification in Ui Ux design',
//         },
//         contact: {
//             name: 'Deepti Deshmukh (HR Manager)',
//             company: 'Mittal Craftworks Pvt. Ltd.',
//             phone: '+91 9876543210',
//             email: 'employer@mittalcraftworks.com',
//         },
//         responsibilities: [
//             'Lorem Ipsum is simply dummy text of the printing and typesetting industry',
//             'Lorem Ipsum is simply dummy text of the printing and typesetting industry',
//             'Lorem Ipsum is simply dummy text of the printing and typesetting industry',
//             'Lorem Ipsum is simply dummy text of the printing and typesetting industry',
//             'Lorem Ipsum is simply dummy text of the printing and typesetting industry',
//         ],
//     },
// ];

const Careers = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const [offers, setOffers] = useState();
  const navigate = useNavigate();
  // useEffect(() => {
  //     if (!offers || offers.length === 0) {
  //         navigate("/pageNotfound");
  //     }
  // }, []);
  const [resumemodal, setResumemodal] = useState(false);
  const [JobId, setJobId] = useState(null);
  const { IMG_URL } = useContext(Context);

  const [data, setBlog] = useState([]);
  const getBlog = async () => {
    const res = await blog();
    if (res?.success) {
      setBlog(res?.data);
    }
  };

  const getAllData = async () => {
    const res = await Jobsdata();
    if (res?.success) {
      setOffers(res?.data);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };

  useEffect(() => {
    getAllData();
    getBlog();
  }, []);

  const formattedOffers = offers?.map((val, index) => ({
    id: val?.id,
    image:IMG_URL+val?.image,
    title: `${++index}.${val?.name}`,
    description:
      typeof val?.requirement === "string" ? parse(val?.requirement) : "",
    details: {
      pay: `₹ ${val?.pay_from} - ₹ ${val?.pay_to} per annum`,
      shift: val?.department?.name,
      location: `${val?.country?.name} , ${val?.country?.name} ,${val?.country?.name}`,
      "Experience Required": `${val?.exp_from} - ${val?.exp_to} years`,
      language: val?.language_details?.map((val1) => `${val1?.language?.name}`),
      jobType: val?.job_type?.name,
      "Number Of vacancy": val?.vacancy,
      skills: typeof val?.skills === "string" ? parse(val?.skills) : "",
      "Application Deadline Date :": val?.deadline,
      qualification: val?.qualification?.name,
    },
    contact: {
      name: val?.hr_name,
      company: val?.company_name,
      phone: val?.recruiter_contact,
      email: val?.recruiter_email,
    },
    responsibilities: [
      typeof val?.job_responsibilities === "string"
        ? parse(val?.job_responsibilities)
        : "",
    ],
  }));

  const handleApplyForJob = (id) => {
    // Your function logic here
    setJobId(id);
    setResumemodal(true);
  };


  return (
    <section className="Careers">
      <div className="container">
        <div className="careerHead">
          <h3>Careers @ MITTAL</h3>
        </div>
        <div className="Careerscard">
          <Accordion>
            {formattedOffers?.map((job, index) => (
              <Accordion.Item
                eventKey={index.toString()}
                key={index}
                onClick={() => handleToggle(index)}
              >
                <Accordion.Header>
                  <div className="blueCard ">
                    <div className="bluecard_head">
                      <Row>
                        <Col lg={6} md={6} xs={8}>
                          <h3>{job.title}</h3>
                        </Col>
                        <Col lg={6} md={6} xs={4}>
                          <div className="viewtxt text-end">
                            <span>
                              {activeIndex === index
                                ? "View Less"
                                : "View More"}
                            </span>
                          </div>
                        </Col>
                      </Row>

                      <div className="job-description-holder">
                        <div className="job-description-img-holder">
                          <img
                            src={job?.image}
                            className="job-description-img"
                            alt="Job description"
                          />
                        </div>

                        <p> {job.description}</p>
                      </div>
                    </div>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="accordDetailsMain">
                    <h3 className="main-heading">Job Details</h3>
                    <div className="jobInfodetails">
                      <Row>
                        {Object.entries(job.details).map(
                          ([key, value], detailIndex) => (
                            <Col lg={6} md={6} key={detailIndex}>
                              <div className="JobHeading">
                                <div>
                                  <img
                                    src={`${
                                      process.env.PUBLIC_URL
                                    }/assest/image/Careers/icon_${detailIndex +
                                      1}.png`}
                                    className="Jobdetailsicons"
                                    alt={`${key} icon`}
                                  />
                                </div>
                                <div>
                                  <h3>
                                    {key.charAt(0).toUpperCase() + key.slice(1)}
                                  </h3>
                                  <p>{value}</p>
                                </div>
                              </div>
                            </Col>
                          )
                        )}
                      </Row>
                    </div>
                    <div className="RolesResponsbility">
                      <div className="responsibilityhead">
                        <h3 className="main-heading"> 
                          <FontAwesomeIcon icon="fa-solid fa-user-gear" /> Roles
                          and responsibilities:
                        </h3>
                      </div>
                      <div className="responsibilityList">
                        <ul>
                          {job.responsibilities.map(
                            (responsibility, respIndex) => (
                              <li key={respIndex}>
                                <img
                                  src={`${process.env.PUBLIC_URL}/assest/image/Careers/tickIcon.png`}
                                  className="tickicon"
                                  alt="Tick icon"
                                />
                                {responsibility}
                              </li>
                            )
                          )}
                        </ul>
                      </div>
                    </div>

                    <div className="RolesResponsbility contactInfo">
                      <div className="responsibilityhead">
                        <h3 className="main-heading">Contact:</h3>
                      </div>
                      <div className="userName">
                        <p>{job.contact.name}</p>
                        <p>{job.contact.company}</p>
                      </div>
                      <Row>
                        <Col lg={6}>
                          <Row className="emailContactinfo">
                            <Col lg={6}>
                              <p className="requtertext">
                                <b>Recruiter Contact Number:</b>
                              </p>
                              <p className="requtertext">
                                <b>Recruiter Email:</b>
                              </p>
                            </Col>
                            <Col lg={6}>
                              <p>{job.contact.phone}</p>
                              <p>{job.contact.email}</p>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>

                    <div className="Applyforjob text-center mb-4">
                      <Button
                        className="btn"
                        type="button"
                        onClick={() => handleApplyForJob(job?.id)}
                      >
                        Apply For Job
                      </Button>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </div>

        <ResumeModal
          JobId={JobId}
          show={resumemodal}
          onHide={() => setResumemodal(false)}
        />
      </div>
    </section>
  );
};

export default Careers;
