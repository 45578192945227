import React, { useEffect, useRef, useState, useContext } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";
import "./Editproflemodal.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import SuccessfullyModal from "../SuccessfullyModal/SuccessfullyModal";

import Cookies from "js-cookie";
import { Link, useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import classNames from "classnames";

import { RegxExpression } from "../../../../utils/common";

import { UserGet, UserEdit } from "../../../../utils/apis/myAccount/myAccount";
const Editproflemodal = (props) => {
  const [value, setValue] = useState();
  const [successshow, setSuccessshow] = useState(false);

  const modalclose = () => {
    props.setEditshow(false);
    setSuccessshow(true);
    setTimeout(() => {
      setSuccessshow(false);
    }, 3000);
  };
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset,
    trigger,
  } = useForm();

  const onSubmit = async (data) => {
    const formData = new FormData();

    formData.append("first_name", data?.first_name);
    formData.append("last_name", data?.last_name);
    formData.append("contact_no", data?.contact_no);

    const res = await UserEdit(formData);
    if (res?.success) {
      props.getAllData();
      //   customiseClose()
      modalclose();
      const res = await UserGet();
      if (res?.success) {
        props.setData(res?.data);
      } else {
        console.error("Error fetching  data:", res?.error);
      }
    } else {
    }
  };

  const [data, setData] = useState();

  const getAlleditData = async () => {
    const res = await UserGet();
    if (res?.success) {
      setData(res?.data);

      reset(res?.data);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };

  useEffect(() => {
    getAlleditData();
  }, []);

  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="editproflemodal"
      >
        <Modal.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group
              className="mb-3 row"
              controlId="exampleForm.ControlInput1"
            >
              <div className="col-lg-2 mt-auto">
                <Form.Label className="mb-0">
                  <p className="mb-0">Name</p>
                </Form.Label>
              </div>
              <div className="col-lg-10">
                <Form.Control
                  type="text"
                  name="first_name"
                  placeholder="Enter Name"
                  {...register("first_name", {
                    required: "first_name required",
                  })}
                  className={classNames("", {
                    "is-invalid": errors?.first_name,
                    "is-valid": getValues("first_name"),
                  })}
                  onKeyDown={(event) => {
                    if (!RegxExpression.name.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </div>
            </Form.Group>

            <Form.Group
              className="mb-3 row"
              controlId="exampleForm.ControlInput1"
            >
              <div className="col-lg-2 mt-auto">
                <Form.Label className="mb-0">
                  <p className="mb-0">Name</p>
                </Form.Label>
              </div>
              <div className="col-lg-10">
                <Form.Control
                  type="text"
                  name="last_name"
                  placeholder="Enter last_name"
                  {...register("last_name", {
                    required: "last_name required",
                  })}
                  className={classNames("", {
                    "is-invalid": errors?.last_name,
                    "is-valid": getValues("last_name"),
                  })}
                  onKeyDown={(event) => {
                    if (!RegxExpression.name.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
              </div>
            </Form.Group>

            <div className="row">
              <div className="col-lg-2 mt-auto">
                <Form.Label className="mb-0">
                  <p className="mb-0">Contact</p>
                </Form.Label>
              </div>
              <div className="col-lg-10 ">
                <Controller
                  name="contact_no"
                  control={control}
                  rules={{
                    required: "Field is required",
                    pattern: {
                      value: /^\+?\d{1,4}[-\s]?\d{10}$/,
                      message:
                        "Invalid number format. Please enter a valid number with an optional country code",
                    },
                  }}
                  render={({ field }) => (
                    <PhoneInput
                      {...field}
                      className={classNames("", {
                        "is-invalid": errors?.contact_no,
                        "is-valid": getValues("contact_no"),
                      })}
                      onChange={(value) => field.onChange(value)}
                      type="text"
                      placeholder="Enter Mobile Number"
                      onKeyDown={(event) => {
                        if (!RegxExpression.phone.test(event.key)) {
                          if (event.key !== "Backspace") {
                            event.preventDefault();
                          }
                        }
                      }}
                      maxLength={10}
                    />
                  )}
                />
                {errors?.contact_no && (
                  <sup className="text-danger">
                    {errors?.contact_no?.message}
                  </sup>
                )}
              </div>
            </div>

            <div className="btnMainsec">
              <Button className="updatebtn" onClick={props.onHide}>
                Cancel
              </Button>
              <Button className="savebtn" type="submit">
                Save
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      <SuccessfullyModal
        show={successshow}
        onHide={() => setSuccessshow(false)}
        text={"Profile Updated Successfully"}
      />
    </>
  );
};

export default Editproflemodal;
