import React, { useState } from 'react';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import { cancelorder } from "../../../../utils/apis/orderProcess/orderProcess";

const Ordercancel = (props) => {
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const [selectedReason, setSelectedReason] = useState("");

    const onSubmit = async (data) => {
      
        const formData = new FormData();
        formData.append("reason", data?.reason);
        formData.append("remark", data?.remark);
        formData.append("order_id", props?.orderId);

        try {
            const res = await cancelorder(formData);
          
            if (res?.success) {
                props.getAllData()
                props.onHide();
            } else {
                console.error('Failed to return order:', res); // Error handling
            }
        } catch (error) {
            console.error('Error during form submission:', error); // Error handling
        }
    };

    const handleReasonChange = (event) => {
        setSelectedReason(event.target.value);
    };
 

    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='Returnmodal'
            >
                <Modal.Body>
                    <Container className="order-return-container">
                        <Row className="justify-content-center">
                            <Col md={6}>
                                <Form className="order-return-form" onSubmit={handleSubmit(onSubmit)}>
                                    <div className='returnHead'>
                                        <h3>Order Cancellation</h3>
                                    </div>
                                    <Form.Group className="form-group">
                                        <Form.Label>Select a reason for cancellation</Form.Label>
                                        <div>
                                            {['Product not required anymore', 'Product price increased', 'Wrong contact number /address added', 'Incorrect product placed', 'Delivery time too long', 'Incorrect payment method selected', 'Other'].map((reason, idx) => (
                                                <Form.Check
                                                    type="radio"
                                                    id={`reason-${idx}`}
                                                    label={reason}
                                                    name="reason"
                                                    value={reason}
                                                    {...register("reason", { required: "Reason Is Required", })}
                                                    key={idx}
                                                    className="form-check"
                                                    onChange={handleReasonChange}
                                                />
                                            ))}
                                        </div>
                                        {errors.reason && (
                                            <span className="text-danger">
                                                {errors.reason.message}
                                            </span>
                                        )}
                                        <Form.Control
                                            as="textarea"
                                            placeholder="Please specify"
                                            className="form-control"
                                            {...register("remark")}
                                        />
                                    </Form.Group>
                                    <div className="btn-container">
                                        <Button variant="secondary" onClick={props.onHide} className="cancelbtn">Cancel</Button>
                                        <Button variant="primary" type="submit" className="submitbtn">Submit</Button>
                                    </div>
                                </Form>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default Ordercancel;
