import React, { useContext, useEffect, useState } from 'react';
import './Login.css';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import { Link, useNavigate } from 'react-router-dom';
import { userLogin } from '../../utils/apis/login/Login';
import { useForm } from 'react-hook-form';
import { Context } from '../../utils/context';
import Cookies from "js-cookie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from "react-bootstrap/Form";
import classNames from "classnames";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { loginCart } from "../../utils/apis/addToCart/addToCart";
import LoginSeller from "./LoginSeller"
import Cookies_popup_modal from '../Common-Component/Commonmodal/Cookies_popup_modal/Cookies_popup_modal';
const Login = () => {
    const [showForgetPassword, setShowForgetPassword] = useState(false);
    const [showEmail, setShowEmail] = useState(true);
   
    const [showPassword, setShowPassword] = useState(false);
    const [iconOne, setIconOne] = useState(faEye);
    const [phoneValue, setPhoneValue] = useState("");
    const navigate = useNavigate();
    const [modalShow, setModalShow] = useState(false);



    const togglePasswordVisibility = (field) => {
        if (field === "password") {
            setShowPassword(!showPassword);
            setIconOne(showPassword ? faEyeSlash : faEye);
        }
    };

    const {
        signin,
        setSignin, shimmerLoader, setShimmerLoader, isCookieAccepted, setIsCookieAccepted,IMG_URL,setSigninGoogle } = useContext(Context);

    const {
        control,
        register,
        handleSubmit,
        getValues,
        setError,
        clearErrors,
        formState: { errors },
        reset,
        trigger, setValue
    } = useForm();


    const [showCookiesModal, setShowCookiesModal] = useState(true);
    const onSubmit = async (data) => {
        if (!isCookieAccepted) {
            setShowCookiesModal(true);
            return;
        }
        setShimmerLoader(true);
        const res = await userLogin(data);
        if (res?.success) {
            await Cookies.set('coinz_kart_security', res?.data?.token);
            await setSignin(true);
            await loginCart();

            setModalShow(true);
            setTimeout(() => {
                // setModalShow(false);
                setShimmerLoader(false);
                navigate(-1);
            }, 1000);
        } else {
            setShimmerLoader(false);
            if (res?.message) {
                setError('email_or_contact', { message: res?.message });
                setError('password', "");
            }
        }
    }


    const handleEmailOrContactChange = (event) => {
        setValue("email_or_contact", event.target.value);
    };
    const handleAcceptCookies = () => {
        setShowCookiesModal(false);
    };

    function googleLogin() {
        const backendURL = IMG_URL;
        const path = "/api/auth/google"; // Dynamic path based on condition or variable
      
        // Dynamically construct the full URL
        const dynamicURL = `${backendURL}${path}`;
      
        // Open the URL in the current window
        window.open(dynamicURL, "_self"); // "_self" opens in the same tab
        setSigninGoogle(true);
      }
    return (
        <div className="login-container">
            <div className="blueOverlay"></div>
            <div className="login-box">
                <div className='logosection'>
                    <img src={process.env.PUBLIC_URL + "/assest/image/logo/header-logo.png"} />
                    <Link to={"/"}>
                        <p className='backtohome'>Back to Home</p>

                    </Link>
                </div>

                <Row className="justify-content-center me-0 ms-0">
                    <Col xxl={4} xl={4} md={8}>
                        <div className="login-header">
                            <Tab.Container id="left-tabs-example" defaultActiveKey="first">

                                <Nav variant="pills">
                                    <div className='row'>
                                        <div className='col-lg-6'>
                                            <Nav.Item>
                                                <Nav.Link eventKey="first">User Log-in</Nav.Link>
                                            </Nav.Item>

                                        </div>
                                        <div className='col-lg-6'>
                                            <Nav.Item>
                                                <Nav.Link eventKey="second">Seller Log-in</Nav.Link>
                                            </Nav.Item>

                                        </div>
                                    </div>
                                </Nav>

                                <Tab.Content className='tablogin'>
                                    <Tab.Pane eventKey="first">
                                        <Form onSubmit={handleSubmit(onSubmit)}>
                                            <Form.Group className="mb-3" controlId="">
                                                <label htmlFor="email" className='emailtxt'>Email or Mobile Number</label>
                                                <Form.Control
                                                    type="text"
                                                    name="email_or_contact"
                                                    placeholder="Email / Mobile Number"
                                                    {...register("email_or_contact", {
                                                        required: "Credentials required",
                                                    })}
                                                    className={classNames("", {
                                                        "is-invalid": errors?.email_or_contact,
                                                        // "is-valid": getValues("email_or_contact"),
                                                    })}
                                                    onChange={handleEmailOrContactChange}
                                                />
                                                {errors?.email_or_contact && (
                                                    <sup className="text-danger">{errors?.email_or_contact?.message}</sup>
                                                )}
                                            </Form.Group>
                                            <div className="form-group">
                                                <label className='emailtxt' htmlFor="password">Password</label>
                                                <Form.Group className="mb-3" controlId="">
                                                    <div className="password-container">
                                                        <Form.Control
                                                            type={showPassword ? "text" : "password"}
                                                            placeholder="Enter Password*"
                                                            name="password"
                                                            {...register("password", {
                                                                required: "Password is required"
                                                            })}
                                                            className={classNames("", {
                                                                "is-invalid": errors?.password,
                                                                "is-valid": getValues("password"),
                                                            })}
                                                        />
                                                        <span className="password-icon" onClick={togglePasswordVisibility}>
                                                            <FontAwesomeIcon
                                                                icon={iconOne}
                                                                className="eye-icon"
                                                                onClick={() => togglePasswordVisibility("password")}
                                                            />
                                                        </span>
                                                        {/* <span className="password-icon" onClick={togglePasswordVisibility}>👁️</span> */}
                                                    </div>
                                                </Form.Group>
                                                {errors?.password && (
                                                    <sup className="text-danger">{errors?.password?.message}</sup>
                                                )}
                                            </div>
                                            <div className="forgot-password">
                                                <Link to={"/forgetpassword"}>Forgot Password?</Link>
                                            </div>
                                            <button type="submit" className="btn btn-primary btn-block">Log-in</button>
                                        </Form>
                                        <div className="signup-link">
                                            <p>Don't have an account? <Link to={"/register"} ><span style={{ color: "#234C9E", fontWeight: "600", textDecoration: "underline" }}>Create Account</span></Link></p>
                                        </div>
                                        <div  onClick={googleLogin} className="social-login">
                                            <p>You can also login by using your</p>
                                            <div className="social-icons">
                                                {/* <img src={process.env.PUBLIC_URL + "/assest/image/login/facebook.png"} alt="Google" /> */}
                                                <img
                                                    src={process.env.PUBLIC_URL + "/assest/image/login/google.png"}
                                                    alt="Google"
                                                  
                                                    style={{ cursor: 'pointer' }}
                                                />login with google
                                                {/* <img src={process.env.PUBLIC_URL + "/assest/image/login/google.png"} alt="Google" /> */}
                                                {/* <img src={process.env.PUBLIC_URL + "/assest/image/login/twitter.png"} alt="Google" /> */}
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="second">

                                        <LoginSeller />
                                    </Tab.Pane>
                                </Tab.Content>

                            </Tab.Container>
                        </div>
                    </Col>
                </Row>
            </div>
            {!isCookieAccepted && (
                <Cookies_popup_modal
                    isCookieAccepted={isCookieAccepted}
                    setIsCookieAccepted={setIsCookieAccepted}
                    show={showCookiesModal}
                    onHide={() => setShowCookiesModal(false)}
                    onAcceptCookies={handleAcceptCookies}
                />
            )}
        </div>
    );
};

export default Login;
