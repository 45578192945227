import React, { useEffect, useRef, useState, useContext } from 'react'
import "./ArticlesInpiration.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { Context } from "../../../utils/context";

import parse from "html-react-parser";
const ArticlesInpiration = ({data}) => {
   
    const { IMG_URL } = useContext(Context);

    return (
        <>
            <section className='ArticlesInpiration'>
                <div className='container'>
                    <div className='articleshead'>
                        <h3>
                            <span>Articles & </span>
                            <span>Inspiration</span>
                        </h3>
                        <p>Where Ideas Flourish: Explore Our Blogverse!</p>
                    </div>

                    <div className='row'>
                        <div className='col-lg-8 col-md-8'>

                            <div className='articlesImg1'>
                                <img src={IMG_URL +data?.data[0]?.image} />
                                <div className='overlay-color'></div>
                                <Link to={`/blog-details/${data?.data[0]?.id}`}>
                                    <div className='imageinfo1'>
                                        <h3>{data?.data[0]?.name}</h3>
                                        {/* <p>{parse(data?.data[0]?.description) || ""}</p> */}
                                        <p>{typeof data?.data[0]?.description === 'string' ? parse(data?.data[0]?.description) : ""}</p>
                                        <p>Read More <FontAwesomeIcon icon="fa-solid fa-angle-right" /></p>
                                    </div>
                                </Link>


                            </div>


                        </div>
                        <div className='col-lg-4 col-md-4'>
                            {data?.data?.map((val, index) => (
                                // Exclude rendering for the first item (index === 0)
                                index > 0 && index < 3 && (

                                    <div className='articlesImg2'>
                                        <img src={IMG_URL + val?.image} />
                                        <div className='overlay-color'></div>
                                        <div className='imageinfo2'>
                                            <Link to={`/blog-details/${val?.id}`}>
                                                <h3>{val?.name}</h3>
                                                <p>{parse(val?.description) || ""}</p>
                                                <p>Read More <FontAwesomeIcon icon="fa-solid fa-angle-right" /></p>
                                            </Link>
                                        </div>
                                    </div>
                                )
                            ))}

                            {/* <div className='articlesImg2'>
                                <img src={process.env.PUBLIC_URL + "/assest/image/home/articlesImg1.png"} />
                                <div className='overlay-color'></div>
                                <div className='imageinfo2'>
                                    <Link to={"/blog-details"}>
                                        <h3>Gift Coins</h3>
                                        <p>Gift a piece of history with our timeless coin collections, each one a tangible...</p>
                                        <p>Read More <FontAwesomeIcon icon="fa-solid fa-angle-right" /></p>
                                    </Link>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ArticlesInpiration
