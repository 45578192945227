import React, { useEffect, useRef, useState, useContext } from "react";
import "./RequestQuotation.css";
import Breadcrumbs from "../Common-Component/Breadcrumbs/Breadcrumbs";
import Form from "react-bootstrap/Form";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Button, Col, Row } from "react-bootstrap";
import { Uploader } from "uploader";
import { UploadButton } from "react-uploader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SuccessfullyModal from "../Common-Component/Commonmodal/SuccessfullyModal/SuccessfullyModal";
import { Context } from "../../utils/context";
import Cookies from "js-cookie";
import { Link, useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import classNames from "classnames";
import { RegxExpression } from "../../utils/common";
import {
  getProductVariant,
  getVariant,
} from "../../utils/apis/Product/Product";
import { Material } from "../../utils/apis/master/Master";
import { RFQ } from "../../utils/apis/form/form";
import { useParams } from "react-router-dom";
import Select from "react-select";
import { allCategory } from "../../utils/apis/master/Master";
const RequestQuotation = () => {
  const [value, setValue] = useState();
  const [showSuccess, setShowsuccess] = useState(false);
  const items = [
    { id: 1, text: "HOME", link: "/" },
    { id: 1, text: "REQUEST FOR QUOTATION", link: "#" },
  ];

  const uploader = Uploader({
    apiKey: "free",
  });

  const options = { multi: true };

  const customiseClose = () => {
    setShowsuccess(true);
    setTimeout(() => {
      setShowsuccess(false);
      window.location.pathname = "/home";
    }, 3000);
  };

  const fileInputRef = useRef(null);
  const { id } = useParams();
  const {
    signin,
    setSignin,
    shimmerLoader,
    setShimmerLoader,
    Select2Data,
    IMG_URL,
  } = useContext(Context);

  const [selectedFiles, setSelectedFiles] = useState([]);
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset,
    trigger,
  } = useForm();

  const onSubmit = async (data) => {
    setShimmerLoader(true);

    const formData = new FormData();
    // formData.append("medal", data?.medal);
    formData.append("category_id", data?.category_id?.value);
    formData.append("requirement", data?.requirement);
    formData.append("company_name", data?.company_name);
    formData.append("name", data?.name);
    formData.append("email", data?.email);
    formData.append("contact_no", data?.contact_no);
    if (selectedFiles.length > 0) {
      selectedFiles?.map((image, index) => {
        formData.append(`images[${index}]`, image);
      });
    }
    const res = await RFQ(formData);
    if (res?.success) {
      // onClick={customiseClose}
      customiseClose();
    } else {
    }
  };

  const handleimageClick = (event) => {
    event.preventDefault();
    fileInputRef.current.click();
  };
  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFiles([...selectedFiles, ...files]);
  };

  const handleRemoveImage = (index) => {
    const filteredFiles = selectedFiles.filter((_, i) => i !== index);
    setSelectedFiles(filteredFiles);
  };

  const [categorydata, setCategorydata] = useState();
  const GetAllcategory = async () => {
    const response = await allCategory(2);

    if (response?.success) {
      setCategorydata(await Select2Data(response?.data, "material_id"));
    }
  };
  useEffect(() => {
    GetAllcategory();
  }, []);
  return (
    <>
      <section className="RequestQuote">
        <div className="container">
          <Breadcrumbs items={items} />
          <div className="RequestQuotehead">
            <h3>Request for Quotation</h3>
          </div>

          <Row>
            <Col xl={6} lg={6} md={7}>
              <div className=" Onbannerform">
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Control
                      type="text"
                      name="name"
                      placeholder="Enter Name"
                      {...register("name", {
                        required: "name required",
                      })}
                      className={classNames("", {
                        "is-invalid": errors?.name,
                        "is-valid": getValues("name"),
                      })}
                      onKeyDown={(event) => {
                        if (!RegxExpression.name.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </Form.Group>

                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Control
                      type="text"
                      name="email"
                      placeholder="Enter Email"
                      {...register("email", {
                        validate: (value) =>
                          value || getValues("contact_no")
                            ? true
                            : "Email or Mobile Number is required",
                        pattern: {
                          value: RegxExpression?.email,
                          message: "Invalid email address",
                        },
                      })}
                      className={classNames("", {
                        "is-invalid": errors?.email,
                        "is-valid": getValues("email"),
                      })}
                    />
                  </Form.Group>

                  <Controller
                    name="contact_no"
                    control={control}
                    rules={{
                      validate: (value) =>
                        value || getValues("email")
                          ? true
                          : "Mobile Number or Email is required",
                      pattern: {
                        value: /^\+?\d{1,4}[-\s]?\d{10}$/,
                        message:
                          "Invalid number format. Please enter a valid number with an optional country code",
                      },
                    }}
                    render={({ field }) => (
                      <PhoneInput
                        country={"in"}
                        {...field}
                        className={classNames("mb-3", {
                          "is-invalid": errors?.contact_no,
                          "is-valid": getValues("contact_no"),
                        })}
                        onChange={(value) => field.onChange(value)}
                        type="text"
                        placeholder="Enter Mobile Number"
                        onKeyDown={(event) => {
                          if (!RegxExpression.phone.test(event.key)) {
                            if (event.key !== "Backspace") {
                              event.preventDefault();
                            }
                          }
                        }}
                        maxLength={10}
                      />
                    )}
                  />
                  {errors?.contact_no && (
                    <sup className="text-danger">
                      {errors?.contact_no?.message}
                    </sup>
                  )}

                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Control
                      type="text"
                      name="company_name"
                      placeholder="Enter company name"
                      {...register("company_name", {
                        required: "company_name required",
                      })}
                      className={classNames("", {
                        "is-invalid": errors?.company_name,
                        "is-valid": getValues("company_name"),
                      })}
                    />
                  </Form.Group>

                  {/* <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Control
                                            type="text"
                                            name="medal"
                                            placeholder="Enter a Desired Product (e.g.: Medal / Coin)"
                                            {...register("medal", {
                                                required: "medal required",

                                            })}
                                            className={classNames("", {
                                                "is-invalid": errors?.medal,
                                                "is-valid": getValues("medal"),
                                            })}

                                        />
                                    </Form.Group> */}

                  <Col lg={6} md={6} className="mb-3">
                    <Controller
                      name="category_id" // name of the field
                      {...register("category_id", {
                        required: "Select Category",
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.material_id
                                ? "red"
                                : baseStyles,
                            }),
                          }}
                          {...field}
                          options={categorydata}
                        />
                      )}
                    />

                    {errors.category_id && (
                      <span className="text-danger">
                        {errors.category_id.message}
                      </span>
                    )}
                  </Col>

                  <div className="customCheck">
                    <Form.Label className="plus-btn me-5">
                      <div className="uploadDiv" onClick={handleimageClick}>
                        <FontAwesomeIcon icon="fa-solid fa-circle-plus" />
                        <p className="mb-2">Upload here</p>
                      </div>
                      <Form.Control
                        ref={fileInputRef}
                        type="file"
                        onChange={handleFileChange}
                        multiple
                        accept="image/"
                        style={{ display: "none" }}
                      />
                    </Form.Label>

                    <div className="image-holder">
                      {selectedFiles.length > 0 &&
                        selectedFiles.map((file, index) => (
                          <div className="file-img-holder" key={index}>
                            <object
                              className="img-logo"
                              data={URL.createObjectURL(file)}
                              type={file.type}
                              width="59" // Set the width
                              height="58" // Set the height
                            />
                            <div
                              className="cross-overlay"
                              onClick={() => handleRemoveImage(index)}
                            >
                              <img
                                className="img-cross"
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assest/image/Careers/crossIcon.png"
                                }
                                alt={`Cross Icon ${index + 1}`}
                              />
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>

                  <div className="uploaddesign">
                    <p className="mb-0">
                      <b>
                        Upload your Design sample/s, so that we will get to your
                        idea.
                      </b>
                    </p>
                    <p>
                      (Upload preferences: 10 Mb max PDF, AI, EPS, JPG, PNG,
                      JPEG)
                    </p>
                  </div>

                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Control
                      type="text"
                      name="requirement"
                      placeholder="Enter requirement"
                      {...register("requirement", {
                        required: "requirement is required",
                      })}
                      className={classNames("", {
                        "is-invalid": errors?.requirement,
                        "is-valid": getValues("requirement"),
                      })}
                    />
                  </Form.Group>

                  <div className="sendRequest">
                    <Button type="submit" className="sendbtn">
                      Send Request
                    </Button>
                  </div>
                </Form>
              </div>
            </Col>

            <Col xl={6} lg={6} md={5} className="my-auto">
              <div className="madeOrderimg">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/assest/image/SumbitRFQ/Illustration.png"
                  }
                />
              </div>
            </Col>
          </Row>
        </div>
      </section>
      <SuccessfullyModal
        show={showSuccess}
        onHide={() => setShowsuccess(false)}
        text={"Your Request has been send successfully"}
      />
    </>
  );
};

export default RequestQuotation;
