import React, { useState, useEffect, useContext } from "react";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import Common_Button from "../../../Common-Component/Common_Button/Common_Button";
import { Col, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';
import { useForm, Controller } from "react-hook-form";
import { Context } from "../../../../utils/context";
import classNames from "classnames";
import { RegxExpression } from "../../../../utils/common";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { sellerInformation,getsellerInformation } from "../../../../utils/apis/sellerForm/Sellerform";
library.add(fas);



const SellerInformation = ({ handleNext, handlePrevious, setAddressSeAddNewaddresslect, addressSelect }) => {
    const [isSaved, setIsSaved] = useState(false);
   

    const { signin, setSignin, shimmerLoader, setShimmerLoader } = useContext(Context);

    const {
        control,
        register,
        handleSubmit,
        getValues,
        setError,
        clearErrors,
        formState: { errors },
        reset,
        trigger,
    } = useForm();

    const [sellerId, setSellerId] = useState("");
    const onSubmit = async (data) => {
      
        setShimmerLoader(true);
      
            const formData = new FormData();
          
            formData.append("personal_detail_id", sellerId);          
            formData.append("gst_number", data?.gst_number);
            formData.append("store_url", data?.store_url);
            formData.append("store_name", data?.store_name);
           
            const res = await sellerInformation(formData);
            if (res?.success) {
               
                handleNext()
              
            } else {
               
                if (res?.message?.gst_number) {
                    setError("gst_number", { message: res?.message?.gst_number });
                }
                

            }

    };
   
   

    const getEditData = async () => {
        const res = await getsellerInformation(sellerId);
        if (res?.success) {
          reset(res?.data)
        }
      }
      useEffect(() => {
        getEditData();
      }, [sellerId]);
    useEffect(() => {    
        setSellerId(Cookies.get('seller_id'));
      }, []);
    return (
        <>
            <section className='stepp-inner-main'>

                <Row>
                    <Col lg={6} className='mx-auto'>
                        <Form>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Store Name*</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="Store Name"
                                    placeholder="Enter Store Name"
                                    {...register("store_name", {
                                        required: "Store Name required",

                                    })}
                                    className={classNames("", {
                                        "is-invalid": errors?.store_name,
                                        "is-valid": getValues("store_name"),
                                    })}
                                   
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Store URL (optional)</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="Store URL"
                                    placeholder="Enter Store URL"
                                    {...register("store_url", {
                                        pattern: {
                                            value: /^(https?:\/\/)?([a-z0-9]+[.])+[a-z0-9]+(\/.*)?$/i, // basic URL validation
                                            message: "Please enter a valid URL",
                                          },
                                    })}
                                    className={classNames("", {
                                        "is-invalid": errors?.store_url,
                                        "is-valid": getValues("store_url"),
                                    })}
                                  
                                />
                                {errors?.store_url && (
                                        <sup className="text-danger">
                                            {errors?.store_url?.message}
                                        </sup>
                                    )}
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>GST Number</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="gst_number"
                                    placeholder="Enter GST Number"
                                    {...register("gst_number", {
                                        // required: "GST Number required",
                                        pattern: {
                                            value: RegxExpression.gst,
                                            message: "Invalid GST Number",
                                        },

                                    })}
                                    className={classNames("", {
                                        "is-invalid": errors?.gst_number,
                                        "is-valid": getValues("gst_number"),
                                    })}
                                   
                                />
                                {errors?.gst_number && (
                                        <sup className="text-danger">
                                            {errors?.gst_number?.message}
                                        </sup>
                                    )}
                            </Form.Group>
                            

                        </Form>
                    </Col>
                </Row>
                <div className="save-btn-div">
                    <Common_Button onClick={handlePrevious} type='button' text={"Back"} className="back-btn" />
                    <Common_Button onClick={handleSubmit(onSubmit)} type='button' text={isSaved ? 'SAVE & NEXT' : 'Next'} className="continue-btn" />


                </div>
                <div className="signup-link">
                    <p>
                        By signing up, I agree to the{" "}
                        <Link to={"/terms-condition"}>
                            Terms and Conditions
                        </Link>
                    </p>

                </div>
                <div className="social-login pb-5">
                    <p>You can also login by using your</p>
                    <div className="social-icons">
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                "/assest/image/login/facebook.png"
                            }
                            alt="Google"
                        />
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                "/assest/image/login/google.png"
                            }
                            alt="Google"
                        />
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                "/assest/image/login/twitter.png"
                            }
                            alt="Google"
                        />
                    </div>
                </div>


            </section>



        </>
    )
}

export default SellerInformation