import React, { useEffect, useState } from "react";
import "./App.css";
import { useLocation } from "react-router-dom";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import AllRoutes from "./components/AllRoutes/AllRoutes";
import AppContext from "./utils/context";

function App() {
  const location = useLocation();
  const [showSplashScreen, setShowSplashScreen] = useState(false);
  const isWelcomePage = location.pathname === '/';
  const isAuthPage = location.pathname === '/login' || location.pathname === '/register' || location.pathname === '/forgetpassword';

  useEffect(() => {
    if (isWelcomePage) {
      setShowSplashScreen(true);
      const timer = setTimeout(() => {
        setShowSplashScreen(false);
      }, 3000); // 3 seconds

      return () => clearTimeout(timer); // Cleanup the timer
    }
  }, [isWelcomePage]);

  if (showSplashScreen) {
    return (
      <div className="splash-screen">
        <img
          className="headlogo"
          src={process.env.PUBLIC_URL + "/assest/image/logo/header-logo.png"}
          alt="Logo"
        />
      </div>
    );
  }

  return (
    <AppContext>
      <ScrollToTop />
      {!isWelcomePage && !isAuthPage && <Header />}
      <AllRoutes />
      {!isWelcomePage && !isAuthPage && <Footer />}
    </AppContext>
  );
}

export default App;
