import React, { useEffect, useRef, useState, useContext } from "react";
import "./Footer.css";
import { Link, useLoaderData, useLocation } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { Container, InputGroup, Row } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram, faLinkedin, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import Common_Button from "../Common-Component/Common_Button/Common_Button";
import { SocialMedia } from "../../utils/apis/master/Master";
import { Context } from "../../utils/context";
import { occasion } from '../../utils/apis/Home/Homeapi';
const Footer = () => {
  const location = useLocation();
  const [data, setData] = useState();
  const { IMG_URL, signin } = useContext(Context);
  const getAllData = async () => {
    const res = await SocialMedia();
    if (res?.success) {
      setData(res?.data);
    } else {
      console.error('Error fetching  data:', res?.error);
    }
  }

  useEffect(() => {
    getAllData();
  }, []);

  const [occasionprod, setOcassionprod] = useState([]);

  const getOcassion = async () => {
    const res = await occasion();
    if (res?.success) {
      setOcassionprod(res?.data)
    }
  }

  useEffect(() => {
    getOcassion();
  }, [])
  return (
    <>
      <section className="footer">
        <Container>
          <Row>
            <div className="col-lg-3 col-md-4 col-sm-6 col-6">
              <div className="Heading-holder">
                <h2 className="footer-title">THE COMPANY</h2>
                <Link to={"/aboutUs"}>
                  <p className={location.pathname === "/aboutUs" ? "addfooterline" : "removefooterline"}>ABOUT US</p>
                </Link>

                <Link to={"/relevantNews"}>
                  <p className={location.pathname === "/relevantNews" ? "addfooterline" : "removefooterline"}>LATEST NEWS</p>
                </Link>

                <Link to={"/legalNotice"}>
                  <p className={location.pathname === "/legalNotice" ? "addfooterline" : "removefooterline"}>LEGAL NOTICE</p>
                </Link>

                <Link to={"/responsibility"}>
                  <p className={location.pathname === "/responsibility" ? "addfooterline" : "removefooterline"}>RESPONSIBILITY</p>
                </Link>

                <Link to={"/privacypolicy"}>
                  <p className={location.pathname === "/privacypolicy" ? "addfooterline" : "removefooterline"}>PRIVACY POLICY</p>
                </Link>

                {/* <Link to={"/pageNotfound"}>
                  <p className={location.pathname === "/pageNotfound" ? "addfooterline" : "removefooterline"}> CAREERS @ MITTAL</p>
                </Link> */}


                <Link to={"/careers"}>
                  <p className={location.pathname === "/careers" ? "addfooterline" : "removefooterline"}>  CAREERS @ MITTAL CRAFTS WORK </p>
                </Link>

              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-6">
              <div className="Heading-holder">
                <h2 className="footer-title">OFFERINGS</h2>
              
                <Link to={"/all-category"}>
                  <p className={location.pathname === "/customizable-product" ? "addfooterline" : "removefooterline"}>PRODUCTS</p>
                </Link>

                {/* <p>GIFT BY PRICE</p> */}
                <Link to={"/customizable-product"}>
                  <p className={location.pathname === "/gift-automation" ? "addfooterline" : "removefooterline"}>GIFT BY PRICE</p>
                </Link>

                <Link to={"/gift-automation"}>
                  <p className={location.pathname === "/gift-automation" ? "addfooterline" : "removefooterline"}>GIFT AUTOMATION</p>
                </Link>

                <Link to={"/sustainable-product"}>
                  <p className={location.pathname === "/gift-automation" ? "addfooterline" : "removefooterline"}>SUSTAINABLE GIFTS</p>
                </Link>

                <Link to={"/gift-customization"}>
                  <p className={location.pathname === "/gift-automation" ? "addfooterline" : "removefooterline"}>GIFT CUSTOMISATION</p>
                </Link>

                

                <Link to={'/multi-recipient'}>
                  <p className={location.pathname === "/multi-recipient" ? "addfooterline" : "removefooterline"}>MULTI-RECIPIENT SENDING</p>
                </Link>
                
                {/* <Link to={"/sustainable-product"}>
                  <p className={location.pathname === "/sustainable-product" ? "addfooterline" : "removefooterline"}>SUSTAINABLE GIFTS</p>
                </Link> */}

                {/* */}


                <Link to={"/shopbyoccasion"}>
                  <p className={location.pathname === "/gift-customization" ? "addfooterline" : "removefooterline"}>GIFT BY OCCASIONS / EVENTS</p>
                </Link> 



                {/* <p>GIFT BY OCCASIONS / EVENTS</p> */}

              </div>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-6">
              <div className="Heading-holder">
                <h2 className="footer-title">POPULAR LINKS</h2>
                <Link to={"/blogs"}>
                  <p className={location.pathname === "/blogs" ? "addfooterline" : "removefooterline"}>BLOGS</p>

                </Link>


                {/* {occasionprod?.map((item) => */}
                  {occasionprod?.slice(0, 5).map((item) => (
                  <Link to={`/product/${item?.id}`}>
                    <p className={location.pathname === "/festivegifts" ? "addfooterline" : "removefooterline"}>{item?.name}</p>

                  </Link>
                ))}
                {/* <Link to={"/festivegifts"}>
                  <p className={location.pathname === "/festivegifts" ? "addfooterline" : "removefooterline"}>⁠RETIREMENT GIFTS</p>

                </Link>

                <Link to={"/festivegifts"}>
                  <p className={location.pathname === "/festivegifts" ? "addfooterline" : "removefooterline"}>EMPLOYEE WELCOME KITS</p>
                </Link>

                <Link to={"/festivegifts"}>
                  <p className={location.pathname === "/festivegifts" ? "addfooterline" : "removefooterline"}>EMPLOYEE BIRTHDAY GIFTS</p>
                </Link>
                <Link to={"/emp-satifaction"}>
                  <p className={location.pathname === "/emp-satifaction" ? "addfooterline" : "removefooterline"}>EMPLOYEE APPRECIATION GIFTS</p>

                </Link> */}
              </div>
            </div>
            <div className="col-lg-3 col-md-5 col-sm-6 col-6">
              <div className="Heading-holder">
                <h2 className="footer-title">SUPPORT</h2>
                <Link to={"/faq"}>
                  <p className={location.pathname === "/faq" ? "addfooterline" : "removefooterline"}>FAQ</p>
                </Link>

                <Link to={"/contactus"}>
                  <p className={location.pathname === "/contactus" ? "addfooterline" : "removefooterline"}>CONTACT US</p>
                </Link>

                {/* <p>UNSUBSCRIBE</p> */}

                <Link to={"/product-care"}>
                  <p>PRODUCT CARE</p>
                </Link>

                <Link to={"/made-to-order"}>
                  <p className={location.pathname === "/made-to-order" ? "addfooterline" : "removefooterline"}>MADE TO ORDER</p>
                </Link>

                <Link to={"/request-quotation"}>
                  <p className={location.pathname === "/request-quotation" ? "addfooterline" : "removefooterline"}>⁠SUBMIT YOUR RFQ</p>
                </Link>


                {signin ? (
                  <>
                    <Link to={"/order-tracking"}>
                      <p className={location.pathname === "/order-tracking" ? "addfooterline" : "removefooterline"}>⁠TRACK YOUR ORDER</p>
                    </Link>


                  </>
                ) : (
                  <></>

                )}


                <p></p>
                <Link to={"/gift-customization"}>
                  <p className={location.pathname === "/gift-customization" ? "addfooterline" : "removefooterline"}>CUSTOMISE WITH US</p>
                </Link>

                <Link to={"/submitsuggestion"}>
                  <p className={location.pathname === "/submitsuggestion" ? "addfooterline" : "removefooterline"}>⁠SUBMIT YOUR SUGGESTIONS</p>
                </Link>

              </div>
            </div>
            <div className="col-lg-5 col-md-5">
              <div className="social-link mt-3">
                <h2 className="footer-title mb-4">FOLLOW US</h2>
                <div className="social-linksss d-flex">
                  {data?.map((val) =>
                    <div className="social-icon text-center">
                      <a href={val?.link} target="_blank" rel="noopener noreferrer">
                        <img className="x-logo" src={`${IMG_URL}${val?.image}`} alt="Logo" />
                      </a>

                    </div>
                  )}
                  {/* <div className="social-icon text-center">
                    <FontAwesomeIcon icon={faLinkedin} className="fa-logo" />

                  </div> */}
                  {/* <div className="social-icon text-center">
                    <img className="x-logo" src={process.env.PUBLIC_URL + '/assest/image/logo/x-mark.png'} alt="Logo" />

                  </div> */}
                  {/* <div className="social-icon text-center">
                    <FontAwesomeIcon icon={faFacebookF} className="fa-logo" />
                  </div> */}
                  {/* <div className="social-icon text-center">
                   
                    <FontAwesomeIcon icon={faInstagram} className="fa-logo" />
                  </div> */}
                  {/* <div className="social-icon text-center">
                    <FontAwesomeIcon icon={faYoutube} className="fa-logo" />
                  </div> */}

                </div>
              </div>
            </div>
            {/* <div className="col-lg-7 col-md-9">
              <div className="news-letter-box mt-3">
                <h2 className="footer-title mb-4">NEWSLETTER</h2>
                <InputGroup className="mb-3">
                  <Form.Control
                    placeholder="enter email"
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                  />
                  <InputGroup.Text id="basic-addon2">
                    <Common_Button
                      text="  Subscribe"
                      className='subscribe-btn'
                    />
                  </InputGroup.Text>
                </InputGroup>
              </div>
            </div> */}
            <div className="col-md-12 mobcenttt">
              <div className="copyright-holder  text-center">
                <p>
                  Copyright ©2024 All Rights Reserved. Developed by  <Link to="https://profcymaglobal.com/" target="___blank"><span>Profcyma </span>  </Link>{" "}
                </p>
              </div>
            </div>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Footer;
