import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useContext, useEffect } from "react";
import { Context } from "../../../utils/context";
// import { ShimmerPostItem } from "react-shimmer-effects";
import { useNavigate } from 'react-router-dom';
import { AddToCart } from "../../../utils/apis/addToCart/addToCart";
import "./Heart_Component.css"
import { Button, Card } from "react-bootstrap";
import { addWishlist, getWishlist } from "../../../utils/apis/Wishlist/Wishlist";
function Heart_Component({ variant_id, product_id }) {
  // const [likedProducts, setLikedProducts] = useState(false); // State to track liked products

  // const handleLike = () => {
  //   setLikedProducts(!likedProducts);
  // };

  const [heartactive, setheartActive] = useState(false);
  const handleClickes = () => {
    setheartActive(!heartactive);
  };

  const { shimmerLoader, setShimmerLoader, addCartModal, setAddCartModal, wishlistData, wishlistContext, triggerCartUpdate } = useContext(Context);
  const navigate = useNavigate();
  const { signin } = useContext(Context);
  const handleHeartClick = async (data) => {
    setShimmerLoader(true);
    if (signin) {

      const res = await addWishlist(data);
      if (res?.success) {
        wishlistContext();
      }
      setShimmerLoader(false);
    } else {
      setShimmerLoader(false);
      navigate('/login');
    }

  };

  const handleNavigate = async () => {
    const id = localStorage.setItem('product_id', product_id);
    // navigate('/vendor-page');

    navigate('/vendor-page');
    // window.location.href = '/vendor-page';
  };
  const handleCartPop = async () => {
    setAddCartModal(true);
    setTimeout(() => {
      setAddCartModal(false);
    }, 3000);
  }

  useEffect(() => {
    wishlistContext();
  }, [signin]);
  return (
    <>
      {/* <div className="background like-heart">
          <Button
            variant="light"
            className="button-holder"
            type="button"
            onClick={handleLike}
          >
            <FontAwesomeIcon
              icon={faHeart}
              className={likedProducts ? "heart-icon active" : "heart-icon"}
            />
          </Button>
        </div> */}

      <div className="hearticn">
        <FontAwesomeIcon
          icon={faHeart}
          onClick={() => handleHeartClick({ product_id: product_id, variant_id: variant_id })}
          // className={heartactive ? "gobiheartss" : "gobiheart"}
          className={
            wishlistData?.some(item => item.product_id === product_id && item.variant_id === variant_id) ?
            "gobiheartss" :
              "gobiheart"
          }
        />
      </div>
    </>
  )
}

export default Heart_Component