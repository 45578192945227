import React, { useEffect, useRef, useState, useContext } from 'react'
import CustomizableProduct from '../CustomizableProduct/CustomizableProduct'
import {Products} from "../../utils/apis/Product/Product";
import {SubCategory} from "../../utils/apis/master/Master";
import { useParams } from 'react-router-dom';
import parse from "html-react-parser";
import { Context } from "../../utils/context";
const EmplyementSatisfaction = () => {
    const [data, setData] = useState();
    const { id } = useParams();
    const {  IMG_URL } = useContext(Context);
    // const getAllData = async () => {
    //     const res = await Products('','',id);
    //     if (res?.success) {
    //         setData(res?.data);
    //     } else {
    //         console.error('Error fetching  data:', res?.error);
    //     }
    // }

    const getAllsubcategory = async () => {
        const res = await SubCategory(id);
        if (res?.success) {
            setData(res?.data);
        } else {
            console.error('Error fetching  data:', res?.error);
        }
    }
  
    useEffect(() => {
        getAllsubcategory();
        // getAllData();
    }, []);

    return (
        <>
            {/* <CustomizableProduct heading={"Employement Appreciation"} /> */}
            {/* <CustomizableProduct heading={data?.data[0]?.sub_category?.name} /> */}
            <CustomizableProduct id={id}/>
        </>
    )
}

export default EmplyementSatisfaction