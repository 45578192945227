import React, { useEffect, useRef, useState, useContext } from 'react'
import { Button, Col, Row } from 'react-bootstrap';
import "./GiftAutomation.css";
import WeExpress from "../home/WeExpress/WeExpress";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-regular-svg-icons';
import Talktous from '../Common-Component/Offcanvas/Talktous/Talktous';
import { Banners,Gratitude ,allCategory,CustomerLove} from "../../utils/apis/master/Master";

const GiftAutomation = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [dataGratitude, setDataGratitude] = useState();
    const getAllDataGratitude = async () => {
      const res = await Gratitude();
      if (res?.success) {
        setDataGratitude(res?.data);
      } else {
        console.error('Error fetching  data:', res?.error);
      }
    }

    useEffect(() => {   
        getAllDataGratitude();
      }, []);
    return (
        <section className='GiftAutomation my-4'>
            <div className="AboutusMain">
                <div className='container'>
                    <Row>
                        <Col lg={5}>
                            <div className="aboutushead">
                                <h3>Gift Automation</h3>
                                <span><b className='schedull'>Preschedule it</b>, then forget it</span>
                                <div className="productCareinfo">
                                    <p className='caretext'>With our smart scheduling tool, set up the gifts, contacts, messages, and send dates one time — then get back to what matters.</p>
                                </div>
                                <div>
                                    <p className='shedtext'><FontAwesomeIcon icon={faCircleCheck} /> Schedule gifts to go out on calendar-based employee occasions</p>
                                    <p className='shedtext'><FontAwesomeIcon icon={faCircleCheck} /> Set up event-based gifts in advance</p>
                                </div>

                            </div>
                        </Col>
                        <Col lg={7}>
                            <div className="RightImgcare">
                                <img src={process.env.PUBLIC_URL + "/assest/image/Gift_automation/gift-automation.png"} />
                            </div>
                        </Col>
                    </Row>

                </div>
            </div>

            <div className='automation-works'>
                <div className='container'>
                    <div className='workHead'>
                        <h3 ><span className='howtext'>How </span><strong >Gift Automation <br /> Works</strong></h3>
                    </div>
                    <div className='row PathsectionMain'>
                        <Col xl={8} md={8} className='mx-auto'>
                            <div className='pathImg'>
                                <img src={process.env.PUBLIC_URL + "/assest/image/Gift_automation/pathImg1.png"} />
                                <Row>
                                    <Col lg={4} md={4}>
                                        <div className='automationtxt'>
                                            <h3>Select A Preset Campaign </h3>
                                            <p>Choose The Way You Want To Send It.</p>
                                        </div>
                                    </Col>
                                    <Col lg={4} md={4}>
                                        <div className='automationtxt'>
                                            <h3>Enter Your Contacts & The Date</h3>
                                            <p>Enter your contacts and the date you want the gift to go out.</p>
                                        </div>
                                    </Col>
                                    <Col lg={4} md={4}>
                                        <div className='automationtxt'>
                                            <h3>Add A Greeting And Send</h3>
                                            <p>Your brand logo will automatically populate the greeting and sender's name.</p>
                                        </div>
                                    </Col>
                                </Row>
                            </div>

                        </Col>
                    </div>
                </div>
            </div>

            <div className='NeedHelp'>
                <div className='container'>
                    <div className='Needhelpheading'>
                        <h3><strong className='needhelp'>Need Help</strong> With Gifting Programs?</h3>
                        <div className='Talktousbtn'>
                            <Button className="btn" onClick={handleShow}>TALK TO US</Button>
                        </div>
                    </div>
                </div>
            </div>

            {dataGratitude?.length > 0 && <WeExpress data={dataGratitude} />}
            <Talktous show={show} handleClose={handleClose} placement="end" name="end" />
        </section >
    )
}

export default GiftAutomation