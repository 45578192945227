import React from "react";
import { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import Offcanvas from "react-bootstrap/Offcanvas";
import "./HeaderOffcanvas.css";
import { Link } from "react-router-dom";

const HeaderOffcanvas = (props) => {
  const handleLinkClick = () => {
    props.handleClose();
  };
 console.log(props.data,"props?.data");
 
  return (
    <>
      <Offcanvas
        className="mobheadoffcanvas"
        show={props.show}
        onHide={props.handleClose}
        {...props}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
          <Link to="/home" onClick={handleLinkClick}>
            <img
              className="mobloggoo"
              src={
                process.env.PUBLIC_URL + "/assest/image/logo/header-logo.png"
              }
            />  </Link>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <section>
            <div className="Headeraccord">
              <Accordion>
                {props?.data?.slice(0, 6).map((val,index)=>
                <Accordion.Item eventKey={index}>
                  <Accordion.Header>{val?.name}</Accordion.Header>
                  <Accordion.Body>
                    <div className="secondMobiledrop mb-0">
                      <div className="subaccmenu">
                        <Link
                          to={"/shop-promotional"}
                          onClick={handleLinkClick}
                        >
                        
                        </Link>
                      </div>
                      {val?.sub_categories?.map((val1)=>
                      <div className="subaccmenu">
                        <Link
                          to={`/emp-satifaction/${val1?.id}`}
                          onClick={handleLinkClick}
                        >
                          {val1?.name}
                        </Link>
                      </div>)}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                )}
                 <Accordion.Item eventKey="6">
                  <Accordion.Header>Shop By</Accordion.Header>
                  <Accordion.Body>
                    <div className="secondMobiledrop mb-0">
                      <div className="subaccmenu">
                        <Link
                         to="/sustainable-product"
                          onClick={handleLinkClick}
                        >
                          Price
                        </Link>
                      </div>
                      <div className="subaccmenu">
                        <Link
                          to="/sustainable-product"
                          onClick={handleLinkClick}
                        >
                          Interest
                        </Link>
                      </div>
                      <div className="subaccmenu">
                        <Link
                          to="/all-category"
                          onClick={handleLinkClick}
                        >
                          Categories
                        </Link>
                      </div>
                      {/* <div className="subaccmenu">
                        <Link
                          to={"/shop-promotional"}
                          onClick={handleLinkClick}
                        >
                          Best Sellers
                        </Link>
                      </div> */}
                      <div className="subaccmenu">
                        <Link
                             to="/shopbyoccasion"
                          onClick={handleLinkClick}
                        >
                          Occasions / Events
                        </Link>
                      </div>
                    
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                

                <Accordion.Item eventKey="7">
                  <Accordion.Header>
                    <Link  to="/customizable-product" onClick={handleLinkClick}>
                      Customise
                    </Link>
                  </Accordion.Header>
                </Accordion.Item>
                <Accordion.Item eventKey="7">
                  <Accordion.Header>
                    <Link   to="/offers" onClick={handleLinkClick}>
                     Offers
                    </Link>
                  </Accordion.Header>
                </Accordion.Item>

             
              </Accordion>
            </div>
          </section>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default HeaderOffcanvas;
