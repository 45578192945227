import React, { useEffect, useRef, useState, useContext } from "react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Form, Container, Row, Col } from "react-bootstrap";
import "./Talktous.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Link } from "react-router-dom";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SuccessfullyModal from "../../Commonmodal/SuccessfullyModal/SuccessfullyModal";
// import Common_Button from '../../../../Common_Button/Common_Button';
import { TalkToUs } from "../../../../utils/apis/form/form";
import { useParams } from "react-router-dom";
import parse from "html-react-parser";
import { Context } from "../../../../utils/context";
import Cookies from "js-cookie";
import {
  helpUs,
  DeliveryRequaired, EstimatedBuget,
} from "../../../../utils/apis/master/Master";
import { useForm, Controller } from "react-hook-form";
import classNames from "classnames";
import { RegxExpression } from "../../../../utils/common";

const Talktous = (props) => {
  const [value, setValue] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedDeliveryOption, setSelectedDeliveryOption] = useState("");
  const [customise, setcustomise] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [discussion, setDiscussion] = useState("");
  const [consent, setConsent] = useState(false);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.id);
  };

  const handleOptionDeliveryChange = (event) => {
    setSelectedDeliveryOption(event.target.id);
  };

  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];

  const handledataSubmit = () => {
    setcustomise(true);
    props.handleClose();
    setTimeout(() => {
      setcustomise(false);
    }, 4000);
  };
  const {
    signin,
    setSignin,
    shimmerLoader,
    setShimmerLoader,
    Select2Data,
    IMG_URL,
  } = useContext(Context);
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset,
    trigger,
  } = useForm();

  const onSubmit = async (data) => {
    // setShimmerLoader(true);

    const formData = new FormData();
    formData.append("discuss", data?.discuss);
    formData.append("help_us_id", selectedOption);
    if (selectedDeliveryOption) {
      formData.append("delivery_required_id", selectedDeliveryOption);
    }
    if (data?.estimated_buget_id) {
      formData.append("estimated_buget_id", data?.estimated_buget_id?.value);
    }

    formData.append("f_name", data?.f_name);
    formData.append("l_name", data?.l_name);
    formData.append("email", data?.email);
    formData.append("contact_no", data?.contact_no);
   
    formData.append("agree_status", data?.agree_status);

    const res = await TalkToUs(formData);
    if (res?.success) {
      reset();
      // onClick={customiseClose}
      // customiseClose()
      handledataSubmit();
    } else {
    }
  };

  const [helpusdata, setHelpUsdata] = useState();
  const GetAllHelpUs = async () => {
    const response = await helpUs();
    setHelpUsdata(response?.data);
    // if (response?.success) {
    //     setHelpUsdata(await Select2Data(response?.data, "help_us_id"));
    // }
  };

  const [deliverydata, setDeliverydata] = useState();
  const GetAllDelivery = async () => {
    const response = await DeliveryRequaired();
    setDeliverydata(response?.data);
  };

  const [estimatedBugetData, setEstimatedBugetData] = useState();
  const GetAllEstimatedBuget = async () => {
    const response = await EstimatedBuget();
    setEstimatedBugetData(await Select2Data(response?.data, "estimated_buget_id"));
  };


  useEffect(() => {
    GetAllEstimatedBuget();
    GetAllHelpUs();
    GetAllDelivery();
  }, []);
  const handleModalHide = () => {
    reset();
    clearErrors(); // Clear the error message
    props.handleClose(); // Call the parent component's onHide function
};
  return (
    <section className="">
      <Offcanvas
        className="Offcanvastalktous"
        show={props.show}
        onHide={props.handleClose}
        {...props}
      >
        <Offcanvas.Body>
          <div className="talktousbody">
            <Row>
              <Col xl={6} md={6} xs={6}>
                <h2 className="contact-form-title">Talk to Us</h2>
              </Col>
              <Col xl={6} md={6} xs={6} className="me-auto">
                <div className="text-end">
                  <FontAwesomeIcon
                    icon="fa-solid fa-xmark"
                    // onClick={props.handleClose}
                    className="crossicon"
                    onClick={handleModalHide}
                  />
                </div>
              </Col>
            </Row>
            <Form className="talktousform" onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <Col>
                  <Form.Group controlId="formFirstName" className="mb-3">
                    <Form.Control
                      type="text"
                      name="f_name"
                      placeholder="Enter First Name"
                      {...register("f_name", {
                        required: "first name required",
                      })}
                      className={classNames("mb-3", {
                        "is-invalid": errors?.f_name,
                        "is-valid": getValues("f_name"),
                      })}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="formLastName" className="mb-3">
                    <Form.Control
                      type="text"
                      name="l_name"
                      placeholder="Enter Last Name"
                      {...register("l_name", {
                        required: "last name required",
                      })}
                      className={classNames("mb-3", {
                        "is-invalid": errors?.l_name,
                        "is-valid": getValues("l_name"),
                      })}
                    />

                  </Form.Group>
                </Col>
              </Row>
              <Form.Group controlId="formEmail" className="mb-3">
                <Form.Control
                  type="text"
                  name="email"
                  placeholder="Enter Email"
                  {...register("email", {
                    required: "Email is required",
                    pattern: {
                      value: RegxExpression.email,
                      message: "Invalid email address",
                    },
                  })}
                  className={classNames("mb-3", {
                    "is-invalid": errors?.email,
                    "is-valid": getValues("email"),
                  })}
                />
              </Form.Group>
              <Form.Group controlId="formContactNumber">
                <Row>

                  <Controller
                    name="contact_no"
                    control={control}
                    rules={{
                      required: "Field is required",
                      minLength: {
                        value: 10,
                        message: "Number should be at least 10 characters",
                      },
                    }}
                    render={({ field }) => (
                      <PhoneInput
                        country={"in"}
                        {...field}
                        className={classNames("mb-3", {
                          "is-invalid": errors?.contact_no,
                          "is-valid": getValues("contact_no"),
                        })}
                        onChange={(value) => field.onChange(value)}
                        type="text"
                        placeholder="Enter Mobile Number"
                        onKeyDown={(event) => {
                          if (!RegxExpression.phone.test(event.key)) {
                            if (event.key !== "Backspace") {
                              event.preventDefault();
                            }
                          }
                        }}
                        maxLength={10}
                      />
                    )}
                  />
                </Row>

                {errors?.contact_no && (
                  <sup className="text-danger">
                    {errors?.contact_no?.message}
                  </sup>
                )}
              </Form.Group>
              <Form.Group className="radio-options">
                <Form.Label>Help us direct you to the right expert:</Form.Label>
                {helpusdata?.map((val) => (
                  <Form.Check
                    type="radio"
                    label={val?.name}
                    name="expertOption"
                    id={val?.id}
                    onChange={handleOptionChange}
                  />
                ))}

              </Form.Group>

              {selectedOption == 1 && <></>}
              {selectedOption == 2 && (
                <div>
                  <Row>
                    <Col lg={6}>

                      <Controller
                        name="estimated_buget_id" // name of the field
                        {...register("estimated_buget_id", {
                          required: "Select estimated buget",
                        })}
                        control={control}
                        render={({ field }) => (
                          <Select
                            styles={{
                              control: (baseStyles) => ({
                                ...baseStyles,
                                borderColor: errors.estimated_buget_id
                                  ? "red"
                                  : baseStyles,
                              }),
                            }}
                            {...field}
                            options={estimatedBugetData}
                          />
                        )}
                      />
                      {errors.estimated_buget_id && (
                        <span className="text-danger">
                          {errors.estimated_buget_id.message}
                        </span>
                      )}
                    </Col>
                  </Row>

                  <Form.Group className="radio-options">
                    <Form.Label>Delivery required in :</Form.Label>
                    {deliverydata?.map((val) => (
                      <Form.Check
                        type="radio"
                        label={val?.name}
                        name="deliveryOption"
                        id={val?.id}
                        onChange={handleOptionDeliveryChange}
                      />
                    ))}

                  </Form.Group>
                </div>
              )}

              <Form.Group controlId="formDiscussion">

                <Form.Control
                  type="text"
                  as="textarea"
                  rows={2}
                  name="discuss"
                  placeholder="Type here what you would like to discuss (optional)"
                  {...register("discuss", {
                    // required: "discuss required",
                  })}
                  className={classNames("", {
                    "is-invalid": errors?.discuss,
                    "is-valid": getValues("discuss"),
                  })}
                />
              </Form.Group>
              <Form.Group controlId="formConsent" className="consent-checkbox">
                <Controller
                  name="agree_status"
                  {...register("agree_status", {
                    required: "Select agree status",
                  })}
                  control={control}
                  defaultValue={0}
                  render={({ field }) => (
                    <Form.Check
                      type="checkbox"
                      label="I agree to receive communication about our products, services and related content."
                      className={`agree_status ${errors ? "is-invalid" : ""}`}
                      // className="agree_status"
                      onChange={(e) => field.onChange(e.target.checked ? 1 : 0)}

                    />
                  )}
                />
                {errors.agree_status && (
                        <span className="text-danger">
                          {errors.agree_status.message}
                        </span>
                      )}
              </Form.Group>
              <div className="submit-section">
                <Button
                  variant="primary"
                  type="submit"
                  className="submit-button"
                >
                  Submit
                </Button>
                <span className="mx-3">OR</span>
                <Link to="#" className="btn btn-link request-callback">
                  Request a Call Back
                </Link>
              </div>
            </Form>
          </div>
        </Offcanvas.Body>
        <SuccessfullyModal
          show={customise}
          onHide={() => setcustomise(false)}
          text={"Form Submitted Successfully"}
        />
      </Offcanvas>
    </section>
  );
};

export default Talktous;
