import React from 'react';
import Lottie from 'react-lottie';
import * as animationData from './Empty.json';
import './EmptyAnimation.css'
import { Link } from 'react-router-dom';

function EmptyAnimation() {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    return (
        <section className='main'>
            <div className='vdo-btn'>
                <Lottie className="me-auto"
                    height={300}
                    width={250}
                    options={defaultOptions}
                />
                
            </div>
        </section>
    );
}

export default EmptyAnimation;