import React, { useContext,useRef, useEffect, useState } from "react";
import { Button, Form } from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal';
import "./ReviewModal.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Uploader } from 'uploader';
import { UploadButton } from 'react-uploader';
import SuccessfullyModal from '../SuccessfullyModal/SuccessfullyModal';
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import classNames from "classnames";

import {
  allProductReviewGet,
  productReviewGet,
  productReviewPost,
} from "../../../../utils/apis/Product/Product";
import { Context } from "../../../../utils/context";
const ReviewModal = (props) => {

    const uploader = Uploader({ apiKey: 'free' });
    const uploadOptions = { multi: true };

    const [reviewsuccess, setReviewsuccess] = useState(false);

    const modalClose = () => {
        props.onHide();
        setReviewsuccess(true)
    }
    const fileInputRef = useRef(null);
    const [ratings, setRatings] = useState([1, 0, 0, 0, 0]);
  const [selectedFiles, setSelectedFiles] = useState([]);

  const handleStarClick = (starIndex) => {
    const newRatings = [...ratings];
    var rate = 1;
    for (let i = 0; i < newRatings.length; i++) {
      newRatings[i] = i < starIndex ? 1 : 0;
    }
    newRatings?.map((value, index) => {
      if (value == 1) {
        rate = index + 1;
      }
    });

    setRatings(newRatings);
    setValue("ratings", rate);
  };

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    setValue,
    clearErrors,
    formState: { errors },
    reset,
    trigger,
  } = useForm({
    defaultValues: {
      ratings: 1,
    },
  });

  const {signin} = useContext(Context);
  const onSubmit = async (data) => {
  
    const sendData = new FormData();
    sendData.append("ratings", props?.rating);
    sendData.append("order_id", props?.order_id);
    sendData.append("review_message", data?.review_message);
    sendData.append("product_id", props.product_id);
   
  
    if (selectedFiles.length > 0) {
      selectedFiles?.map((image, index) => {
        sendData.append(`images[${index}]`, image);
      });
    }
    const res = await productReviewPost(sendData);
    if (res?.success) {
        modalClose()
        props.reviewGet();
    }
  };

  
  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFiles([...selectedFiles, ...files]);
  };

  const handleimageClick = (event) => {
    event.preventDefault();
    fileInputRef.current.click();
};
  const handleRemoveImage = (index) => {
    const filteredFiles = selectedFiles.filter((_, i) => i !== index);
    setSelectedFiles(filteredFiles);
  };



  const { IMG_URL } = useContext(Context);
  const [reviews, setReviews] = useState([]);


  const [userreview, setUserReviews] = useState(false);

    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='AddreviewModalMain'
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Add Review
                    </Modal.Title>
                    <div className='x-markMain'>
                        <FontAwesomeIcon icon="fa-solid fa-xmark" className='crossIcon' onClick={props.onHide} />
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <section className='AddreviewModal'>
                        <Form  onSubmit={handleSubmit(onSubmit)} >
                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Label>Example textarea</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    name="review_message"
                                    type="text"
                                    rows={3}
                                    placeholder="Enter Message"
                                    {...register("review_message", {
                                        required: "Message required",
                                    })}
                                    className={classNames("", {
                                        "is-invalid": errors?.review_message,
                                    })}
                                />
                            </Form.Group>
                       


                        <div className='uploadreviewMain'>
                            <div className='uploadReviewsec'>

                                {/* <div className='Reviewsec'>
                                    <img src={process.env.PUBLIC_URL + "/assest/image/Careers/reviewImg.png"} className='reviewImg' />
                                </div>
                                <UploadButton
                                    uploader={uploader}
                                    options={uploadOptions}
                                    onComplete={(files) => alert(files.map(x => x.fileUrl).join('\n'))}
                                >
                                    {({ onClick }) => (
                                        <button className='uploadDiv' onClick={onClick}>
                                            <FontAwesomeIcon icon='fa-solid fa-circle-plus' />
                                            <p className='mb-1'>Upload here</p>
                                        </button>
                                    )}
                                </UploadButton> */}

                                <div className='Reviewsec'>                       

                                  

                                    <Form.Label className="plus-btn me-5">
                                        <div className='uploadDiv' onClick={handleimageClick}>
                                            <FontAwesomeIcon icon='fa-solid fa-circle-plus' />
                                            <p className='mb-1'>Upload here</p>
                                        </div>
                                        <Form.Control
                                            ref={fileInputRef}
                                            type="file"
                                            onChange={handleFileChange}
                                            multiple
                                             accept="image/*,video/*"
                                            style={{ display: 'none' }}
                                        />
                                    </Form.Label>

                                    <div className="image-holder">
                                        {selectedFiles.length > 0 &&
                                            selectedFiles.map((file, index) => (
                                                <div className="file-img-holder" key={index}>

                                                    <object
                                                        className="img-logo"
                                                        data={URL.createObjectURL(file)}
                                                        type={file.type}
                                                        width="60"  // Set the width
                                                        height="60" // Set the height
                                                    />
                                                    <div
                                                        className="cross-overlay"
                                                        onClick={() => handleRemoveImage(index)}
                                                    >
                                                        <img
                                                            className="img-cross"
                                                            src={process.env.PUBLIC_URL + "/assest/image/Careers/crossIcon.png"}
                                                            alt={`Cross Icon ${index + 1}`}
                                                        />
                                                    </div>
                                                </div>
                                            ))}
                                    </div>


                                </div>

                            </div>

                            <div className='reviewVideo'>
                                <p><b>Upload your review video </b>(optional)</p>
                                <p>(Upload preferences: 10 Mb max)</p>
                            </div>
                        </div>

                        <div className='btnsecmain'>
                            <Button className="submitbtn" type='submit'>Submit</Button>
                        </div>
                        </Form>
                    </section>
                </Modal.Body>

                <SuccessfullyModal show={reviewsuccess} onHide={() => setReviewsuccess(false)} text={"Review Submitted Successfully"} />

            </Modal>
        </>
    )
}

export default ReviewModal