// import React, { useEffect, useRef,useState, useContext  } from 'react'
// import './Welcomepage.css'
// import { Link } from 'react-router-dom';
// import Common_Button from '../Common-Component/Common_Button/Common_Button';
// import { Context } from "../../utils/context";
// import { getWebsiteType } from "../../utils/apis/master/Master";
// const Welcomepage = () => {
//     const vidRef = useRef();

//     useEffect(() => {
//         if (vidRef.current) {
//             vidRef.current.play().catch(error => {
//                 console.error('Error attempting to play', error);
//             });
//         }
//     }, []);

//     const [data, setData] = useState();
//     const {  IMG_URL } = useContext(Context);
//     const getAllData = async () => {
//         const res = await getWebsiteType();
//         if (res?.success) {
//             setData(res?.data);
//         } else {
//             console.error('Error fetching  data:', res?.error);
//         }
//     }

//     useEffect(() => {
//         getAllData();
//     }, []);
//     return (
//         <>
//             <section className='welcome-page'>

//                 <div className="video-main">
//                     <div className='videodesktop'>
//                     <video className="video-bg" src={process.env.PUBLIC_URL + "/assest/videos/welcome/welcomevdo.mp4"} ref={vidRef} muted loop autoPlay />
//                         {/* <video className="video-bg" src={process.env.PUBLIC_URL + "/assest/video/welcome-video.mp4"} ref={vidRef} muted loop /> */}
//                     </div>

//                     <div className='videoMobile'>
//                         <video className="video-bg" src={process.env.PUBLIC_URL + "/assest/videos/welcome/mobwelcomevdo.mp4"} ref={vidRef} autoPlay muted loop />
//                     </div>

//                     <div className='black-overlay'> </div>
//                     <div className='main-row-secccc'>
//                         <div className='row me-0 ms-0 main-row'>
//                         {data?.map((val, index)=>
//                             <div className='col-md-6 col-sm-12 px-0'>
//                                 <div className='heading-text text-center'>
//                                     <h2>{val?.name}</h2>
//                                     <div className='button-holder'>
//                                     {val?.id === 2 ? (
//                                 <Link to="/home">
//                                     <Common_Button
//                                         text="EXPLORE"
//                                         className='explore-btn'
//                                     />
//                                 </Link>
//                             ) : (
//                                  <Common_Button
//                                 text="Incoming Website"
//                                 className='explore-btn'
//                             />
//                             )}
//                                     </div>
//                                     <div className='text-holder mt-5'>
//                                         {/* <p>Handcrafted Luxury<br /> You Care About</p> */}
//                                         <p>{val?.sub_name}</p>
//                                     </div>
//                                     <div className='overlay-text-color1'></div>
//                                 </div>

//                             </div>
//                               )}
//                             {/* <div className='col-md-6 col-sm-12 px-0'>
//                                 <div className='heading-text1 text-center'>
//                                     <h2>CORPORATE GIFTS</h2>
//                                     <div className='button-holder'>
//                                         <Link to="/home">  <Common_Button
//                                             text="EXPLORE"
//                                             className='explore-btn'
//                                         /></Link>
//                                     </div>
//                                     <div className='text-holder'>
//                                         <p>Global All-In-One<br /> Gifting Solution</p>
//                                     </div>
//                                     <div className='overlay-text-color2'></div>
//                                 </div>
//                             </div> */}
//                         </div>
//                     </div>
//                 </div>

//                 <div className='overflow-content'>
//                     <div className='container'>
//                         <div className="logo">
//                             <Link to="/home">
//                                 <img className='headlogo' src={process.env.PUBLIC_URL + '/assest/image/logo/header-logo.png'} alt="Logo" />
//                             </Link>
//                         </div>
//                     </div>
//                 </div>

//             </section>
//         </>
//     )
// }

// export default Welcomepage

import React, { useEffect, useRef, useState, useContext } from "react";
import "./Welcomepage.css";
import { Link } from "react-router-dom";
import Common_Button from "../Common-Component/Common_Button/Common_Button";
import { Context } from "../../utils/context";
import { getWebsiteType } from "../../utils/apis/master/Master";
import Popup_holder from "../Common-Component/Commonmodal/Popup_holder/Popup_holder";

const Welcomepage = () => {
  const vidRef = useRef();
  const [data, setData] = useState();
  const { IMG_URL } = useContext(Context);
  const [showPopup, setShowPopup] = useState(false); // State to control popup visibility

  useEffect(() => {
    if (vidRef.current) {
      vidRef.current.play().catch((error) => {
        console.error("Error attempting to play", error);
      });
    }
  }, []);

  const getAllData = async () => {
    const res = await getWebsiteType();
    if (res?.success) {
      setData(res?.data);
    } else {
      console.error("Error fetching data:", res?.error);
    }
  };

  useEffect(() => {
    getAllData();
  }, []);

  const handleIncomingClick = () => {
    setShowPopup(true);
    setTimeout(() => {
      setShowPopup(false);
    }, 3000); // Close popup after 3 seconds
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  return (
    <>
      <section className="welcome-page">
        <div className="video-main">
          <div className="main-row-secccc">
            <div className="row me-0 ms-0 main-row">
              {data?.map((val, index) => (
                <div
                  className="col-md-6 col-sm-6 col-12 px-0 partation-holder"
                  key={index}
                >
                  <>
                    {val?.id === 2 ? (
                      <>

                      <div className="videodesktop">
                        <video
                          className="video-bg"
                        //   src={
                        //     process.env.PUBLIC_URL +
                        //     "/assest/videos/welcome/luxury-dekstop.mp4"
                        //   }
                          src={
                            IMG_URL +
                            val?.video
                          }
                          ref={vidRef}
                          muted
                          loop
                          autoPlay
                        />
                      </div>

                      <div className="overlay-holder"></div>
                      </>
                    ) : (
                      <>

                      <div className="videodesktop">
                        <video
                          className="video-bg"
                        //   src={
                        //     process.env.PUBLIC_URL +
                        //     "/assest/videos/welcome/corporate-dekstop.mp4"
                        //   }
                        src={
                            IMG_URL +
                            val?.video
                          }
                          ref={vidRef}
                          muted
                          loop
                          autoPlay
                        />
                      </div>
                      <div className="overlay-holder"></div>
                      </>
                    )}
                    <div className="content-holder">
                      <div className="heading-text text-center">
                        <h2>{val?.name}</h2>
                        <div className="button-holder">
                          {val?.id === 2 ? (
                            <Link to="/home">
                              <Common_Button
                                text="EXPLORE"
                                className="explore-btn"
                              />
                            </Link>
                          ) : (
                            <Common_Button
                              text="EXPLORE"
                              className="explore-btn"
                              onClick={handleIncomingClick} // Add click handler
                            />
                          )}
                        </div>
                        <div className="text-holder mt-5">
                          <p>{val?.sub_name}</p>
                        </div>
                        <div className="overlay-text-color1"></div>
                      </div>
                    </div>
                  </>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="overflow-content">
          <div className="container">
            <div className="logo">
              <Link to="/home">
                <img
                  className="headlogo"
                  src={
                    process.env.PUBLIC_URL +
                    "/assest/image/logo/header-logo.png"
                  }
                  alt="Logo"
                />
              </Link>
            </div>
          </div>
        </div>
      </section>

      {showPopup && (
        <Popup_holder text="Launching Soon" onClose={closePopup} /> // Render the popup if visible
      )}

      
    </>
  );
};

export default Welcomepage;
