import React, { useEffect, useRef, useState, useContext } from 'react'
import "./Blogs.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row } from 'react-bootstrap';
import Pagination from 'react-bootstrap/Pagination';
import { Link } from 'react-router-dom';
import Breadcrumbs from '../Common-Component/Breadcrumbs/Breadcrumbs';
import { Context } from "../../utils/context";
import { blog } from "../../utils/apis/Blog/Blog";
import parse from "html-react-parser";
import { OffersBlogdata } from "../../utils/apis/master/Master";
import Stack from '@mui/material/Stack';
import Paginationss from '@mui/material/Pagination';
const articlesData = [
    {
        id: 1,
        imgSrc: process.env.PUBLIC_URL + "/assest/image/home/articlesImg2.png",
        title: "Luxury Articles to Gift",
        description: "Indulge in the art of gifting with our exquisite selection of luxury articles, meticulously curated to elevate any occasion.",
        date: "Jun 02, 2024"
    },
    {
        id: 2,
        imgSrc: process.env.PUBLIC_URL + "/assest/image/Blogs/blogImg1.png",
        title: "Luxury Articles to Gift",
        description: "Indulge in the art of gifting with our exquisite selection of luxury articles, meticulously curated to elevate any occasion.",
        date: "Jun 02, 2024"
    },
    {
        id: 2,
        imgSrc: process.env.PUBLIC_URL + "/assest/image/Blogs/blogImg2.png",
        title: "Luxury Articles to Gift",
        description: "Indulge in the art of gifting with our exquisite selection of luxury articles, meticulously curated to elevate any occasion.",
        date: "Jun 02, 2024"
    },
    {
        id: 2,
        imgSrc: process.env.PUBLIC_URL + "/assest/image/Blogs/blogImg1.png",
        title: "Luxury Articles to Gift",
        description: "Indulge in the art of gifting with our exquisite selection of luxury articles, meticulously curated to elevate any occasion.",
        date: "Jun 02, 2024"
    },

];



const Blogs = () => {
    const { IMG_URL } = useContext(Context);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [perPage, setPerPage] = useState(12);
    const [totalEntries, setTotalEntries] = useState(0);

    const [activePage, setActivePage] = useState(1);
    const [data, setBlog] = useState([]);
    const getBlog = async () => {
        const res = await blog(activePage);
        if (res?.success) {
            setCurrentPage(res?.data?.currentPage);
            setTotalPages(res?.data?.totalPages);
            setPerPage(res?.data?.per_page);
            setTotalEntries(res?.data?.totalEntries);
            setBlog(res?.data)
        }
    }

    const [offers, setOffers] = useState();

    const getAllData = async () => {
        const res = await OffersBlogdata();
        if (res?.success) {
            setOffers(res?.data);
        } else {
            console.error('Error fetching  data:', res?.error);
        }
    }


    useEffect(() => {
        getAllData();
        getBlog();
    }, [activePage])

    const items = [
        { id: 1, text: "HOME", link: "/home" },
        { id: 1, text: "BLOG" },
    ]
    // const handlePageChange = (event, page) => {
    //     getBlog();
    //     setCurrentPage(page);
    // };

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
    };

    const paginationItems = [];
    for (let page = 1; page <= totalPages; page++) {
        paginationItems.push(
            <Pagination.Item
                key={page}
                active={page === activePage}
                onClick={() => handlePageChange(page)}
            >
                {page}
            </Pagination.Item>
        );
    }
    return (
        <>
            <section className='Blogs'>
                <div className='container'>
                    <Breadcrumbs items={items} />
                    <div className='row'>
                        <div className='col-lg-9 col-md-7'>
                            {/* <Link to={"/blog-details"}> */}
                            <div className='row'>
                                {data?.data?.slice(0, 4).map((article) => (

                                    <div className='col-lg-6' key={article.id}>
                                        <Link to={`/blog-details/${article?.id}`}>
                                            <div className='articlesImg1'>

                                                <img src={IMG_URL + article?.image} alt={article.name} />


                                                <div className='overlay-color'></div>
                                                <div className='imageinfo1'>
                                                    <h3>{article?.name}</h3>
                                                    <p>{parse(article?.description)}</p>
                                                    <Row className='readmoresecmain'>
                                                        <Col xl={6} lg={6} md={6} xs={5}>
                                                            <p>Read More <FontAwesomeIcon icon="fa-solid fa-angle-right" /></p>

                                                        </Col>
                                                        <Col xl={6} lg={6} md={6} xs={7}>
                                                            <p className='posteddate'>Posted date:{article?.createdAt ? new Date(article?.createdAt).toLocaleDateString('en-US', {
                                                                year: 'numeric',
                                                                month: 'short',
                                                                day: '2-digit'
                                                            }) : ''}</p>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>


                                ))}
                            </div>
                            {/* </Link> */}

                            <div className='blogBanner'>
                                {offers && offers[0]?.image ? (
                                    <img src={`${IMG_URL}${offers[0].image}`} className='blogbanimg' alt='Offer Image' />
                                ) : (
                                    <div>No image available</div> // Optional: A placeholder or message when there's no image
                                )}
                                <div className='bannertxt'>
                                    {/* <p>Surprise Your Team with</p>
                                    <h3>Best Corporate Gifts</h3>
                                    <p>with Our Exciting Offers</p> */}
                                </div>
                            </div>


                            <Row>

                                {data?.data?.slice(4, 8).map((article) => (



                                    <div className='col-lg-6' key={article.id}>
                                        <Link to={`/blog-details/${article?.id}`}>
                                            <div className='articlesImg1'>

                                                <img src={IMG_URL + article?.image} alt={article.name} />


                                                <div className='overlay-color'></div>
                                                <div className='imageinfo1'>
                                                    <h3>{article?.name}</h3>
                                                    <p>{parse(article?.description)}</p>
                                                    <Row className='readmoresecmain'>
                                                        <Col xl={6} lg={6} md={6} xs={5}>
                                                            <p>Read More <FontAwesomeIcon icon="fa-solid fa-angle-right" /></p>

                                                        </Col>
                                                        <Col xl={6} lg={6} md={6} xs={7}>
                                                            <p className='posteddate'>{article?.createdAt ? new Date(article?.createdAt).toLocaleDateString('en-US', {
                                                                year: 'numeric',
                                                                month: 'short',
                                                                day: '2-digit'
                                                            }) : ''}</p>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>


                                ))}

                                {/* <div className='col-lg-6'>
                                    <div className='articlesImg1'>
                                        <img src={process.env.PUBLIC_URL + "/assest/image/home/articlesImg2.png"} />
                                        <div className='overlay-color'></div>
                                        <div className='imageinfo1'>
                                            <h3>Luxury Articles to Gift</h3>
                                            <p>Indulge in the art of gifting with our exquisite selection of luxury articles,<br /> meticulously curated to elevate any occasion.</p>
                                            <p>Read More <FontAwesomeIcon icon="fa-solid fa-angle-right" /></p>
                                        </div>

                                    </div>
                                </div> */}
                                {/* <div className='col-lg-6'>
                                    <div className='articlesImg1'>
                                        <img src={process.env.PUBLIC_URL + "/assest/image/home/articlesImg2.png"} />
                                        <div className='overlay-color'></div>
                                        <div className='imageinfo1'>
                                            <h3>Luxury Articles to Gift</h3>
                                            <p>Indulge in the art of gifting with our exquisite selection of luxury articles,<br /> meticulously curated to elevate any occasion.</p>
                                            <p>Read More <FontAwesomeIcon icon="fa-solid fa-angle-right" /></p>
                                        </div>

                                    </div>
                                </div> */}

                            </Row>

                        </div>
                        <div className='col-lg-3 col-md-5'>
                            <div className='recentUpload'>
                                <div className='recentuploadHead'>
                                    <h3>Recent Uploads</h3>
                                </div>
                                <div className='recentBlog'>
                                    {data?.recent_post?.map(article => (
                                        <Link to={`/blog-details/${article?.id}`}>
                                            <div className='articles2' key={article.id}>
                                                <img src={IMG_URL + article?.image} alt={article?.name} />
                                                <div className='overlay-color'></div>
                                                <div className='imageinfo3'>
                                                    <h3>{article?.name}</h3>
                                                    <p>{parse(article?.description)}</p>
                                                    <p className='recentedPostedDate'>Posted date: <b>{article?.createdAt ? new Date(article?.createdAt).toLocaleDateString('en-US', {
                                                        year: 'numeric',
                                                        month: 'short',
                                                        day: '2-digit'
                                                    }) : ''}</b></p>
                                                </div>
                                            </div>
                                        </Link>
                                    ))}
                                </div>


                            </div>
                            <div className='blogBanner_holder'>

                                {offers?.map((val) =>

                                    <div className='blogBanner'>
                                        <img src={IMG_URL + val?.image} className='blogrecentbanimg' />
                                    </div>)}
                            </div>

                            {/* <div className='blogBanner'>
                                <img src={process.env.PUBLIC_URL + "/assest/image/Offer/OfferImg2.png"} className='blogrecentbanimg' />
                            </div> */}
                        </div>
                    </div>


                    {/* <Pagination>
                    <Stack spacing={2}>
                        <Paginationss
                            count={totalPages}
                            page={currentPage}
                            onChange={handlePageChange}
                        />
                    </Stack>
                    </Pagination> */}

                    {/* <Pagination>
                        <Pagination.First />
                        <Pagination.Prev />
                        <Pagination.Item>{1}</Pagination.Item>
                        <Pagination.Item>{2}</Pagination.Item>
                        <Pagination.Item>{3}</Pagination.Item>
                        <Pagination.Ellipsis />

                        <Pagination.Item active>{7}</Pagination.Item>
                        <Pagination.Next />
                        <Pagination.Last />
                    </Pagination> */}

                    {/* <Pagination>
                        <Pagination.First onClick={() => handlePageChange(1)} />
                        <Pagination.Prev onClick={() => handlePageChange(Math.max(activePage - 1, 1))} />
                        {paginationItems}
                        <Pagination.Next onClick={() => handlePageChange(Math.min(activePage + 1, totalPages))} />
                        <Pagination.Last onClick={() => handlePageChange(totalPages)} />
                    </Pagination> */}

                    <Pagination>
                        <Pagination.First
                            onClick={() => handlePageChange(1)}
                            disabled={activePage === 1}
                        />
                        <Pagination.Prev className="custom-pagination-prev"
                            onClick={() => handlePageChange(Math.max(activePage - 1, 1))}
                            disabled={activePage === 1}
                        />
                        {paginationItems}
                        <Pagination.Next className="custom-pagination-next"
                            onClick={() => handlePageChange(Math.min(activePage + 1, totalPages))}
                            disabled={activePage === totalPages}
                        />
                        <Pagination.Last
                            onClick={() => handlePageChange(totalPages)}
                            disabled={activePage === totalPages}
                        />
                    </Pagination>
                    Explanation:
                </div>
            </section>
        </>
    )
}

export default Blogs;
