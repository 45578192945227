import React, { useEffect, useRef, useState, useContext } from 'react'
import "swiper/swiper-bundle.css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import { Row } from "react-bootstrap";
import ProductCard from '../Common-Component/ProductCard/ProductCard';
// import "./RecentlyViewed.css";
import { Products } from "../../utils/apis/Product/Product";
import Cookies from "js-cookie";
const RecentlyViewed = () => {

    const [swiperInstance, setSwiperInstance] = useState(null);
    const [swiperInstancenew, setSwiperInstancenew] = useState(null);
    const orders = [
        {
            id: 1,
            image: process.env.PUBLIC_URL + "/assest/image/Profile/productImg.png",
            image2: process.env.PUBLIC_URL + "/assest/image/Profile/productImg1.png",
            name: 'Nike Dri-FIT Legend Tee - Men\'s',
            price: '0000'
        },
        {
            id: 2,
            image: process.env.PUBLIC_URL + "/assest/image/Profile/productImg.png",
            image2: process.env.PUBLIC_URL + "/assest/image/Profile/productImg1.png",
            name: 'White Coffee Mug',
            price: '0000'
        },
        {
            id: 3,
            image: process.env.PUBLIC_URL + "/assest/image/Profile/productImg.png",
            image2: process.env.PUBLIC_URL + "/assest/image/Profile/productImg1.png",
            name: 'Lather Cover Executive Diary and 2 more items',
            price: '0000'
        },
        {
            id: 4,
            image: process.env.PUBLIC_URL + "/assest/image/Profile/productImg.png",
            image2: process.env.PUBLIC_URL + "/assest/image/Profile/productImg1.png",
            name: 'Lather Cover Executive Diary and 2 more items',
            price: '0000'
        }

    ];
    const [productIds, setProductIds] = useState([]);
    const [Ids, setIds] = useState([]);
    const product_id =Cookies.get("recently_viewed")
    const [productdata, setproductData] = useState();
    const getAllData = async () => {
      
        setIds(productIds?.join(','));
         const  res = await Products('','','','','','','','','','',Ids);
        
        if (res?.success) {
            setproductData(res?.data?.data);
        } else {
            console.error('Error fetching  data:', res?.error);
        }
    }
  
    useEffect(() => {
        getAllData();
    }, [productIds]); 
    useEffect(() => {
        const recentlyViewed = Cookies.get("recently_viewed");
    
        if (recentlyViewed) {
          try {
            const parsedRecentlyViewed = JSON.parse(recentlyViewed);
            
            // Ensure it's an array
            if (Array.isArray(parsedRecentlyViewed)) {
              const ids = parsedRecentlyViewed.map(item => item.product_id);
              setProductIds(ids);
            } else {
              console.error("Parsed recently viewed is not an array:", parsedRecentlyViewed);
            }
          } catch (error) {
            console.error("Error parsing recently viewed:", error);
          }
        }
      }, []);

    return (
        <>
            <section className='BestMatchedMain'>
                <div className='BestMatched'>
                    <div className='bestMatchedhead'>
                        <h3>Recently Viewed by You</h3>
                    </div>
                    <Row>
                        <Swiper
                            slidesPerView={"auto"}
                            modules={[Autoplay, Pagination]}
                            onSwiper={(swiper) => setSwiperInstance(swiper)}
                            breakpoints={{
                                0: {
                                    slidesPerView: 2,
                                    spaceBetween: 10,
                                },
                                370: {
                                    slidesPerView: 2,
                                    spaceBetween: 10,
                                },
                                485: {
                                    slidesPerView: 2,
                                    spaceBetween: 10,
                                },
                                575: {
                                    slidesPerView: 2,
                                    spaceBetween: 30,
                                },

                                768: {
                                    slidesPerView: 2,
                                    spaceBetween: 20,
                                },
                                992: {
                                    slidesPerView: 3,
                                    spaceBetween: 30,
                                },
                                1024: {
                                    slidesPerView: 2,
                                    spaceBetween: 30,
                                },
                                1200: {
                                    slidesPerView: 3,
                                    spaceBetween: 30,
                                },
                                1440: {
                                    slidesPerView: 3,
                                    spaceBetween: 30,
                                },
                                2000: {
                                    slidesPerView: 3,
                                    spaceBetween: 20,
                                },
                            }}
                            navigation
                            pagination={{ clickable: true }}
                            className="mySwiper1"
                        >


                            {productdata?.map(order => (
                                <SwiperSlide>
                                    <ProductCard order={order} />

                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </Row>
                </div>

            </section>
        </>
    )
}

export default RecentlyViewed