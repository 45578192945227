import React, { useEffect, useRef, useState, useContext } from 'react'
import "./Faq.css";
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Accordion from 'react-bootstrap/Accordion';
import Tab from 'react-bootstrap/Tab';
import { Context } from "../../utils/context";
import { FaqData, FaqCategoryData, FaqFAqData } from "../../utils/apis/Blog/Blog";
import parse from "html-react-parser";
const Faq = () => {
    const [dataCategory, setCategoryData] = useState();
    const [dataCategoryID, setCategoryDataId] = useState();
    const [data, setData] = useState();
    const [datafaq, setfaqData] = useState();
    const { IMG_URL } = useContext(Context);
    const getAllcategoryData = async () => {
        const res = await FaqCategoryData();
        if (res?.success) {
            setCategoryData(res?.data);
        } else {
            console.error('Error fetching  data:', res?.error);
        }
    }
    const getAllData = async (id) => {
        setCategoryDataId(id);
        const res = await FaqData(id);
        if (res?.success) {
            setData(res?.data);
        } else {
            console.error('Error fetching  data:', res?.error);
        }
    }

    const getAllFAQData = async () => {
        const res = await FaqFAqData();
        if (res?.success) {
            setfaqData(res?.data);
        } else {
            console.error('Error fetching  data:', res?.error);
        }
    }

    useEffect(() => {
        getAllFAQData();
        getAllcategoryData();
    }, []);


    return (
        <>
            <section className='Faq'>
                <div className='container'>
                    <div className='FaqBannermain'>
                        <div className='bannerImg'>
                            <img src={process.env.PUBLIC_URL + "/assest/image/Faq/faqBannerImg.png"} />
                            <div className='onbannertxt'>
                                <p>Get Answers </p>
                                <p>to your questions</p>
                            </div>
                        </div>
                    </div>


                    <div className='row faqTabs'>

                        <Tab.Container id="left-tabs-example" defaultActiveKey="0">

                            <Nav variant="pills" className="faqtabsNav">
                                <Nav.Item>
                                    <Nav.Link eventKey="0" onClick={() => getAllFAQData()}>All</Nav.Link>
                                </Nav.Item>
                                {dataCategory?.map((val, index) =>
                                    <Nav.Item>
                                        <Nav.Link eventKey={val?.id} onClick={() => getAllData(val?.id)}>{val?.name}</Nav.Link>
                                    </Nav.Item>
                                )}
                            </Nav>

                            <Tab.Content>
                                <Tab.Pane eventKey="0" >
                                    <div className='card accordionCard'>
                                        <div className='card-body'>
                                            <Accordion defaultActiveKey="0">
                                                {datafaq?.length > 0 ? (
                                                    datafaq.map((val, index) => (
                                                        <Accordion.Item eventKey={index.toString()} key={index}>
                                                            <Accordion.Header>{val?.name}</Accordion.Header>
                                                            <Accordion.Body>
                                                                <div className='faqAccordhead'>
                                                                    <p>{typeof val?.description === 'string' ? parse(val?.description) : ""}</p>
                                                                </div>
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                    ))
                                                ) : (
                                                    <></> // <p style={{ textAlign: 'center', fontWeight: 'bold' }}>Data not available</p>
                                                )}
                                            </Accordion>
                                        </div>
                                    </div>
                                </Tab.Pane>

                                {data?.length > 0 ? (
                                 
                                        <Tab.Pane eventKey={dataCategoryID} >
                                            <div className='card accordionCard'>
                                                <div className='card-body'>
                                                    <Accordion defaultActiveKey="0">
                                                        {data?.map((val, index) => (
                                                            <Accordion.Item eventKey={index.toString()} key={index}>
                                                                <Accordion.Header>{val?.name}</Accordion.Header>
                                                                <Accordion.Body>
                                                                    <div className='faqAccordhead'>
                                                                        <p>{typeof val?.description === 'string' ? parse(val?.description) : ""}</p>
                                                                    </div>
                                                                </Accordion.Body>
                                                            </Accordion.Item>
                                                         ))} 
                                                    </Accordion>
                                                </div>
                                            </div>
                                        </Tab.Pane>
                                  
                                ) : (
                                    <p style={{ textAlign: 'center', fontWeight: 'bold' }}>Data not available</p>
                                )}

                                

                                
                                {/* <Tab.Pane eventKey="1">
                                    <div className='card accordionCard'>
                                        <div className='card-body'>
                                            <Accordion>
                                                {datafaq?.map((val, index) =>
                                                    <Accordion.Item eventKey="0">
                                                        <Accordion.Header>{val?.name}</Accordion.Header>
                                                        <Accordion.Body>
                                                            <div className='faqAccordhead'>
                                                                <p>{typeof val?.description === 'string' ? parse(val?.description) : ""}</p>
                                                            </div>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                )}
                                                {data?.map((val, index) =>
                                                    <Accordion.Item eventKey={val?.id}>
                                                        <Accordion.Header>{val?.name}</Accordion.Header>
                                                        <Accordion.Body>
                                                            <div className='faqAccordhead'>
                                                                <p>{typeof val?.description === 'string' ? parse(val?.description) : ""}</p>
                                                            </div>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                )}
                                                
                                            </Accordion>
                                        </div>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="2">
                                    <div className='card accordionCard'>
                                        <div className='card-body'>
                                            <Accordion>
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header>Can I add company branding to my gifts?</Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className='faqAccordhead'>
                                                            <p>Absolutely! We welcome all customers who wish to shop at Mittalcraftworks.com. If you’re looking for a single gift, we recommend browsing our No Minimum Quantity.</p>
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="1">
                                                    <Accordion.Header>How do I add my custom design through your portal?</Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className='faqAccordhead'>
                                                            <p>Absolutely! We welcome all customers who wish to shop at Mittalcraftworks.com. If you’re looking for a single gift, we recommend browsing our No Minimum Quantity.</p>
                                                        </div>

                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="2">
                                                    <Accordion.Header>What printing methods are available?</Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className='faqAccordhead'>
                                                            <p>Absolutely! We welcome all customers who wish to shop at Mittalcraftworks.com. If you’re looking for a single gift, we recommend browsing our No Minimum Quantity.</p>
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="3">
                                                    <Accordion.Header>Which products are available for customization?</Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className='faqAccordhead'>
                                                            <p>Absolutely! We welcome all customers who wish to shop at Mittalcraftworks.com. If you’re looking for a single gift, we recommend browsing our No Minimum Quantity.</p>
                                                        </div>

                                                    </Accordion.Body>
                                                </Accordion.Item>

                                                <Accordion.Item eventKey="4">
                                                    <Accordion.Header>What are set up fees?</Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className='faqAccordhead'>
                                                            <p>Absolutely! We welcome all customers who wish to shop at Mittalcraftworks.com. If you’re looking for a single gift, we recommend browsing our No Minimum Quantity.</p>
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        </div>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="3">Second tab content</Tab.Pane>
                                <Tab.Pane eventKey="4">Second tab content</Tab.Pane>
                                <Tab.Pane eventKey="5">Second tab content</Tab.Pane>
                                <Tab.Pane eventKey="6">Second tab content</Tab.Pane>
                                <Tab.Pane eventKey="7">Second tab content</Tab.Pane> */}
                            </Tab.Content>

                        </Tab.Container>


                    </div>
                </div>
            </section>
        </>
    )
}

export default Faq