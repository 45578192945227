import React, { useEffect, useRef, useState, useContext } from 'react'
import "./RelevantNewsInner.css";
import Breadcrumbs from '../../Common-Component/Breadcrumbs/Breadcrumbs';
import { Card, Col, Row } from 'react-bootstrap';
import "swiper/swiper-bundle.css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import { Link } from 'react-router-dom';
import { LatestNews, LatestNewsDetails } from "../../../utils/apis/LatestNews/LatestNews";
import { useParams } from 'react-router-dom';
import parse from "html-react-parser";
import { Context } from "../../../utils/context";
const RelevantNewsInner = () => {


    const orders = [
        {
            id: 1,
            image: process.env.PUBLIC_URL + "/assest/image/Blogs/blogImg1.png",
            name: 'Nike Dri-FIT Legend Tee - Men\'s',
            price: '0000'
        },
        {
            id: 2,
            image: process.env.PUBLIC_URL + "/assest/image/Blogs/blogImg1.png",
            name: 'White Coffee Mug',
            price: '0000'
        },
        {
            id: 3,
            image: process.env.PUBLIC_URL + "/assest/image/Blogs/blogImg1.png",
            name: 'Lather Cover Executive Diary and 2 more items',
            price: '0000'
        },
        {
            id: 4,
            image: process.env.PUBLIC_URL + "/assest/image/Blogs/blogImg1.png",
            name: 'Lather Cover Executive Diary and 2 more items',
            price: '0000'
        },

        {
            id: 4,
            image: process.env.PUBLIC_URL + "/assest/image/Blogs/blogImg1.png",
            name: 'Lather Cover Executive Diary and 2 more items',
            price: '0000'
        }

    ];
    const [data, setData] = useState();
    const { id } = useParams();
    const { IMG_URL, formatDate } = useContext(Context);
    const getAllData = async () => {
        const res = await LatestNewsDetails(id);
        if (res?.success) {
            setData(res?.data);
        } else {
            console.error('Error fetching  data:', res?.error);
        }
    }
    const [Relevantdata, setRelevantData] = useState();

    const getAllRelevantData = async () => {
        const res = await LatestNews();
        if (res?.success) {
            setRelevantData(res?.data);
        } else {
            console.error('Error fetching  data:', res?.error);
        }
    }

    useEffect(() => {
        getAllData();
        getAllRelevantData();

    }, [id]);

    const items = [
        { id: 1, text: "HOME", link: "/home" },
        { id: 2, text: "NEWS", link: "/relevantNews" },
        { id: 3, text: data?.name || "LATEST UPDATES" },
    ]
    return (
        <>
            <section className='RelevantInner'>
                <div className='container'>
                    <Breadcrumbs items={items} />
                    <div className='articleshead'>
                        <h3>
                            <span>Latest Updates</span>
                            <span className='nevermiss'>You Never Want to Miss</span>
                        </h3>

                    </div>


                    <div className='Relevantinnerbanner'>
                        <Row>
                            <div className='col-lg-7 col-12 mx-auto'>
                                <div className='articlmainn'>
                                    {/* <Link to={"/relevantInner"}> */}
                                    <div className='articlesImg1'>
                                        <img src={IMG_URL + data?.image} />
                                        <div className='overlay-color'></div>
                                        <div className='imageinfo1'>
                                            <h3>{data?.name}</h3>
                                            <p> {typeof data?.description === 'string' ? parse(data?.description) : ""}</p>
                                            <div className='minute-text-holder'>
                                                <p>By <span style={{ color: "#234C9E" }}>{data?.by_name}</span> </p>
                                                <p>{new Date(data?.createdAt).toLocaleTimeString([], { minute: '2-digit' })} minute ago</p>
                                            </div>
                                        </div>

                                    </div>
                                    {/* </Link> */}
                                </div>

                            </div>
                        </Row>

                        <div className='infoMain'>

                            {typeof data?.long_description === 'string' ? parse(data?.long_description) : ""}
                            {/* <div className='RelevantInninfo'>
                                <p>When it comes to gifting, few things rival the timeless elegance and sophistication of luxury home décor items. Whether you're celebrating a special occasion, expressing gratitude, or simply adding a touch of opulence to your loved one's living space, luxury home décor gifts are sure to leave a lasting impression. Here are some exquisite ideas to inspire your next gift-giving gesture:</p>
                            </div>

                            <div className='innerHead'>
                                <h3>1. Elegant Pottery</h3>
                                <p>Elegant Pottery: Gift a piece of artistry with handcrafted pottery that exudes elegance and craftsmanship. Choose from delicate porcelain tea sets, artisanal ceramic bowls, or intricately designed serving platters to add a touch of sophistication to any dining table. Each piece tells a story of tradition, skill, and creativity, making it a cherished addition to any home. </p>
                            </div>

                            <div className='innerHead'>
                                <h3>2.Fine Crackery Sets</h3>
                                <p>Elevate any floral arrangement with a statement flower vase crafted from the finest materials. Opt for crystal vases adorned with intricate patterns or hand-painted porcelain vases for a touch of Old-World charm. A luxurious flower vase not only enhances the beauty of fresh blooms but also serves as a timeless keepsake. </p>
                            </div>

                            <div className='innerHead'>
                                <h3>3. Decorative Figurines</h3>
                                <p>Elevate everyday dining experiences with luxurious crockery sets that combine style and functionality. Treat your loved ones to fine bone china dinnerware embellished with exquisite patterns or sleek contemporary designs crafted from premium materials like porcelain or stoneware. From elegant dinner plates to delicate tea sets, luxury crockery sets promise to make every meal a special occasion. </p>
                            </div>

                            <div className='innerHead'>
                                <h3>4. Statment Wall Art</h3>
                                <p>Add a touch of whimsy and charm to any living space with decorative figurines that serve as exquisite works of art. Choose from intricately sculpted bronze statues, hand-blown glass figurines, or delicately carved marble sculptures to adorn shelves, mantels, or display cabinets. These luxurious accents not only elevate the aesthetic appeal of any room but also serve as timeless conve- -rsation pieces.  </p>
                            </div>

                            <div className='innerHead'>
                                <h3>5. Stunning Flower Vases</h3>
                                <p>Transform any room into a gallery-worthy space with statement wall art that captures the imagination and inspires awe. Choose from breathtaking oil paintings, mesmerizing tapestries, or contemporary metal sculptures to create a focal point that reflects your loved one's personality and style. Whether it's a vibrant abstract canvas or a serene landscape masterpiece, luxury wall art adds depth, dimension, and character to any interior décor scheme. </p>
                            </div>

                            <div className='innerHead'>
                                <h3>Conclusion</h3>
                                <p>From exquisite flower vases to elegant crockery sets, luxury home décor gifts offer a perfect blend of beauty, functionality, and sophistication. Whether you're gifting for a special occasion or simply expressing appreciation, these exquisite items are sure to make a lasting impression and bring joy to any recipient's home.

                                </p>
                            </div> */}

                        </div>

                    </div>


                    <div className='RelevantNewsupdates'>
                        <div className='relevantHead'>
                            <h3>Relevant News Updates</h3>
                        </div>
                        <Swiper
                            slidesPerView={"auto"}
                            modules={[Autoplay, Pagination]}
                            breakpoints={{
                                0: {
                                    slidesPerView: 2,
                                    spaceBetween: 10,
                                },
                                370: {
                                    slidesPerView: 2,
                                    spaceBetween: 10,
                                },
                                485: {
                                    slidesPerView: 2,
                                    spaceBetween: 10,
                                },
                                575: {
                                    slidesPerView: 2,
                                    spaceBetween: 30,
                                },

                                768: {
                                    slidesPerView: 2,
                                    spaceBetween: 20,
                                },
                                992: {
                                    slidesPerView: 5,
                                    spaceBetween: 30,
                                },
                                1024: {
                                    slidesPerView: 4,
                                    spaceBetween: 30,
                                },
                                1200: {
                                    slidesPerView: 4,
                                    spaceBetween: 30,
                                },
                                1440: {
                                    slidesPerView: 4,
                                    spaceBetween: 30,
                                },
                                2000: {
                                    slidesPerView: 3,
                                    spaceBetween: 20,
                                },
                            }}

                            pagination={{ clickable: true }}
                            className="mySwiper1"
                        >

                            {Relevantdata?.recent_post?.map(order => (
                                <SwiperSlide>
                                    <Link to={`/relevantInner/${order?.id}`}>
                                        <Card className="order-card">
                                            <div className='productImg'>
                                                <Card.Img variant="top" src={IMG_URL + order.image} />
                                            </div>
                                            <div className='cardBodymain'>
                                                <p>{formatDate(order?.updatedAt)}</p>
                                                <h3>{order?.name}</h3>
                                                <p>By <span style={{ color: "#234C9E" }}>{order?.by_name}</span>
                                                    {/* and <span style={{ color: "#234C9E" }}>William</span>  */}
                                                </p>
                                            </div>
                                        </Card>
                                    </Link>

                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </div>
            </section>
        </>
    )
}

export default RelevantNewsInner