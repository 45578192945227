import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';

const useCookie = (cookieName) => {
    const [cookie, setCookie] = useState(() => Cookies.get(cookieName));

    useEffect(() => {
        const interval = setInterval(() => {
            const newCookie = Cookies.get(cookieName);
            if (newCookie !== cookie) {
                setCookie(newCookie);
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [cookieName, cookie]);

    return cookie;
};

export default useCookie;
