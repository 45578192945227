import React, { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import Offcanvas from "react-bootstrap/Offcanvas";
import "./PromotionalFilter.css";
import { Form } from "react-bootstrap";
import InputRange from "react-input-range";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faPlus } from "@fortawesome/free-solid-svg-icons";

const PromotionalFilter = (props) => {
  const [value, setValue] = useState(10);
  const [activeKeys, setActiveKeys] = useState(["1"]);

  const handleSelect = (key) => {
    if (activeKeys.includes(key)) {
      setActiveKeys(activeKeys.filter((activeKey) => activeKey !== key));
    } else {
      setActiveKeys([...activeKeys, key]);
    }
  };

  const [isActive, setIsActive] = useState(false);

  const handleClick = () => {
    setIsActive(!isActive); // Toggle the active state
  };
  return (
    <>
      <Offcanvas show={props.show} onHide={props.handleClose} {...props}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <section className="Filteroffcanvas">
            <div className="Headeraccord">
              <div className="sidebar">
                <Form>
                  <Form.Group controlId="customizable">
                    <Form.Check type="checkbox" label="Customizable (514)" />
                  </Form.Group>
                  <Form.Group controlId="freeShipping">
                    <Form.Check type="checkbox" label="Free Shipping" />
                  </Form.Group>

                  <div className="tabsbsb">
                    <Tab.Container
                      id="left-tabs-example"
                      activeKey={activeKeys}
                      onSelect={handleSelect}
                    >
                      <Nav variant="pills" className="flex-row">
                        <Nav.Item>
                          <Nav.Link
                            eventKey="1"
                            active={activeKeys.includes("1")}
                            onClick={() => handleSelect("1")}
                          >
                            Silver{" "}
                            <span>
                              <FontAwesomeIcon
                                icon={
                                  activeKeys.includes("1") ? faPlus : faXmark
                                }
                                className="x-mark"
                              />
                            </span>
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey="2"
                            active={activeKeys.includes("2")}
                            onClick={() => handleSelect("2")}
                          >
                            Gold{" "}
                            <span>
                              <FontAwesomeIcon
                                icon={
                                  activeKeys.includes("2") ? faPlus : faXmark
                                }
                                className="x-mark"
                              />
                            </span>
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey="3"
                            active={activeKeys.includes("3")}
                            onClick={() => handleSelect("3")}
                          >
                            Bronze{" "}
                            <span>
                              <FontAwesomeIcon
                                icon={
                                  activeKeys.includes("3") ? faPlus : faXmark
                                }
                                className="x-mark"
                              />
                            </span>
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </Tab.Container>
                  </div>

                  <div className="productsBycat">
                    <h3 className="productsBycatHead">Products by Category</h3>

                    <div className="categoryItemssec">
                      <div className="categoryItem">
                        <p>Bags</p>
                        <p className="lessthanicon">
                          <FontAwesomeIcon icon="fa-solid fa-angle-right" />
                        </p>
                      </div>

                      <div className="categoryItem">
                        <p>T-shirts</p>
                        <p className="lessthanicon">
                          <FontAwesomeIcon icon="fa-solid fa-angle-right" />
                        </p>
                      </div>
                      <div className="categoryItem">
                        <p>Copperware</p>
                        <p className="lessthanicon">
                          <FontAwesomeIcon icon="fa-solid fa-angle-right" />
                        </p>
                      </div>

                      <div className="categoryItem">
                        <p>Frames</p>
                        <p className="lessthanicon">
                          <FontAwesomeIcon icon="fa-solid fa-angle-right" />
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="price-range">
                    <h3 className="productsBycatHead">Price Range</h3>
                    <InputRange
                      maxValue={20}
                      minValue={0}
                      value={value}
                      onChange={(value) => setValue(value)}
                    />
                  </div>

                  <Form.Group controlId="quantity">
                    <h3 className="productsBycatHead">Quantity</h3>
                    <div className="quantityform">
                      <Form.Control
                        type="number"
                        placeholder="Enter Quantity"
                      />
                      <div className="onfieldtxt">
                        <Form.Check type="checkbox" label="No min qty" />
                      </div>
                    </div>
                  </Form.Group>
                  <Accordion>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>Brand</Accordion.Header>
                      <Accordion.Body>
                        <div className="accordBodycontent">
                          <p
                            className={isActive ? "active" : ""}
                            onClick={handleClick}
                          >
                            Gift Wrap
                          </p>
                          <p
                            className={isActive ? "active" : ""}
                            onClick={handleClick}
                          >
                            Customisable
                          </p>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="3">
                      <Accordion.Header>Colour</Accordion.Header>
                      <Accordion.Body>
                        <div className="accordBodycontent">
                          <p
                            className={isActive ? "active" : ""}
                            onClick={handleClick}
                          >
                            Gift Wrap
                          </p>
                          <p
                            className={isActive ? "active" : ""}
                            onClick={handleClick}
                          >
                            Customisable
                          </p>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="4">
                      <Accordion.Header>Occasion</Accordion.Header>
                      <Accordion.Body>
                        <div className="accordBodycontent">
                          <p
                            className={isActive ? "active" : ""}
                            onClick={handleClick}
                          >
                            Gift Wrap
                          </p>
                          <p
                            className={isActive ? "active" : ""}
                            onClick={handleClick}
                          >
                            Customisable
                          </p>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="5">
                      <Accordion.Header>Material</Accordion.Header>
                      <Accordion.Body>
                        <div className="accordBodycontent">
                          <p
                            className={isActive ? "active" : ""}
                            onClick={handleClick}
                          >
                            Gift Wrap
                          </p>
                          <p
                            className={isActive ? "active" : ""}
                            onClick={handleClick}
                          >
                            Customisable
                          </p>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="6">
                      <Accordion.Header>Categories</Accordion.Header>
                      <Accordion.Body>
                        <div className="accordBodycontent">
                          <p
                            className={isActive ? "active" : ""}
                            onClick={handleClick}
                          >
                            Gift Wrap
                          </p>
                          <p
                            className={isActive ? "active" : ""}
                            onClick={handleClick}
                          >
                            Customisable
                          </p>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="7">
                      <Accordion.Header>Deals & Discount</Accordion.Header>
                      <Accordion.Body>
                        <div className="accordBodycontent">
                          <p
                            className={isActive ? "active" : ""}
                            onClick={handleClick}
                          >
                            Gift Wrap
                          </p>
                          <p
                            className={isActive ? "active" : ""}
                            onClick={handleClick}
                          >
                            Customisable
                          </p>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="8">
                      <Accordion.Header>Ordering Options</Accordion.Header>
                      <Accordion.Body>
                        <div className="accordBodycontent">
                          <p
                            className={isActive ? "active" : ""}
                            onClick={handleClick}
                          >
                            Gift Wrap
                          </p>
                          <p
                            className={isActive ? "active" : ""}
                            onClick={handleClick}
                          >
                            Customisable
                          </p>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Form>
              </div>
            </div>
          </section>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default PromotionalFilter;
