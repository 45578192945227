import React, { useEffect, useRef, useState, useContext } from 'react'
import "./PrivacyPolicy.css";
import { Context } from "../../utils/context";
import {PrivacyPolicydata} from "../../utils/apis/master/Master";
import { useParams } from 'react-router-dom';
import parse from "html-react-parser";
const PrivacyPolicy = () => {
    const [data, setData] = useState();
    const { id } = useParams();
    const { IMG_URL } = useContext(Context);
    const getAllData = async () => {
        const res = await PrivacyPolicydata();
        if (res?.success) {
            setData(res?.data);
        } else {
            console.error('Error fetching  data:', res?.error);
        }
    }

    useEffect(() => {
        getAllData();
      
    }, []);
    return (
        <>
            <section className='Privacypolicy pb-5'>
                <div className='container'>
                    <div className='PrivacyHead mb-5'>
                        <h3>{data?.name}</h3>
                        {typeof data?.description === 'string' ? parse(data?.description) : ""}
                        </div>


                </div>
            </section>
        </>
    )
}

export default PrivacyPolicy