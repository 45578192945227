import React, { useState } from 'react'
import Accordion from 'react-bootstrap/Accordion';
import Offcanvas from 'react-bootstrap/Offcanvas';
import "./OrdersFilter.css";
import { NavDropdown, Button } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faPlus } from '@fortawesome/free-solid-svg-icons';

const OrdersFilter = (props) => {
    const [value, setValue] = useState(10);
    const [activeKeys, setActiveKeys] = useState(['1']);

    const handleSelect = (key) => {
        if (activeKeys.includes(key)) {
            setActiveKeys(activeKeys.filter(activeKey => activeKey !== key));
        } else {
            setActiveKeys([...activeKeys, key]);
        }
    };

    const filterOptions = [
        { eventKey: '1', label: 'Shipped' },
        { eventKey: '2', label: 'Delivered' },
        { eventKey: '3', label: 'Cancelled' },
        { eventKey: '4', label: 'Returned' },
    ];

    const timeOptions = [
        { eventKey: '11', label: 'Last 30 Days' },
        { eventKey: '12', label: 'Last 6 Mths.' },
        { eventKey: '13', label: '2023' },
        { eventKey: '14', label: '2022' },
        { eventKey: '15', label: '2020' },
        { eventKey: '16', label: 'Older' },
    ];
    return (
        <>
            <Offcanvas show={props.show} onHide={props.handleClose} {...props}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title></Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <section className='Filteroffcanvas'>
                        <div className='sortingsec'>
                            <div className='filterDropsec'>
                                <div className='Filterdropdownmain'>
                                    <p className='clearfiltertxt'>Clear Filter</p>
                                    <div className='orderstatus'>
                                        <p><b>ORDER STATUS</b></p>
                                    </div>
                                    <Tab.Container id="left-tabs-example" activeKey={activeKeys} onSelect={handleSelect}>
                                        <Nav variant="pills" className="flex-row filterDrop">
                                            {filterOptions.map(option => (
                                                <Nav.Item key={option.eventKey}>
                                                    <Nav.Link eventKey={option.eventKey} active={activeKeys.includes(option.eventKey)}>
                                                        {option.label} <span><FontAwesomeIcon icon={activeKeys.includes(option.eventKey) ? faPlus : faXmark} className='x-mark' /></span>
                                                    </Nav.Link>
                                                </Nav.Item>
                                            ))}
                                        </Nav>
                                    </Tab.Container>
                                </div>
                                <div className='Filterdropdownmain'>
                                    <div className='orderstatus'>
                                        <p><b>ORDER TIME</b></p>
                                    </div>
                                    <Tab.Container id="left-tabs-example" activeKey={activeKeys} onSelect={handleSelect}>
                                        <Nav variant="pills" className="flex-row filterDrop">
                                            {timeOptions.map(option => (
                                                <Nav.Item key={option.eventKey}>
                                                    <Nav.Link eventKey={option.eventKey} active={activeKeys.includes(option.eventKey)}>
                                                        {option.label} <span><FontAwesomeIcon icon={activeKeys.includes(option.eventKey) ? faPlus : faXmark} className='x-mark' /></span>
                                                    </Nav.Link>
                                                </Nav.Item>
                                            ))}
                                        </Nav>
                                        <div className='btnMainsec'>
                                            <Button className='cancelbtn' onClick={props.handleClose}>Cancel</Button>
                                            <Button className='savebtn'>Save</Button>
                                        </div>
                                    </Tab.Container>
                                </div>
                            </div>

                        </div>

                    </section>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}

export default OrdersFilter