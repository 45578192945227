import React, { useState } from 'react';
import './PageNotfound.css';
import { Button, Col, Row } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ResumeModal from '../Common-Component/Commonmodal/ResumeModal/ResumeModal';
import { useNavigate } from 'react-router-dom';

const jobListings = [
    {
        title: '1. Jr. H.R. Executive',
        description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry...',
        details: {
            pay: '₹ 2,00,000 - ₹ 7,00,000 per annum',
            shift: 'Day-Shift',
            location: 'Bavdhan, Pune',
            experience: '1 - 3 years',
            language: 'English, Hindi, Marathi',
            jobType: 'Full Time',
            vacancy: '1',
            skills: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
            deadline: '20/10/2023',
            qualification: 'Graduated, Certification in Ui Ux design',
        },
        contact: {
            name: 'Deepti Deshmukh (HR Manager)',
            company: 'Mittal Craftworks Pvt. Ltd.',
            phone: '+91 9876543210',
            email: 'employer@mittalcraftworks.com',
        },
        responsibilities: [
            'Lorem Ipsum is simply dummy text of the printing and typesetting industry',
            'Lorem Ipsum is simply dummy text of the printing and typesetting industry',
            'Lorem Ipsum is simply dummy text of the printing and typesetting industry',
            'Lorem Ipsum is simply dummy text of the printing and typesetting industry',
            'Lorem Ipsum is simply dummy text of the printing and typesetting industry',
        ],
    },
    {
        title: '2. Sr. Marketing Executive',
        description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry...',
        details: {
            pay: '₹ 2,00,000 - ₹ 7,00,000 per annum',
            shift: 'Day-Shift',
            location: 'Bavdhan, Pune',
            experience: '1 - 3 years',
            language: 'English, Hindi, Marathi',
            jobType: 'Full Time',
            vacancy: '1',
            skills: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
            deadline: '20/10/2023',
            qualification: 'Graduated, Certification in Ui Ux design',
        },
        contact: {
            name: 'Deepti Deshmukh (HR Manager)',
            company: 'Mittal Craftworks Pvt. Ltd.',
            phone: '+91 9876543210',
            email: 'employer@mittalcraftworks.com',
        },
        responsibilities: [
            'Lorem Ipsum is simply dummy text of the printing and typesetting industry',
            'Lorem Ipsum is simply dummy text of the printing and typesetting industry',
            'Lorem Ipsum is simply dummy text of the printing and typesetting industry',
            'Lorem Ipsum is simply dummy text of the printing and typesetting industry',
            'Lorem Ipsum is simply dummy text of the printing and typesetting industry',
        ],
    },
];


const PageNotfound = () => {
    const navigate = useNavigate();

    const [resumemodal, setResumemodal] = useState(false);
    return (
        <section className='Careers'>
            <div className='container'>
                <div className='careerHead'>
                    <h3>Careers @ MITTAL</h3>
                </div>

                <Row>
                    <Col xl={12} >
                        <div className='weRaesrymain'>
                            <div className='wearesrytxt'>
                                <p>We are Sorry,</p>
                                <p>for moment we don't have job openings.</p>
                                <div className='pathBelow'>
                                    <img src={process.env.PUBLIC_URL + "/assest/image/Careers/pathBelow.png"} />
                                </div>
                            </div>

                        </div>

                    </Col>
                </Row>
                <div className='noVacancyImg'>
                    <img src={process.env.PUBLIC_URL + "/assest/image/Careers/no-vacancy.png"} />
                    <p>*ONLY FOR UI DESING <b style={{ cursor: "pointer" }} onClick={() => navigate("/careers")}>DEMO: CLICK HERE</b> TO SEE JOB AVAILABILITY</p>
                </div>

                <ResumeModal show={resumemodal} onHide={() => setResumemodal(false)} />
            </div>
        </section>
    );
};

export default PageNotfound;
