import React, { useState, useEffect, useContext } from "react";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fas } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import Form from 'react-bootstrap/Form';
import AddNewaddress from '../../Common-Component/Commonmodal/AddNewaddress/AddNewaddress';
import EditNewaddress from '../../Common-Component/Commonmodal/AddNewaddress/EditNewaddress';
import { postAddress, getAddress, deleteAddress, getSingleAddress } from "../../../utils/apis/orderProcess/orderProcess";
import { Context } from "../../../utils/context";
import Delete_Modal from "../../Common-Component/Commonmodal/Delete_Modal/Delete_Modal";
library.add(fas);

const Billing_information = ({ handleNext, ShippingId,setAddressSelect,address_id,setAddress_id, addressSelect ,setIsShippingSameAsBilling,isShippingSameAsBilling}) => {
    const [newAddress, setNewaddress] = useState(false);
    const [modalDeleteShow, setDeleteModalShow] = useState(false);
    const [newaddAddress, setAddNewaddress] = useState(false);
    const address = [
        { id: 1, hometype: "Home", image: process.env.PUBLIC_URL + "/assest/image/Profile/map.png" },
        { id: 2, hometype: "Work", image: process.env.PUBLIC_URL + "/assest/image/Profile/map.png" },
    ];
    const [Type, setType] = useState([]);
    const [data, setData] = useState();
    const [deleteID, setDeleteID] = useState();
    const [error, setError] = useState(false);
    const handleNextStep = (d) => {
        if(data.length === 0){
            setError("Address required");
            return;
        }
        setAddressSelect(d);
        handleNext();

    };

    const handleAddressSave = (data) => {
        setAddressSelect(data);
        setNewaddress(false);
        handleNext();
    };

  
    const { IMG_URL } = useContext(Context);
    const getAllData = async () => {
        const res = await getAddress();
        if (res?.success) {
            setData(res?.data);
        } else {
            console.error('Error fetching  data:', res?.error);
        }
    }

    useEffect(() => {
        getAllData();
    }, []);

    const initialId = data && data.length > 0 ? data[0].id : undefined;
    const [selectedradioaddress, setSelectedRadioAddress] = useState(null);
    const handleRadioChange = (id) => {
        setSelectedRadioAddress(id);
        setAddress_id(id);
    };

    const handleDelete = async (id) => {
        setDeleteModalShow(true)
        setDeleteID(id)
    }

    const [editAddress, seteditAddress] = useState(null);
    const handleEditClick = (id) => {
        setNewaddress(true);
        seteditAddress(id)

    };

   
    const handleCheckboxChange = (e) => {
      setIsShippingSameAsBilling(e.target.checked ? 1 : 0);
      setAddressSelect(selectedradioaddress)
      setAddress_id(selectedradioaddress)
    };

   
   
    useEffect(() => {
        if(address_id){
            setSelectedRadioAddress(address_id);
        }
        else if(data?.length > 0) {
            setAddress_id(data[0]?.id)
            setSelectedRadioAddress(data[0]?.id);
        }
    }, [data,address_id]);

   
    
    return (
        <>
            <section className='stepp-inner-main'>
                <div className='address-div'>
                    <div className='title-div'>
                        <h6 className='choss-add'>Choose Address</h6>
                    </div>
                    <div className='row mt-5'>
                        <Form.Check
                            type="checkbox"
                            label="Is shipping address same as billing address"
                            className='stepOnecheckbox mb-3'
                            checked={isShippingSameAsBilling === 1}
                            onChange={handleCheckboxChange}
                        />
                         {error && <div className="error-message" style={{ color: 'red' }}>{error}</div>}
                        {data?.map((value, index) => (
                            <div className='col-lg-4 col-md-6 mb-3' key={index}>
                                <div className='addd-card'>
                                    <div className='row'>
                                        <div className='col-10'>
                                            <div className='card-flex'>
                                                <div className='loc-img-div'>
                                                    <img className='map-img' src={process.env.PUBLIC_URL + "/assest/image/Profile/map.png"} alt="map" />
                                                </div>
                                                <div className='cont-div'>
                                                    <h6 className='hom'>{value?.address_type}</h6>
                                                    <p className='name'><b>{value?.f_name} {value?.l_name}</b></p>
                                                    <p className='adddd'>{value?.company}, {value?.address}, {value?.city?.name}, {value?.state?.name}, {value?.country?.name} - {value?.pincode?.name}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-2 text-end'>
                                            <Form.Check
                                                type="radio"
                                                label=""
                                                name="address"
                                                checked={selectedradioaddress === value?.id}
                                                onChange={() => handleRadioChange(value?.id)}
                                            />
                                            <p className='edit mb-0' onClick={() => handleEditClick(value?.id)} style={{ cursor: "pointer" }}>Edit</p>
                                        </div>
                                        <div className='dlttt-div'>
                                            <FontAwesomeIcon icon="fa-solid fa-trash-can" onClick={() => handleDelete(value?.id)} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                        <div className='col-lg-4 mb-3'>
                            <div className='addd-card-new-add'>
                                <button type='button' className='addnewaddbtn' onClick={() => setAddNewaddress(true)}>Add New Address</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="save-btn-div mt-5">
                    <button type='button' className="continue-btn" onClick={handleNextStep}>
                        Proceed
                    </button>
                </div>
            </section>
            <AddNewaddress Type={Type} setType={setType} ShippingId={ShippingId} setAddress_id={setAddress_id} getAllData={getAllData} show={newaddAddress} onHide={() => setAddNewaddress(false)} onSave={handleAddressSave} />
            <EditNewaddress show={newAddress} id={editAddress} getAllData={getAllData} onHide={() => setNewaddress(false)} onSave={handleAddressSave} />
        
            <Delete_Modal text={"Are you sure you want to delete address?"} getAllData={getAllData} deleteID={deleteID} show={modalDeleteShow} onHide={() => setDeleteModalShow(false)} />
        </>
    );
};

export default Billing_information;
