import React, { useEffect, useRef, useState, useContext } from 'react'
import "./AboutUs.css";
import { Col, Row } from "react-bootstrap";
import CountUp from "react-countup";
import WeExpress from "../home/WeExpress/WeExpress";
import Customerlove from "../home/Customerlove/Customerlove";
import OurLatestreward from "./OurLatestreward/OurLatestreward";
import ValuesSection from "./ValueSection/ValueSection";
import { Context } from "../../utils/context";
import { whoWeAre, Counter } from "../../utils/apis/AboutUs/AboutUs";
import { Banners,Gratitude ,allCategory,CustomerLove} from "../../utils/apis/master/Master";
import { value,OurlatestAward } from "../../utils/apis/AboutUs/AboutUs";
import parse from "html-react-parser";
const AboutUs = () => {
  const [datacount, setDataCount] = useState();

  const cardsData = [
    {
      key: 1,
      count: datacount?.happy_customer,
      // suffix: " K",
      title: "Happy Customer",
      description: "buy, manage, and distribute HR gifts",
      classname: "aboutuscard"
    },
    {
      key: 2,
      count: datacount?.hour_saved,
      // suffix: " M",
      title: "Hour Saved",
      description: "buy, manage, and distribute HR gifts",
      classname: "middleaboutuscard"
    },
    {
      key: 3,
      count: datacount?.grateful_recipients,
      // suffix: " M",
      title: "Grateful Recipients",
      description: "buy, manage, and distribute HR gifts",
      classname: "aboutuscard"
    },
  ];


  const [data, setData] = useState();
  const { IMG_URL } = useContext(Context);
  const getAllData = async () => {
    const res = await whoWeAre();
    if (res?.success) {
      setData(res?.data);
    } else {
      console.error('Error fetching  data:', res?.error);
    }
  }

  const [counter, setCounter] = useState();
  const getAllCounter = async () => {
    const res = await counter();
    if (res?.success) {
      setCounter(res?.data);
    } else {
      console.error('Error fetching  data:', res?.error);
    }
  }

  useEffect(() => {
    getAllData();
    getAllCounter();
  }, []);



  const getAllDataCount = async () => {
    const res = await Counter();
    if (res?.success) {
      setDataCount(res?.data);
    } else {
      console.error('Error fetching  data:', res?.error);
    }
  }

  const [dataCustomerLove, setDataCustomerLove] = useState();
  const getAllDataCustomerLove = async () => {
    const res = await CustomerLove();
    if (res?.success) {
      setDataCustomerLove(res?.data);
    } else {
      console.error('Error fetching  data:', res?.error);
    }
  }
  const [dataGratitude, setDataGratitude] = useState();
  const getAllDataGratitude = async () => {
    const res = await Gratitude();
    if (res?.success) {
      setDataGratitude(res?.data);
    } else {
      console.error('Error fetching  data:', res?.error);
    }
  }

  const [datavalue, setDatavalue] = useState();
  const getAllvalueData = async () => {
    const res = await value();
    if (res?.success) {
      setDatavalue(res?.data);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };

  const [dataOurlatestAward, setDataOurlatestAward] = useState();
  const getAllDataOurlatestAward = async () => {
    const res = await OurlatestAward();
    if (res?.success) {
        setDataOurlatestAward(res?.data);
    } else {
        console.error('Error fetching  data:', res?.error);
    }
}
  useEffect(() => {
    getAllDataOurlatestAward();
    getAllvalueData();
    getAllDataGratitude();
    getAllDataCustomerLove();
    getAllDataCount();
  }, []);
  return (
    <section className="AboutUs">
      <div className="AboutusMain">
        <div className="container">
          <div className="aboutushead">
            <h3>About Us</h3>
            <p>
              <span>We’ve made it </span>
              <span className="">
                <strong className="spanfont">easy to appreciate </strong>employees
              </span>
            </p>
          </div>
          <Row>
            {cardsData.map((card) => (
              <Col lg={4} md={6} sm={6} className="mx-auto" key={card.key}>
                <div className={`aboutuscard ${card.classname}`}>
                  <div className="cardContent text-center">
                    <h3>
                      {/* <CountUp
                        start={0}
                        end={card.count}
                        suffix={card.suffix}
                        duration={3.5}
                      /> */}
                      {card?.count}
                    </h3>
                    <h4>{card.title}</h4>
                    <p>{card.description}</p>
                  </div>
                </div>
              </Col>
            ))}
          </Row>

          <div className="paraInfo">
            <p>
              It took the vision of an innovator & the knowledge of experts to
              build a virtual market where people could explore, discover & buy
              various kinds of genuine coins, with guaranteed peace of-mind, in
              their own luxurious confines.
            </p>
          </div>
        </div>
      </div>

      {data &&
      <div className="container">
        <div className="whoweAre">
          <div className="whoweareHEad">
            <div className="row whoweareInfo">
              <Col lg={6} md={5}>
                <h3>{data?.name}</h3>
                <p>{typeof data?.description === 'string' ? parse(data?.description) : ""}</p></Col>
              <Col lg={6} md={7}>
                <Row>
                  <Col xl={10}>
                    <div className="rightsecImage">

                      <img src={IMG_URL + data?.logo} />


                      <div className="overlayImg"></div>
                    </div>

                  </Col>
                </Row>
              </Col>

            </div>
          </div>
        </div>
      </div>}

      {/* <div className="container">
        <div className="valueStandby">
          <div className="valueStandbyHead">
            <h3>The values we stand by</h3>
          </div>

          <div className="row">
            <div className="col-lg-3">
              <div className="card valuestandCard">
                <div className="cardImgsec">
                  <img src={process.env.PUBLIC_URL + "/assest/image/Aboutus/valueIcon1.png"} />
                </div>
                <div className="cardHead">
                  <h3>Be Grateful</h3>
                  <p>We deeply appreciate our customers and focus on fostering trust and dependability.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="card valuestandCard">
                <div className="cardImgsec">
                  <img src={process.env.PUBLIC_URL + "/assest/image/Aboutus/valueIcon2.png"} />
                </div>
                <div className="cardHead">
                  <h3>Be Transparent</h3>
                  <p>We deeply appreciate our customers and focus on fostering trust and dependability.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="card valuestandCard">
                <div className="cardImgsec">
                  <img src={process.env.PUBLIC_URL + "/assest/image/Aboutus/valueIcon3.png"} />
                </div>
                <div className="cardHead">
                  <h3>Reduce Waste</h3>
                  <p>Our goal is to ensure your recipients are content, your workload is minimized, and your budget is optimized.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="card valuestandCard">
                <div className="cardImgsec">
                  <img src={process.env.PUBLIC_URL + "/assest/image/Aboutus/valueIcon4.png"} />
                </div>
                <div className="cardHead">
                  <h3>Listen Radically</h3>
                  <p>We deeply appreciate our customers and focus on fostering trust and dependability.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

     
      {datavalue?.length > 0 && <ValuesSection data={datavalue} />}
      {dataGratitude?.length > 0 && <WeExpress data={dataGratitude} />}
      {dataOurlatestAward?.length > 0 && <OurLatestreward data={dataOurlatestAward} />}

      {dataCustomerLove?.length > 0 && <Customerlove data={dataCustomerLove} />}
    </section>
  );
};

export default AboutUs;
