import React, { useState, useEffect, useContext } from "react";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import Common_Button from "../../../Common-Component/Common_Button/Common_Button";
import { Col, Row, Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';
import Cookies from "js-cookie";
import { useForm, Controller } from "react-hook-form";
import classNames from "classnames";
import { RegxExpression } from "../../../../utils/common";
import { loginCart } from "../../../../utils/apis/addToCart/addToCart";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { Context } from "../../../../utils/context";
import { useNavigate } from "react-router-dom";
import { personalDetails, getpersonalDetails } from "../../../../utils/apis/sellerForm/Sellerform";
import PasswordChecklist from "react-password-checklist";
import Cookies_popup_modal from "../../../Common-Component/Commonmodal/Cookies_popup_modal/Cookies_popup_modal";
library.add(fas);



const PersonalInformation = ({ handleNext, handlePrevious, setAddressSeAddNewaddresslect, addressSelect }) => {
    const [isSaved, setIsSaved] = useState(false);
    const navigate = useNavigate();
    // const [showPassword, setShowPassword] = useState(false);
    const [showPassword1, setShowPassword1] = useState(false);
    const [password, setPassword] = useState("");
    const [passwordAgain, setPasswordAgain] = useState("");
  
    // const togglePasswordVisibility = () => {
    //     setShowPassword(!showPassword);
    // };

    const togglePasswordVisibility1 = () => {
        setShowPassword1(!showPassword1);
    };
    const { signin, setSignin, shimmerLoader, setShimmerLoader, isCookieAccepted, setIsCookieAccepted } = useContext(Context);

    const {
        control,
        register,
        handleSubmit,
        getValues,
        setError,
        clearErrors,
        formState: { errors },
        reset,
        trigger,
    } = useForm();

    const [showCookiesModal, setShowCookiesModal] = useState(true);
    const [sellerId, setSellerId] = useState("");
    const onSubmit = async (data) => {
        if (!isCookieAccepted) {
            setShowCookiesModal(true);
            return;
        }
        
        setShimmerLoader(true);
        if (password === passwordAgain) {
            const formData = new FormData();
            if (data?.id) {
                formData.append("personal_detail_id", data.id);
            }
            formData.append("first_name", data?.first_name);
            formData.append("last_name", data?.last_name);
            formData.append("email", data?.email);
            formData.append("password", data?.password);
            const res = await personalDetails(formData);
            if (res?.success) {
                await Cookies.set("seller_id", res.data);

                handleNext()

            } else {
                if (res?.message?.both) {
                    setError("email", { message: res?.message?.both });
                }
                if (res?.message?.email) {
                    setError("email", { message: res?.message?.email });
                }
            }
        } else {
            setError("re_password", { message: "Password Does not match" });
        }

    };

    const [showPassword, setShowPassword] = useState(false);
    const [showConformPassword, setShowConformPassword] = useState(false);
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);

    };
    const toggleConformPasswordVisibility = () => {

        setShowConformPassword(!showConformPassword)
    };

   

    const getEditData = async () => {
        const res = await getpersonalDetails(sellerId);
        if (res?.success) {
            reset(res?.data)
        }
    }
    useEffect(() => {
        getEditData();
    }, [sellerId]);
    useEffect(() => {
        setSellerId(Cookies.get('seller_id'));
    }, []);

    const handleAcceptCookies = () => {
        setShowCookiesModal(false);
    };
    return (
        <>
            <section className='stepp-inner-main'>

                <Row>
                    <Col lg={6} className='mx-auto'>
                        <Form >
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>First Name*</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="First_Name"
                                    placeholder="Enter First Name"
                                    {...register("first_name", {
                                        required: "First name required",

                                    })}
                                    className={classNames("", {
                                        "is-invalid": errors?.first_name,
                                        "is-valid": getValues("first_name"),
                                    })}
                                    onKeyDown={(event) => {
                                        if (!RegxExpression.name.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Last Name*</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="last_name"
                                    placeholder="Enter Last Name"
                                    {...register("last_name", {
                                        required: "Last Name required",

                                    })}
                                    className={classNames("", {
                                        "is-invalid": errors?.last_name,
                                        "is-valid": getValues("last_name"),
                                    })}
                                    onKeyDown={(event) => {
                                        if (!RegxExpression.name.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Email Address</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="email"
                                    placeholder="Enter Email"
                                    {...register("email", {
                                        required: "Email is required",
                                        pattern: {
                                            value: RegxExpression.email,
                                            message: "Invalid email address",
                                        },
                                    })}
                                    className={classNames("", {
                                        "is-invalid": errors?.email,
                                        "is-valid": getValues("email"),
                                    })}
                                />
                                {errors?.email && (
                                    <sup className="text-danger">
                                        {errors?.email?.message}
                                    </sup>
                                )}
                            </Form.Group>
                            <Form.Group controlId="formFirstName">
                                <Form.Label className="emailtxt">Password*</Form.Label>
                                <div className="formfieldregister">
                                    <Form.Control
                                        type={showPassword ? "text" : "password"}
                                        placeholder="Enter Password"
                                        name="password"
                                        {...register("password", {
                                            required: "Password is required",
                                            minLength: {
                                                value: 8,
                                                message:
                                                    "Password must be at least 8 characters long",
                                            },
                                            pattern: {
                                                value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                                                message:
                                                    "Password must contain at least one uppercase letter, one lowercase letter, and one number",
                                            },
                                        })}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />


                                    <div
                                        className="eyeIcon"
                                        onClick={togglePasswordVisibility}
                                    >
                                        {showPassword ? "🙈" : "👁️"}
                                    </div>
                                </div>
                            </Form.Group>


                            <Form.Group controlId="formFirstName">
                                <Form.Label className="emailtxt">Confirm Password*</Form.Label>
                                <div className="formfieldregister">
                                    <Form.Control
                                        type={showConformPassword ? "text" : "password"}
                                        placeholder="Re-Enter Password"
                                        name="re_password"
                                        {...register("re_password", {
                                            required: "Re-Password is required",
                                        })}
                                        onChange={(e) => setPasswordAgain(e.target.value)}
                                    />
                                    {errors?.re_password && (
                                        <sup className="text-danger">
                                            {errors?.re_password?.message}
                                        </sup>
                                    )}
                                    {password && (
                                        <PasswordChecklist
                                            rules={[
                                                "minLength",
                                                "specialChar",
                                                "number",
                                                "capital",
                                                "match",
                                            ]}
                                            minLength={8}
                                            value={password}
                                            valueAgain={passwordAgain}
                                            onChange={(isValid) => { }}
                                            style={{
                                                fontSize: "10px",
                                                padding: "4px",
                                                margin: "0",
                                            }}
                                        />
                                    )}

                                    <div
                                        className="eyeIcon"
                                        onClick={toggleConformPasswordVisibility}
                                    >
                                        {showConformPassword ? "🙈" : "👁️"}
                                    </div>
                                </div>
                            </Form.Group>
                        </Form>

                    </Col>
                </Row>
                <div className="save-btn-div mt-md-5 mt-3">
                    <Common_Button onClick={handleSubmit(onSubmit)} text={isSaved ? 'SAVE & NEXT' : 'Next'} className="continue-btn" />
                    {/* <Button className="continue-btn" type="submit">
                            Next
                        </Button> */}
                </div>

                <div className="signup-link">
                    <p>
                        By signing up, I agree to the{" "}
                        <Link to={"/terms-condition"}>
                            Terms and Conditions
                        </Link>
                    </p>

                </div>
                <div className="social-login pb-5">
                    {/* <p>You can also login by using your</p>
                    <div className="social-icons">
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                "/assest/image/login/facebook.png"
                            }
                            alt="Google"
                        />
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                "/assest/image/login/google.png"
                            }
                            alt="Google"
                        />
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                "/assest/image/login/twitter.png"
                            }
                            alt="Google"
                        />
                    </div> */}
                </div>


            </section>
            {!isCookieAccepted && (
                <Cookies_popup_modal
                    isCookieAccepted={isCookieAccepted}
                    setIsCookieAccepted={setIsCookieAccepted}
                    show={showCookiesModal}
                    onHide={() => setShowCookiesModal(false)}

                    onAcceptCookies={handleAcceptCookies}
                />
            )}


        </>
    )
}

export default PersonalInformation