import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";

export const postAddress = async (data) => {
  try {
    return await postData(`/order-process/order-address`, data);
  } catch (error) {
    console.log(error);
  }
};

export const getAddress = async () => {
  try {
    return await getData(`/order-process/order-address`);
  } catch (error) {
    console.log(error);
  }
};


export const deleteAddress = async (id) => {
  try {
    return await deleteData(`/order-process/${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const getSingleAddress = async (id) => {
  try {
    return await getData(`/order-process/order-address/${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const getSingleShippingAddress = async (id) => {
  try {
    return await getData(`/order-process/order-address/shipping/${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const DeleteAddress = async (id) => {
  try {
    return await getData(`/useraddress/delete/${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const postorder = async (data) => {
  try {
    return await postData(`/order-process/order`, data);
  } catch (error) {
    console.log(error);
  }
};

export const cancelorder = async (data) => {
  try {
    return await postData(`/order-process/order-cancel`, data);
  } catch (error) {
    console.log(error);
  }
};

export const Returnorder = async (data) => {
  try {
    return await postData(`/order-process/order-return`, data);
  } catch (error) {
    console.log(error);
  }
};

export const getOrder= async (selectedOrderTime) => {
  try {
    // const timeString = selectedOrderTime.join(',');
    if (selectedOrderTime) {
      return await getData(`/order-process/orderget?time=${selectedOrderTime}`);
    }else{
      return await getData(`/order-process/orderget`);
    }
  } catch (error) {
    console.log(error);
  }
};

export const getSingleOrder = async (id) => {
  try {
    return await getData(`/order-process/orderget/${id}`);
  } catch (error) {
    console.log(error);
  }
};
export const getCouponCode = async (code, amount) => {
  try {
    return await getData(`/coupenCode/coupon-code?code=${code}&amount=${amount}`);
  } catch (error) {
    // Capture the error message from the response
    const message = error.response?.data?.message || 'Invalid Coupon';
    return { success: false, message };
  }
};
