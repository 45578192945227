import React, { useEffect, useRef, useState, useContext } from "react";
import "./ProductCare.css";
import { Button, Col, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { ProductQuery } from "../../utils/apis/form/form";
import { useForm, Controller } from "react-hook-form";
import classNames from "classnames";
import { RegxExpression } from "../../utils/common";
import SuccessfullyModal from "../Common-Component/Commonmodal/SuccessfullyModal/SuccessfullyModal";
const ProductCare = () => {
  const [value, setValue] = useState();
  const cardsData = [
    {
      key: 1,
      count: 50,
      suffix: " K",
      title: "Happy Customer",
      description: "buy, manage, and distribute HR gifts",
    },
    {
      key: 2,
      count: 10,
      suffix: " M",
      title: "Hours Saved",
      description: "buy, manage, and distribute HR gifts",
    },
    {
      key: 3,
      count: 5,
      suffix: " M",
      title: "Grateful Recipient",
      description: "buy, manage, and distribute HR gifts",
    },
  ];
  const [showSuccess, setShowsuccess] = useState(false);
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset,
    trigger,
  } = useForm();

  const onSubmit = async (data) => {
    const formData = new FormData();

    formData.append("product_code", data?.product_code);
    formData.append("product_query", data?.product_query);
    formData.append("name", data?.name);
    formData.append("email", data?.email);
    formData.append("contact_no", data?.contact_no);

    const res = await ProductQuery(formData);
    if (res?.success) {
      setShowsuccess(true);
      setTimeout(() => {
        setShowsuccess(false);
      }, 3000);
      reset();
      //   customiseClose();
    } else {
    }
  };
  return (
    <section className="ProductCare">
      <div className="AboutusMain">
        <div className="container">
          <Row>
            <Col lg={5} md={7}>
              <div className="aboutushead">
                <h3>Product Care</h3>
                <span>Care. Love. Preserve. Shine.</span>
                <div className="productCareinfo">
                  <p>
                    Revitalize your product's brilliance with our savvy care
                    techniques! From routine maintenance to expert tricks, we've
                    got you covered. Say goodbye to dullness and hello to
                    long-lasting charm.
                  </p>

                  <p>
                    {" "}
                    With our simple yet effective care routine, you'll have
                    heads turning in admiration. Elevate your product care game
                    today and enjoy a lifetime of satisfaction!
                  </p>
                </div>
              </div>
            </Col>
            <Col lg={7} md={5}>
              <div className="RightImgcare">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/assest/image/ProductCare/product_care1.png"
                  }
                />
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <div className="container">
        <div className="feelFree">
          <div className="feelfreehead">
            <h3>
              <span>Feel Free To Contact Us</span>
              <span>Regarding Any Query of Your Product</span>
            </h3>
          </div>

          <div className="row formMain">
            <div className="col-lg-6 mx-auto">
              <div className="feelfreecontactform">
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Control
                      type="text"
                      name="name"
                      placeholder="Enter Name"
                      {...register("name", {
                        required: "name required",
                      })}
                      className={classNames("", {
                        "is-invalid": errors?.name,
                        "is-valid": getValues("name"),
                      })}
                      onKeyDown={(event) => {
                        if (!RegxExpression.name.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </Form.Group>

                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Control
                      type="text"
                      name="email"
                      placeholder="Enter Email"
                      {...register("email", {
                        validate: (value) =>
                          value || getValues("contact_no")
                            ? true
                            : "Email or Mobile Number is required",
                        pattern: {
                          value: RegxExpression?.email,
                          message: "Invalid email address",
                        },
                      })}
                      className={classNames("", {
                        "is-invalid": errors?.email,
                        "is-valid": getValues("email"),
                      })}
                    />
                  </Form.Group>
                  <Controller
                    name="contact_no"
                    control={control}
                    className="mb-3"
                    rules={{
                      validate: (value) =>
                        value || getValues("email")
                          ? true
                          : "Mobile Number or Email is required",
                      pattern: {
                        value: /^\+?\d{1,4}[-\s]?\d{10}$/,
                        message:
                          "Invalid number format. Please enter a valid number with an optional country code",
                      },
                    }}
                    render={({ field }) => (
                      <PhoneInput
                        country={"in"}
                        {...field}
                        className={classNames("mb-3", {
                          "is-invalid": errors?.contact_no,
                          "is-valid": getValues("contact_no"),
                        })}
                        onChange={(value) => field.onChange(value)}
                        type="text"
                        placeholder="Enter Mobile Number"
                        onKeyDown={(event) => {
                          if (!RegxExpression.phone.test(event.key)) {
                            if (event.key !== "Backspace") {
                              event.preventDefault();
                            }
                          }
                        }}
                        maxLength={10}
                      />
                    )}
                  />
                  {errors?.contact_no && (
                    <sup className="text-danger">
                      {errors?.contact_no?.message}
                    </sup>
                  )}

                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Control
                      type="text"
                      name="product_code"
                      placeholder="Enter Product Name or Code"
                      {...register("product_code", {
                        required: "product_code required",
                      })}
                      className={classNames("", {
                        "is-invalid": errors?.product_code,
                        "is-valid": getValues("product_code"),
                      })}
                    />
                  </Form.Group>

                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Control
                      type="text"
                      name="product_query"
                      placeholder="Type a Query regarding your product"
                      {...register("product_query", {
                        required: "product_query, required",
                      })}
                      className={classNames("", {
                        "is-invalid": errors?.product_query,
                        "is-valid": getValues("product_query"),
                      })}
                    />
                  </Form.Group>

                  <div className="sendRequest">
                    <Button type="submit" className="sendbtn">
                      Send Request
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <SuccessfullyModal
        show={showSuccess}
        onHide={() => setShowsuccess(false)}
        text={"Product Query has been send successfully"}
      />
    </section>
  );
};

export default ProductCare;
