import React, { useEffect, useRef, useState, useContext } from "react";
import "./SubmitSuggestion.css";
import Form from "react-bootstrap/Form";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Button } from "react-bootstrap";
import Common_Button from "../Common-Component/Common_Button/Common_Button";
import SuccessfullyModal from "../Common-Component/Commonmodal/SuccessfullyModal/SuccessfullyModal";
import { Suggetion } from "../../utils/apis/form/form";
import { useParams } from "react-router-dom";
import { Context } from "../../utils/context";
import Cookies from "js-cookie";
import { Link, useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import classNames from "classnames";
import { RegxExpression } from "../../utils/common";
import {
  getProductVariant,
  getVariant,
} from "../../utils/apis/Product/Product";
import { Material } from "../../utils/apis/master/Master";
const SubmitSuggestion = () => {
  const [value, setValue] = useState();
  const [showSuccess, setShowsuccess] = useState(false);

  const customiseClose = () => {
    setShowsuccess(true);
    setTimeout(() => {
      setShowsuccess(false);
      window.location.pathname = "/home";
    }, 3000);
  };
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset,
    trigger,
  } = useForm();

  const onSubmit = async (data) => {
    const formData = new FormData();

    formData.append("suggestion", data?.suggestion);
    formData.append("name", data?.name);
    formData.append("email", data?.email);
    formData.append("contact_no", data?.contact_no);

    const res = await Suggetion(formData);
    if (res?.success) {
      customiseClose();
    } else {
    }
  };

  return (
    <>
      <section className="SubmitSuggestion">
        <div className="suggestionForm">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <div className="formCard">
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Control
                        type="text"
                        name="name"
                        placeholder="Enter Name"
                        {...register("name", {
                          required: "name required",
                        })}
                        className={classNames("", {
                          "is-invalid": errors?.name,
                          "is-valid": getValues("name"),
                        })}
                        onKeyDown={(event) => {
                          // event.key === 'Enter' && event.preventDefault()
                          if (!RegxExpression.name.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                    </Form.Group>

                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Control
                        type="text"
                        name="email"
                        placeholder="Enter Email"
                        {...register("email", {
                          validate: (value) =>
                            value || getValues("contact_no")
                              ? true
                              : "Email or Mobile Number is required",
                          pattern: {
                            value: RegxExpression?.email,
                            message: "Invalid email address",
                          },
                        })}
                        className={classNames("", {
                          "is-invalid": errors?.email,
                          "is-valid": getValues("email"),
                        })}
                        // onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
                      />
                    </Form.Group>

                    <div className="mb-3">
                      <Controller
                        name="contact_no"
                        control={control}
                        rules={{
                          validate: (value) =>
                            value || getValues("email")
                              ? true
                              : "Mobile Number or Email is required",
                          pattern: {
                            value: /^\+?\d{1,4}[-\s]?\d{10}$/,
                            message:
                              "Invalid number format. Please enter a valid number with an optional country code",
                          },
                        }}
                        // rules={{
                        //   required: "Field is required",
                        //   pattern: {
                        //     value: /^\+?\d{1,4}[-\s]?\d{10}$/,
                        //     message:
                        //       "Invalid number format. Please enter a valid number with an optional country code",
                        //   },
                        // }}
                        render={({ field }) => (
                          <PhoneInput
                            country={"in"}
                            {...field}
                            className={classNames("", {
                              "is-invalid": errors?.contact_no,
                              "is-valid": getValues("contact_no"),
                            })}
                            onChange={(value) => field.onChange(value)}
                            type="text"
                            placeholder="Enter Mobile Number"
                            onKeyDown={(event) => {
                              // event.key === 'Enter' && event.preventDefault()
                              if (!RegxExpression.phone.test(event.key)) {
                                if (event.key !== "Backspace") {
                                  event.preventDefault();
                                }
                              }
                            }}
                            maxLength={10}
                          />
                        )}
                      />
                      {errors?.contact_no && (
                        <sup className="text-danger">
                          {errors?.contact_no?.message}
                        </sup>
                      )}
                    </div>

                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Control
                        type="text"
                        name="suggestion"
                        placeholder="Enter suggestion"
                        {...register("suggestion", {
                          required: "suggestion required",
                        })}
                        className={classNames("", {
                          "is-invalid": errors?.suggestion,
                          "is-valid": getValues("suggestion"),
                        })}
                        onKeyDown={(e) => {
                          e.key === "Enter" && e.preventDefault();
                        }}
                      />
                    </Form.Group>

                    <div className="submitbtnsec">
                      {/* <Common_Button type="submit" className="btn" text={"Send Message"} /> */}
                      <Button
                        variant="primary"
                        type="submit"
                        className="submit-button"
                        aria-hidden="true"
                        // className="hidden"
                        // disabled
                      >
                        Send Message
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>

              <div className="col-lg-8 col-md-6">
                <div className="suggestiontxt">
                  <p>Let's make things </p>
                  <p>better together!!!</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <SuccessfullyModal
          show={showSuccess}
          onHide={() => setShowsuccess(false)}
          text={"Your Request has been send successfully"}
        />
      </section>
    </>
  );
};

export default SubmitSuggestion;
