import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";

function Popular_Product_Card({
  content,
  image,
  imgClassName,
  className,
  textClassName,
  alt,
  overlaycolor,
  mainimagesec,
  icon,
  link
}) {

 
  
  return (
    <>
        <Link to={link}>
      <div className={className}>
        <img src={image} className={imgClassName} alt={alt} />
        <div className="text-holder">
          <Link to={link}>
            <p className={textClassName}>{content}</p>
          </Link>
          {/* <Link to={link}> */}
            <FontAwesomeIcon icon={icon} className="fa-icon" />
          {/* </Link> */}
        </div>
        <div className={overlaycolor}></div>


      </div>
      </Link>
    </>
  );
}

export default Popular_Product_Card;
