import Cookies from "js-cookie";
import { v4 as uuidv4 } from 'uuid';
import {
    getData,
    postData,
    putData,
    editStatusData,
    deleteData,
    getDownloadDataExcel,
} from "../../api";

import { Context, variant } from "../../context";
import { useContext } from "react";

import { getProductDetail, getVariant, } from "../Product/Product";


export const madeToOrder = async (data) => {
    try {
      const res = await postData(`/form/made-to-order`, data);
  
      return res;
    } catch (error) {
      console.log(error);
    }
  };

  export const CustomizationForm = async (data) => {
    try {
      const res = await postData(`/form/customization`, data);
  
      return res;
    } catch (error) {
      console.log(error);
    }
  };

  export const RFQ = async (data) => {
    try {
      const res = await postData(`/form/rfq`, data);
  
      return res;
    } catch (error) {
      console.log(error);
    }
  };

  export const ContactUsForm = async (data) => {
    try {
      const res = await postData(`/form/contact-us`, data);
  
      return res;
    } catch (error) {
      console.log(error);
    }
  };

  export const TalkToUs = async (data) => {
    try {
      const res = await postData(`/form/talk-to-us`, data);
  
      return res;
    } catch (error) {
      console.log(error);
    }
  };

  export const Suggetion = async (data) => {
    try {
      const res = await postData(`/form/suggestion`, data);
  
      return res;
    } catch (error) {
      console.log(error);
    }
  };

  export const ProductQuery = async (data) => {
    try {
      const res = await postData(`/form/product-query`, data);
  
      return res;
    } catch (error) {
      console.log(error);
    }
  };

