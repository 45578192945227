import React, { useEffect, useRef,useState, useContext  } from 'react'
import { Col, Row } from 'react-bootstrap'
import "./OurLatestreward.css";
import { Context } from "../../../utils/context";
import { OurlatestAward } from "../../../utils/apis/AboutUs/AboutUs";
const OurLatestreward = ({data}) => {

    
  
    const {  IMG_URL ,formatDate} = useContext(Context);
    
  
    
    return (
        <>
            <section className='OurLatestReward'>
                <div className='container'>
                    <div className='latestRewardHead'>
                        <h3>Our latest <b className='awardd'>awards</b></h3>
                    </div>
                    <Row>
                        <Col lg={10} className='mx-auto'>
                            <Row>
                                {data?.map((val)=>
                                <Col lg={4} md={4}>
                                    <div className='RewardBackimg'>
                                        <img src={IMG_URL + val?.image} />
                                        <div className='rewardOntxt'>
                                            <p>{val?.name}</p>
                                            <p>{formatDate(val?.createdAt)}</p>

                                        </div>
                                    </div>
                                </Col>
                                )}
                                {/* <Col lg={4} md={4}>
                                    <div className='RewardBackimg'>
                                        <img src={process.env.PUBLIC_URL + "/assest/image/Aboutus/award-01.png"} />
                                        <div className='rewardOntxt'>
                                            <p>HIGH PERFORMER</p>
                                            <p>2024</p>

                                        </div>
                                    </div>
                                </Col>
                                <Col lg={4} md={4}>
                                    <div className='RewardBackimg'>
                                        <img src={process.env.PUBLIC_URL + "/assest/image/Aboutus/award-01.png"} />
                                        <div className='rewardOntxt'>
                                            <p>BEST SUPPORT</p>
                                            <p>2024</p>

                                        </div>
                                    </div>
                                </Col> */}
                            </Row>

                        </Col>
                    </Row>
                </div>
            </section>
        </>
    )
}

export default OurLatestreward