import React, { useState, useContext } from 'react';
import { useForm } from 'react-hook-form';
import Modal from 'react-bootstrap/Modal';
import { Button, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faTrash, faUpload } from '@fortawesome/free-solid-svg-icons';
import './ResumeModal.css';
import { Context } from "../../../../utils/context";
import SuccessfullyModal from '../SuccessfullyModal/SuccessfullyModal';
const ResumeModal = (props) => {
    const { register, handleSubmit, setValue, watch, getValues, formState: { errors },reset } = useForm();
    const file = watch('resumeFile');
    const { postData } = useContext(Context);
    const [error, seterror] = useState('');
    const [fileName, setFileName] = useState('');
    const [showSuccess, setShowsuccess] = useState(false);
  

    const onSubmit = async (data) => {
        
        const finalData = new FormData();
    
        if (data.resume.name) {
            finalData.append("resume", data.resume); 
            finalData.append("job_id", props?.JobId);
            
            const response = await postData(`/without-login/career/resume`, finalData);
          
            if (response?.success) {
                setShowsuccess(true);
                seterror('')
                setFileName('');
                reset();
                setTimeout(() => {
                   
                    setShowsuccess(false); 
                    props.onHide();// Reset the success state after modal is hidden
                }, 3000);
               
              
               
                
            } else {
                // Handle error response from API
            }
        } else {
            seterror("Resume is required"); // Correctly set the error message
        }
    };
    




    const handleIconClick = () => {
        document.getElementById('resumeFileInput').click();
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            seterror('')
            setFileName(file.name); // Store the file name
            setValue('resume', event.target.files[0]); // Update React Hook Form value
        }
    };
   

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className='Resumemodal'
        >
            <Modal.Body>
                <section>
                    <div>
                        <div className='resumeHead'>
                            <h3>Resume:</h3>
                            <p>Resume is the most important document recruiters look for. Recruiters generally do not look at profiles without resumes.</p>
                            <div>
                                <div className='text-end'>
                                    {/* <FontAwesomeIcon
                                        icon={faDownload}
                                        className='trashIcon'
                                        onClick={handleDownloadResume}
                                    />
                                    <FontAwesomeIcon
                                        icon={faTrash}
                                        className='trashIcon'
                                        onClick={handleDeleteResume}
                                    /> */}
                                </div>
                            </div>
                        </div>

                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className='row uploadResumebtn'>
                                <Col lg={6}>
                                    <Button type='submit' className='btn uploadbtn'>Submit Resume</Button>
                                    {/* <p>Supported Formats: doc, docx, rtf, pdf, up to 2 MB</p> */}
                                </Col>
                                <Col lg={6}>
                                    <div className='uploadLink'>
                                        {fileName && (
                                            <p>
                                                {fileName}
                                            </p>
                                        )}
                                        <p>Upload Resume 
                                            <FontAwesomeIcon
                                                icon={faUpload}
                                                className='ms-2'
                                                onClick={handleIconClick}
                                            />
                                            <input
                                                id='resumeFileInput'
                                                type="file"
                                                 name="resume"
                                                accept=".pdf,.doc,.docx" // Accept specific file types (optional)
                                                {...register("resume", {
                                                    // required: "resume is required",
                                                })}
                                                style={{ display: 'none' }} // Hide the actual input field
                                                onChange={handleFileChange}
                                            />
                                           {error && <div className="error-message" style={{ color: 'red' }}>{error}</div>}
                                        </p>
                                    </div>
                                </Col>
                            </div>
                        </form>
                    </div>
                    <SuccessfullyModal show={showSuccess} onHide={() => setShowsuccess(false)} text={"Applied successfully"} />
                </section>
            </Modal.Body>
        </Modal>
    );
};

export default ResumeModal;




// import React from 'react'
// import "./ResumeModal.css";
// import Modal from 'react-bootstrap/Modal';
// import { Button, Col } from 'react-bootstrap';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// const ResumeModal = (props) => {
//     return (
//         <>
//             <Modal
//                 {...props}
//                 size="lg"
//                 aria-labelledby="contained-modal-title-vcenter"
//                 centered
//                 className='Resumemodal'
//             >

//                 <Modal.Body>
//                     <section>
//                         <div>
//                             <div className='resumeHead'>
//                                 <h3>Resume:</h3>
//                                 <p>Resume is the most important document recruiters look for. Recruiters generally do not look at profiles without resumes.</p>
//                                 <div>
//                                     <div className='text-end'>
//                                         <FontAwesomeIcon icon="fa-solid fa-download" className='trashIcon' />
//                                         <FontAwesomeIcon icon="fa-solid fa-trash" className='trashIcon' />
//                                     </div>
//                                 </div>
//                             </div>

//                             <div className='row uploadResumebtn'>
//                                 <Col lg={6}>
//                                     <Button className='btn uploadbtn'>Upload Resume</Button>
//                                     <p>Supported Formats: doc, docx, rtf, pdf, upto 2 MB</p>

//                                 </Col>
//                                 <Col lg={6}>
//                                     <div className='uploadLink'>
//                                         <p>Upload Resume Link <FontAwesomeIcon icon="fa-solid fa-upload" className='ms-2' /></p>

//                                     </div>
//                                 </Col>
//                             </div>
//                         </div>
//                     </section>
//                 </Modal.Body>

//             </Modal>


//         </>
//     )
// }

// export default ResumeModal