import React, { useEffect, useRef, useState } from "react";
import './mob_search.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import ListGroup from 'react-bootstrap/ListGroup';


const Mob_search = () => {

    const [show, toggleShow] = useState(false);

    return (
        <>
            <div className="searchformob">

                <div className='d-flex'>
                    <Link className='my-auto' to={"/home"}><FontAwesomeIcon className='backscreen' icon="fa-solid fa-chevron-left" /></Link>
                    <input onClick={() => toggleShow(!show)} type="search" className="form-control mobileSearch" placeholder="Search here..." />
                </div>

                {show &&
                
                    <ListGroup className='listgroup'>
                        <ListGroup.Item>Product 1</ListGroup.Item>
                        <ListGroup.Item>Product 2</ListGroup.Item>
                        <ListGroup.Item>Product 3</ListGroup.Item>
                        <ListGroup.Item>Product 4</ListGroup.Item>
                        <ListGroup.Item>Product 5</ListGroup.Item>
                    </ListGroup>
                }

            </div>
        </>
    )
}

export default Mob_search