import React, { useEffect, useState, useContext } from 'react'
import './PromotionalProduct.css'
import { Col, Container, Row } from 'react-bootstrap'
import Popular_Product_Card from '../../Common-Component/Popular_Product_Card/Popular_Product_Card'
import { Link } from 'react-router-dom'
import { promotional_products } from '../../../utils/apis/Home/Homeapi'
import { Context } from "../../../utils/context";
const PromotionalProduct = ({promoProduct}) => {
   
    const { IMG_URL } = useContext(Context);
  
    return (
        <>
            <section className='promotional-product'>
                <Container>
                    <Row>
                        <div className='heading-holder'>
                            <div className='d-flex'>
                                <h2 className='first-heading'>Shop</h2>
                                <h3 className='second-heading'>Promotional Products</h3>
                            </div>
                            <span className='text-para'>Unleash Your Brand's Potential With Our Irresistible Promotional Products!</span>
                        </div>
                        <>
                            <Col lg={6}>
                                <Row>
                                {promoProduct?.slice(0, 2).map((row, rowIndex) => (
                                        <React.Fragment key={rowIndex}>
                                            {row.map((product, colIndex) => (
                                                <Col key={colIndex} lg={6} md={6} sm={6} xs={6}>
                                                    {/* {product?.product_variants?.map((variant, variantIndex) => ( */}
                                                    <Popular_Product_Card
                                                        key={colIndex}
                                                        className="main-section"
                                                        image={IMG_URL + product?.product_variants[0]?.first_image}
                                                        imgClassName="seller-img"
                                                        overlaycolor="bg-overlay-one"
                                                        mainimagesec="image-box"
                                                        icon="fa-solid fa-arrow-right"
                                                        link={`/productDetails/${product?.id}?type=Promotional Products`}
                                                        //  link="/promotional-product"
                                                    />
                                                    {/* ))} */}
                                                </Col>
                                            ))}
                                        </React.Fragment>
                                    ))}

                                </Row>
                            </Col>
                            {promoProduct && promoProduct.length > 0 && promoProduct[0] && promoProduct[0][0] && promoProduct[0][0].product_variants && (
                                <Col lg={6}>
                                    <Popular_Product_Card
                                        className="main-section"
                                        image={IMG_URL + promoProduct[0][0].product_variants[0]?.first_image}
                                        imgClassName="seller-img"
                                        content="VIEW ALL"
                                        textClassName="local-text"
                                        overlaycolor="bg-overlay"
                                        link="/promotional-product"
                                    />
                                </Col>
                            )}
                            {/* <Col lg={6}>
                                <Popular_Product_Card
                                    className="main-section "
                                    image={IMG_URL + promoProduct[0][0]?.product_variants[0]?.first_image}
                                    imgClassName="seller-img"
                                    content="VIEW ALL"
                                    textClassName="local-text"
                                    overlaycolor="bg-overlay"
                                    link="/promotional-product"
                                />
                            </Col> */}
                        </>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default PromotionalProduct
