import React, { useContext, useEffect, useState } from 'react';
import './Login.css';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import { Link, useNavigate } from 'react-router-dom';
import { sellerLogin } from '../../utils/apis/login/Login';
import { useForm } from 'react-hook-form';
import { Context } from '../../utils/context';
import Cookies from "js-cookie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from "react-bootstrap/Form";
import classNames from "classnames";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { loginCart } from "../../utils/apis/addToCart/addToCart";
// import LoginModal from "../Common_modal/LoginModal/LoginModal"
const Login = () => {
    const [showForgetPassword, setShowForgetPassword] = useState(false);
    const [showEmail, setShowEmail] = useState(true);
    // const { signin, setSignin } = useContext(Context);
    const [showPassword, setShowPassword] = useState(false);
    const [iconOne, setIconOne] = useState(faEye);
    const [phoneValue, setPhoneValue] = useState("");
    const navigate = useNavigate();
    const [modalShow, setModalShow] = useState(false);



    const togglePasswordVisibility = (field) => {
        if (field === "password") {
            setShowPassword(!showPassword);
            setIconOne(showPassword ? faEyeSlash : faEye);
        }
    };

    const {
        signin,
        setSignin, shimmerLoader, setShimmerLoader,SellerURL } = useContext(Context);

    const {
        control,
        register,
        handleSubmit,
        getValues,
        setError,
        clearErrors,
        formState: { errors },
        reset,
        trigger, setValue
    } = useForm();


    const onSubmit = async (data) => {
      
        setShimmerLoader(true);
        const res = await sellerLogin(data);
        if (res?.success) {
            await Cookies.set('coinzkart_admin_security', res?.data?.token);
            // await setSignin(true);
            // await loginCart();

            setModalShow(true);
            setTimeout(() => {
                window.location.href = `${SellerURL}?token=${res?.data?.token}`;
            }, 1000);
        } else {
            setShimmerLoader(false);
            if (res?.message) {
                setError('email', { message: res?.message });
                setError('password', "");
            }
        }
    }

  
    const handleEmailOrContactChange = (event) => {
        setValue("email", event.target.value);
    };
    return (
        <>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Group className="mb-3" controlId="">
                    <label htmlFor="email" className='emailtxt'>Email </label>
                    <Form.Control
                        type="text"
                        name="email"
                        placeholder="Email"
                        {...register("email", {
                            required: "Credentials required",
                        })}
                        className={classNames("", {
                            "is-invalid": errors?.email,
                            // "is-valid": getValues("email_or_contact"),
                        })}
                        onChange={handleEmailOrContactChange}
                    />
                    {errors?.email && (
                        <sup className="text-danger">{errors?.email?.message}</sup>
                    )}
                </Form.Group>
                <div className="form-group">
                    <label className='emailtxt' htmlFor="password">Password</label>
                    <Form.Group className="mb-3" controlId="">
                        <div className="password-container">
                            <Form.Control
                                type={showPassword ? "text" : "password"}
                                placeholder="Enter Password*"
                                name="password"
                                {...register("password", {
                                    required: "Password is required"
                                })}
                                className={classNames("", {
                                    "is-invalid": errors?.password,
                                    "is-valid": getValues("password"),
                                })}
                            />
                            <span className="password-icon" onClick={togglePasswordVisibility}>
                                <FontAwesomeIcon
                                    icon={iconOne}
                                    className="eye-icon"
                                    onClick={() => togglePasswordVisibility("password")}
                                />
                            </span>
                            {/* <span className="password-icon" onClick={togglePasswordVisibility}>👁️</span> */}
                        </div>
                    </Form.Group>
                    {errors?.password && (
                        <sup className="text-danger">{errors?.password?.message}</sup>
                    )}
                </div>
                <div className="forgot-password">
                    <Link to={"/forgetpassword"}>Forgot Password?</Link>
                </div>
                <button type="submit" className="btn btn-primary btn-block">Log-in</button>
            </Form>
            <div className="signup-link">
                <p>Don't have an account? <Link to={"/register"} ><span style={{ color: "#234C9E", fontWeight: "600", textDecoration: "underline" }}>Create Account</span></Link></p>
            </div>
            {/* <div className="social-login">
                <p>You can also login by using your</p>
                <div className="social-icons">
                    <img src={process.env.PUBLIC_URL + "/assest/image/login/facebook.png"} alt="Google" />
                    <img src={process.env.PUBLIC_URL + "/assest/image/login/google.png"} alt="Google" />
                    <img src={process.env.PUBLIC_URL + "/assest/image/login/twitter.png"} alt="Google" />
                </div>
            </div> */}
        </>
    );
};

export default Login;
