

import { getData } from "../../api";

// Blog
export const value = async (id) => {
    try {
        return await getData(`/about-us/values`);
    } catch (error) {
        console.log(error);
    }
};

export const whoWeAre = async (id) => {
    try {
        return await getData(`/about-us/who-we-are`);
    } catch (error) {
        console.log(error);
    }
};

// Faq

export const OurlatestAward = async (id) => {
    try {
        return await getData(`/about-us/our-latest-awards`);
    } catch (error) {
        console.log(error);
    }
};

export const Counter = async (id) => {
    try {
        return await getData(`/without-login/about-us-counter`);
    } catch (error) {
        console.log(error);
    }
}


