import React, { useEffect, useRef,useState, useContext  } from 'react'
import "./Profile.css";
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import { Button, ProgressBar } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Common_Button from '../Common-Component/Common_Button/Common_Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import Editproflemodal from '../Common-Component/Commonmodal/Editprofilemodal/Editproflemodal';
import SuccessfullyModal from '../Common-Component/Commonmodal/SuccessfullyModal/SuccessfullyModal';
import AddNewaddress from "../Common-Component/Commonmodal/AddNewaddress/AddNewaddress";
import EditNewaddress from "../Common-Component/Commonmodal/AddNewaddress/EditNewaddress";
import ChangePassword from "./changepassword";
import classNames from "classnames";
import { useForm } from 'react-hook-form';
import { Context } from "../../utils/context";
import { RegxExpression } from "../../utils/common";

import { UserGet ,changeEmail} from "../../utils/apis/myAccount/myAccount";
import { postAddress, getAddress, deleteAddress, getSingleAddress } from "../../utils/apis/orderProcess/orderProcess";
import Delete_Modal from '../Common-Component/Commonmodal/Delete_Modal/Delete_Modal';
const Profile = () => {
    const [editshow, setEditshow] = useState(false);
    const [newAddress, setNewaddress] = useState(false);
    const [newEditAddress, setEditNewaddress] = useState(false);
    const [emailModal, setEmailmodal] = useState(false);
    const [passwordModal, setPasswordModal] = useState(false);
    const [activeTab, setActiveTab] = useState("1");
    const emailClose = () => {
        setEmailmodal(true);
        setTimeout(() => {
            setEmailmodal(false);
            setActiveTab('1')
        }, 3000)
    }

    const passwordClose = () => {
        setPasswordModal(true);
        setTimeout(() => {
            setPasswordModal(false);
            setActiveTab('1')
        }, 3000)
    }

    const navItems = [
        { eventKey: "1", title: "Personal Details" },
        { eventKey: "2", title: "My Orders" },
        { eventKey: "3", title: "Loyalty Points" },
        { eventKey: "4", title: "Manage Address" },
        { eventKey: "5", title: "Update Email Address" },
        { eventKey: "6", title: "Update Password" }
    ];

    const loyaltyCards = [
        { points: 4, progress: 30, imgSrc: "/assest/image/Profile/mittallogo.png", valid: "Invalid", classname: "Invalidtxt" },
        { points: 4, progress: 30, imgSrc: "/assest/image/Profile/mittallogo.png", valid: "SHOP NOW", classname: "shopnow" },
        { points: 4, progress: 30, imgSrc: "/assest/image/Profile/mittallogo.png", valid: "SHOP NOW", classname: "shopnow" }
    ];

    const addresses = [
        { type: "Home", name: "John Doe", address: "E/92 Apmc Market Yard, Turbhe, Vashi, Mumbai , Maharashtra - 400703" },
        { type: "Work", name: "John Doe", address: "E/92 Apmc Market Yard, Turbhe, Vashi, Mumbai , Maharashtra - 400703" }
    ];

    const [data, setData] = useState();
    const [modalDeleteShow, setDeleteModalShow] = useState(false);
    const {  IMG_URL } = useContext(Context);
    const getAllarressData = async () => {
        const res = await UserGet();
        if (res?.success) {
            setData(res?.data);
            
        } else {
            console.error('Error fetching  data:', res?.error);
        }
    }
  
    

    const [addressdata, setAdressData] = useState();
    const [deleteID, setDeleteID] = useState();
    const getAllData = async () => {
        const res = await getAddress();
        if (res?.success) {
            setAdressData(res?.data);
        } else {
            console.error('Error fetching  data:', res?.error);
        }
    }

    useEffect(() => {
        getAllarressData();
        getAllData();
    }, []);
    const [editAddress, seteditAddress] = useState(null);
    const handleEditClick = (id) => {
        setEditNewaddress(true);
        seteditAddress(id)

    };

  
        const handleDelete = async (id) => {
         
            setDeleteModalShow(true)
            setDeleteID(id)
        }
    

    const {
        control,
        register,
        handleSubmit,
        getValues,
        setError,
        clearErrors,
        formState: { errors },
        reset,
        trigger, setValue
    } = useForm();


    const onSubmit = async (data) => {
       
        const res = await changeEmail(data);
        if (res?.success) {
            emailClose()
           reset()
           window.location.reload();
        } else {
            if (res?.message?.email) {
                setError('email', { message: res?.message?.email });
                
            }else if(res?.message?.password){
                setError('password', { message: res?.message?.password });
            }
            
        }
    }
    return (
        <>
            <section className='Profile'>
                <div className='img-div'>
                    <img className='prof-top-bannimg' src={process.env.PUBLIC_URL + '/assest/image/Profile/profilebanner.png'} alt="Profile Banner" />
                </div>
                <div className='container'>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="1" activeKey={activeTab} onSelect={(k)=> setActiveTab(k)}>
                        <Row>
                            <Col lg={4} md={4}>
                                <div className='profilecontainer'>
                                    <div className='top-contt'>
                                        <p>Hello</p>
                                        <h1>{data?.first_name}</h1>
                                        <h1>{data?.last_name}</h1>
                                    </div>
                                    <Nav variant="pills" className="flex-column">
                                        <Nav.Item>
                                            <Nav.Link eventKey="1"><img src={process.env.PUBLIC_URL + "/assest/image/Careers/Personaldetails.png"} className='personalicon' />Personal Details</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link as={Link} to={"/myOrders"} eventKey="2"><img src={process.env.PUBLIC_URL + "/assest/image/Careers/Personaldetails.png"} className='personalicon' />My Orders</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="3"><img src={process.env.PUBLIC_URL + "/assest/image/Careers/token.png"} className='personalicon' />Loyalty Points</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="4"><img src={process.env.PUBLIC_URL + "/assest/image/Careers/Personaldetails.png"} className='personalicon' />Manage Address</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="5"><img src={process.env.PUBLIC_URL + "/assest/image/Careers/Personaldetails.png"} className='personalicon' />Update Email Address</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="6"><img src={process.env.PUBLIC_URL + "/assest/image/Careers/lock.png"} className='personalicon' />Update Password</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </div>
                            </Col>
                            <Col md={8} lg={8}>
                                <Tab.Content>
                                    <Tab.Pane eventKey="1">
                                        <div className='row'>
                                            <div className='col-lg-6'>
                                                <div className='row personalDetails'>
                                                    <div className='col-lg-4'>
                                                        <p className='detailshead'>Name:</p>
                                                    </div>
                                                    <div className='col-lg-8'>
                                                        <p>{data?.first_name} {data?.last_name}</p>
                                                    </div>
                                                </div>
                                                <div className='row personalDetails'>
                                                    <div className='col-lg-4'>
                                                        <p className='detailshead'>Contact: </p>
                                                    </div>
                                                    <div className='col-lg-8'>
                                                        <p>{data?.contact_no}</p>
                                                    </div>
                                                </div>
                                                <div className='row personalDetails'>
                                                    <div className='col-lg-4'>
                                                        <p className='detailshead'>Email: </p>
                                                    </div>
                                                    <div className='col-lg-8'>
                                                        <p>{data?.email}</p>
                                                    </div>
                                                </div>
                                                <div className='editProfile'>
                                                    <Common_Button className="editbtn" text={"Edit Profile"} onClick={() => setEditshow(true)} />
                                                </div>
                                            </div>
                                        </div>
                                    </Tab.Pane>

                                    <Tab.Pane eventKey="3">
                                        <div className='loyalitypoint'>
                                            <div className='loyalityhead'>
                                                <h3>Loyalty Points</h3>
                                                <p>Earn loyalty points for every rupee you spend with us.</p>
                                            </div>
                                            <div className='loyalitycardmain'>
                                                <div className='row loyalityrow'>
                                                    {loyaltyCards.map((card, index) => (
                                                        <div className='col-lg-4 col-md-6' key={index}>
                                                            <Card className="loyalitycard">
                                                                <div className='clip'>
                                                                    <img src={process.env.PUBLIC_URL + card.imgSrc} className='mittalImage' alt="Loyalty Card" />
                                                                </div>
                                                                <Card.Body>
                                                                    <div className='loyalitycardbody'>
                                                                        <p className='text-center mb-1'>{card.points}PT</p>
                                                                        <ProgressBar now={card.progress} label={`${card.progress}%`} />
                                                                    </div>
                                                                    <p className='text-center mb-1'>{card.points} Point</p>
                                                                    <p className={`invalidtxt mb-0 ${card.classname}`}>{card.valid}</p>
                                                                </Card.Body>
                                                            </Card>
                                                        </div>
                                                    ))}
                                                </div>

                                                <div className='recentTransaction'>
                                                    <h3>RECENT 10 TRANSACTIONS</h3>
                                                    <p>There is no transaction history</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="4">
                                        <div className='ManageAddress'>
                                            <div className='row manageaddressrow'>
                                                {addressdata?.map((value, index) => (
                                                    <>
                                                        <div className='col-lg-6' key={index}>
                                                            <div className='row manageaddresscard'>
                                                                <div className='col-lg-3 col-md-3 col-sm-3 col-3'>
                                                                    <div className='loyalimgsection'>
                                                                        <img src={process.env.PUBLIC_URL + "/assest/image/Profile/map.png"} className='' alt="Map Icon" />
                                                                        <FontAwesomeIcon icon="fa-solid fa-location-dot" className="locationdot" />
                                                                    </div>
                                                                </div>
                                                                <div className='col-lg-7 col-md-7 col-sm-7 col-7'>
                                                                    <div className='loyaladdress'>
                                                                        <h3>{value?.address_type}</h3>
                                                                        <p>{value?.f_name} {value?.l_name}</p>
                                                                        <span>{value?.company}, {value?.address}, {value?.city?.name}, {value?.state?.name}, {value?.country?.name} - {value?.pincode?.name}</span>
                                                                    </div>
                                                                </div>
                                                                <div className='col-lg-2 col-md-2 col-sm-2 col-2 text-end'>
                                                                    {/* <Form>
                                                                        <div className="mb-3">
                                                                            <Form.Check name="name" type="radio" id={`radio-${index}`} />
                                                                        </div>
                                                                    </Form> */}
                                                                </div>
                                                                <Row>
                                                                    <Col xl={6}>
                                                                        <div className=''>
                                                                            <FontAwesomeIcon icon="fa-solid fa-trash" onClick={() => handleDelete(value?.id)} />
                                                                        </div>
                                                                    </Col>
                                                                    <Col xl={6}>
                                                                        <div className='editsec'>
                                                                            <p onClick={() => handleEditClick(value?.id)}>Edit</p>
                                                                        </div>

                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </div>

                                                    </>
                                                ))}

                                                <div className='addAddressbtn'>
                                                    <Button className='addNewaddress' onClick={() => setNewaddress(true)}>Add New Address</Button>
                                                    {/* <Button className='deleteaddress' onClick={() => setNewaddress(true)}>Delete Address</Button> */}
                                                </div>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="5">
                                        <div className='changeEmail'>
                                            <Form onSubmit={handleSubmit(onSubmit)}>
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                    <Form.Label>New Email Address</Form.Label>
                                                    <Form.Control
                                                    type="text"
                                                    name="email"
                                                    placeholder="Email"
                                                    {...register("email", {
                                                        required: "Credentials required",
                                                        pattern: {
                                                            value: RegxExpression.email,
                                                            message: "Invalid email address",
                                                        },
                                                    })}
                                                    className={classNames("", {
                                                        "is-invalid": errors?.email,
                                                        // "is-valid": getValues("email_or_contact"),
                                                    })}

                                                  
                                                />
                                                {errors?.email && (
                                                    <sup className="text-danger">{errors?.email?.message}</sup>
                                                )}
                                                </Form.Group>
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                    <Form.Label>Enter Current Password </Form.Label>
                                                    <Form.Group className="mb-3" controlId="">
                                                    <div className="password-container">
                                                        <Form.Control
                                                          
                                                            placeholder="Enter Current Password*"
                                                            name="password"
                                                            {...register("password", {
                                                                required: "Password is required"
                                                            })}
                                                            className={classNames("", {
                                                                "is-invalid": errors?.password,
                                                                "is-valid": getValues("password"),
                                                            })}
                                                        />
                                                       
                                                        {/* <span className="password-icon" onClick={togglePasswordVisibility}>👁️</span> */}
                                                    </div>
                                                </Form.Group>
                                                {errors?.password && (
                                                    <sup className="text-danger">{errors?.password?.message}</sup>
                                                )}
                                                </Form.Group>

                                                <Button className='updatebtn'type='submit' >Update</Button>
                                            </Form>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="6">
                                        <div className='changeEmail'>
                                        <ChangePassword />
                                        </div>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>

                    <Editproflemodal setData={setData} show={editshow} getAllData={getAllData} onHide={() => setEditshow(false)} setEditshow={setEditshow} />
                    {/* <AddNewaddress show={newAddress}  id={editAddress} onHide={() => setNewaddress(false)} /> */}
                    <AddNewaddress getAllData={getAllData} show={newAddress} profile={"profile"}onHide={() => setNewaddress(false)} />
                    <EditNewaddress show={newEditAddress} id={editAddress} getAllData={getAllData} onHide={() => setEditNewaddress(false)} />
                    <SuccessfullyModal show={emailModal} onHide={() => setEmailmodal(false)} text={"Email Updated Successfully"} />
                    <SuccessfullyModal show={passwordModal} onHide={() => setPasswordModal(false)} text={"Password Updated Successfully"} />
                    <Delete_Modal text={"Are you sure you want to delete address?"} getAllData={getAllData} deleteID={deleteID} show={modalDeleteShow} onHide={() => setDeleteModalShow(false)} />
                </div>
            </section >
        </>
    )
}

export default Profile;
