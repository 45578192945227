import {
  postData,
  getData
} from "../../api";

export const personalDetails = async (data) => {
  try {
    const res = await postData(`/seller/personal-details`, data);

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getpersonalDetails = async (id) => {
  try {
    const res = await getData(`/seller/personal-details/${id}`);

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const sellerInformation = async (data) => {
  try {
    const res = await postData(`/seller/seller-information`, data);

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getsellerInformation = async (id) => {
  try {
    const res = await getData(`/seller/seller-information/${id}`);

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const selleraddress = async (data) => {
  try {
    const res = await postData(`/seller/seller-address`, data);

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getselleraddress = async (id) => {
  try {
    const res = await getData(`/seller/seller-address/${id}`);

    return res;
  } catch (error) {
    console.log(error);
  }
};
