import React, { useEffect, useRef, useState, useContext } from 'react'
import OrderSummary from './OrderSummary/OrderSummary'
import ProductOrder from './ProductOrder/ProductOrder'
import { Button, Col, Container, Row } from 'react-bootstrap'
import Breadcrumbs from '../Common-Component/Breadcrumbs/Breadcrumbs'
import { AddToCart, getCart, deleteCart, logCartData } from "../../utils/apis/addToCart/addToCart";
import { useParams } from 'react-router-dom';
import parse from "html-react-parser";
import { Context } from "../../utils/context";
import Cookies from "js-cookie";
import { Link, useNavigate } from "react-router-dom";
import useCookie from './ProductOrder/cookiesStore';
import EmptyAnimation from '../EmptyAnimation/EmptyAnimation'
const MyCart = () => {
    const items = [
        { id: 1, text: "HOME", link: "/home" },
        { id: 1, text: "MY CART" },
    ]
    const cartCookie = useCookie('coinz_kart_cart');
    const [cart, setCart] = useState();
    const [deletecart, setDeletecart] = useState({});
    const { id } = useParams();
    const navigate = useNavigate();
    const { IMG_URL, signin,isCookieAccepted, setCartCount, setTrigger, updateCartCount, setcartwithoutData, cartwithoutData } = useContext(Context);
    const cartGet = async () => {
        setDeletecart()
        const res = await getCart();
        if (res?.success) {
            setCart(res);
            updateCartCount();
        } else {
            console.error('Error fetching  data:', res?.error);
        }
    }

    const withoutCart = async () => {
  
        if (Cookies.get('coinz_kart_cart')) {
    
            if (JSON.parse(Cookies.get('coinz_kart_cart')).length < 0) {
                navigate('/home');
            }
            await setCart(JSON.parse(Cookies.get('coinz_kart_cart')));
            updateCartCount();
        }
    }

   

    useEffect(() => {
        if (signin) {
            cartGet();
        } else {
            withoutCart();
        }
    }, [cartCookie, signin]);



    const handleClick = async (id, size_id) => {
        const res = await deleteCart(deletecart);
        if (res?.success) {
            cartGet();
        } else {

        }
        updateCartCount();
    };
   
    useEffect(() => {
        if (deletecart !== undefined) {
            handleClick(deletecart);
        }
    }, [deletecart]);


    const [totalPrice, setTotalPrice] = useState(0);
    const [total, setTotal] = useState(0);
    const [totalTax, setTotalTax] = useState(0);

    // Calculate totalPrice and totalTax whenever data or its dependencies change
    const calculateTotals = () => {
        let calculatedTotalPrice = 0;
        let calculatedTotalTax = 0;

        cart?.forEach(val => {
            if (val?.sizes?.length > 0) {
                // Calculate total price and tax based on sizes and quantities
                val?.sizes.forEach(val1 => {
                    if (val1 && val1.quantity !== 0) {
                        calculatedTotalPrice += parseFloat(val.price) * parseFloat(val1.quantity);
                        calculatedTotalTax += (parseFloat(val.tax_percent) * (parseFloat(val.price) * parseFloat(val1.quantity))) / 100;
                    }
                });
            } else {
                // When sizes array is empty, add the base price and tax once per item
                calculatedTotalPrice += parseFloat(val.price)* parseFloat(val.quantity);  // Add the base price
                calculatedTotalTax += (parseFloat(val.tax_percent) *( parseFloat(val.price)) / 100)* parseFloat(val.quantity);  // Add the tax
            }
        });

        // Format the totals to the desired decimal places
        const formattedTotalPrice = parseFloat(calculatedTotalPrice.toFixed(8));
        const formattedTotalTax = parseFloat(calculatedTotalTax.toFixed(8));

        // Set the formatted totals into state or wherever needed
        setTotalPrice(formattedTotalPrice);
        setTotalTax(formattedTotalTax);
        setTotal(formattedTotalPrice + formattedTotalTax)
    };


    useEffect(() => {
        if (!signin) {
            calculateTotals();
        }
    }, [cart]);


    return (
        <section className='my-cartmain'>
            {signin ? (
                cart?.data?.length > 0 ? (
                    <Container>
                        <Breadcrumbs items={items} />
                        <div className='my-cart-titleee'>
                            <h3>My Cart</h3>
                        </div>
                        <Row>
                            <Col xl={9} lg={9} md={12} sm={12} xs={12}>
                                <ProductOrder
                                    data={cart}
                                    setDeletecart={setDeletecart}
                                    deletecart={deletecart}
                                    totalPrice={totalPrice}
                                    totalTax={totalTax}
                                    setTotalPrice={setTotalPrice}
                                    setTotalTax={setTotalTax}
                                    cartGet={cartGet}
                                    cart={cart}
                                    setCart={setCart}
                                    withoutCart={withoutCart}
                                    calculateTotals={calculateTotals}
                                />
                            </Col>
                            <Col xl={3} lg={3} md={12} sm={12} xs={12}>
                                <OrderSummary
                                    data={cart?.message}
                                    totalTax={totalTax}
                                    totalPrice={totalPrice}
                                    total={total}
                                    calculateTotals={calculateTotals}
                                />
                            </Col>
                        </Row>
                    </Container>
                ) : (
                    <Container>
                        <Breadcrumbs items={items} />
                        <div className='my-cart-titleee'>
                            <h3>My Cart</h3>
                        </div>
                        <Col xl={9} lg={9} md={12} sm={12} xs={12}>
                            <EmptyAnimation />
                        </Col>
                    </Container>
                )
            ) : (
                cart?.length > 0 ? (
                    <Container>
                        <Breadcrumbs items={items} />
                        <div className='my-cart-titleee'>
                            <h3>My Cart</h3>
                        </div>
                        <Row>
                            <Col xl={9} lg={9} md={12} sm={12} xs={12}>
                                <ProductOrder
                                    data={cart}
                                    setDeletecart={setDeletecart}
                                    deletecart={deletecart}
                                    totalPrice={totalPrice}
                                    totalTax={totalTax}
                                    setTotalPrice={setTotalPrice}
                                    setTotalTax={setTotalTax}
                                />
                            </Col>
                            <Col xl={3} lg={3} md={12} sm={12} xs={12}>
                                <OrderSummary
                                    data={cart?.message}
                                    totalTax={totalTax}
                                    totalPrice={totalPrice}
                                    total={total}
                                />
                            </Col>
                        </Row>
                    </Container>
                ) : (
                    <Container>
                        <Breadcrumbs items={items} />
                        <div className='my-cart-titleee'>
                            <h3>My Cart</h3>
                        </div>
                        <Col xl={9} lg={9} md={12} sm={12} xs={12}>
                            <EmptyAnimation />
                            <div className='text-center'>
                                <Link to='/customizable-product'><Button className='continue-shopiing-btnnnnn mt-5 mb-5'>Continue Shopping</Button></Link>
                            </div>
                        </Col>
                    </Container>
                )
            )}


        </section>
    )
}

export default MyCart