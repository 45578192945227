import React, { useContext, useEffect, useState } from "react";
import ProductCard from '../Common-Component/ProductCard/ProductCard'
import { Col, Row,Container } from 'react-bootstrap'
import Breadcrumbs from '../Common-Component/Breadcrumbs/Breadcrumbs'
import "./Wishlist.css";

import EmptyAnimation from '../EmptyAnimation/EmptyAnimation'

import { ShimmerPostItem } from "react-shimmer-effects";
import { getWishlist, addWishlist } from "../../utils/apis/Wishlist/Wishlist";
import { AddToCart } from "../../utils/apis/addToCart/addToCart";
import { Context } from "../../utils/context";
const Wishlist = () => {
    const orders = [
        {
            id: 1,
            image: process.env.PUBLIC_URL + "/assest/image/Profile/productImg.png",
            image2: process.env.PUBLIC_URL + "/assest/image/Profile/productImg1.png",
            name: 'Nike Dri-FIT Legend Tee - Men\'s',
            price: '0000'
        },
        {
            id: 2,
            image: process.env.PUBLIC_URL + "/assest/image/Profile/productImg.png",
            image2: process.env.PUBLIC_URL + "/assest/image/Profile/productImg1.png",
            name: 'White Coffee Mug',
            price: '0000'
        },
        {
            id: 3,
            image: process.env.PUBLIC_URL + "/assest/image/Profile/productImg.png",
            image2: process.env.PUBLIC_URL + "/assest/image/Profile/productImg1.png",
            name: 'Lather Cover Executive Diary and 2 more items',
            price: '0000'
        },
        {
            id: 4,
            image: process.env.PUBLIC_URL + "/assest/image/Profile/productImg.png",
            image2: process.env.PUBLIC_URL + "/assest/image/Profile/productImg1.png",
            name: 'Nike Dri-FIT Legend Tee - Men\'s',
            price: '0000'
        }
    ];

    const items = [
        { id: 1, text: "PEOPLE" },
        { id: 1, text: "ALL ORDERS" },
    ]

    const [wishlistProducts, setWishlistProduct] = useState([]);



    // const navigate = useNavigate();
    const { IMG_URL, shimmerLoader, setShimmerLoader, wishlistData, wishlistContext } = useContext(Context);
  
    const handleWishCart = async () => {
      await Promise.all(wishlistData?.map(async (value) => {
       
        await AddToCart({ product_id: value?.product_id, variant_id: value?.variant_id });
        await addWishlist(value?.product_id);
      }));
  
    //   setModalShow(true);
    //   setTimeout(() => {
    //     setModalShow(false);
    //     navigate('/product-cart');
    //   }, 3000);
    }
  
    const getWishlist = async () => {
      setShimmerLoader(true);
     
      await setWishlistProduct(wishlistData);
  
    
    setShimmerLoader(false);
    }
  
    useEffect(() => {
      setWishlistProduct(wishlistData);
    }, [wishlistData]);


 

    return (
        <>
            <section className='Wishlist'>
            {wishlistData.length > 0 ? (
                <div className='container'>
                    <Breadcrumbs items={items} />
                    <div className='wishlistHead'>
                        <h3>Wishlist <span><sub>({wishlistData?.length})</sub></span></h3>
                    </div>

                    
                    <Row className='wishlistMainrow' >
                        {wishlistData?.map(order => (
                            <Col key={order.id} md={4} lg={3} xs={6} className="my-3">
                                <ProductCard wishlistData={order} />
                            </Col>
                        ))}
                    </Row>
                    
                        
                      
                </div>
                  ) : (
                    <div className="no-data-content-holder">
                        <div className="littie-holder">
                        <EmptyAnimation />
                        </div>

                        <p className="no-data-found-text"> No product avaliable </p>
                    </div>

                  )}
            </section>
        </>
    )
}

export default Wishlist