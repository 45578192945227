import React, { useEffect, useRef, useState ,useContext} from "react";
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import Cookies from "js-cookie";
import { postAddress, getAddress, deleteAddress, getSingleAddress } from "../../../../utils/apis/orderProcess/orderProcess";
import { Context } from "../../../../utils/context";
import { useNavigate } from "react-router-dom";
const Delete_Modal = ({onHide, show , text,deleteID,getAllData,  ...props}) => {
    const navigate = useNavigate();

    const handleYesClick = async () => {
        const res = await deleteAddress(deleteID);
        if (res?.success) {
            getAllData();
            onHide(); // Hide the modal
        } else {
            console.error('Error fetching  data:', res?.error);
        }
       
    };
    return (
        <>
            <Modal
                // {...props}
                show={show}
                onHide={onHide}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="logOutmodal"
            >

                <Modal.Body>
                    <section className='LogoutMain'>
                        <div className='logoutinfo text-center'>
                            <p>{text}</p>
                        </div>

                        <div className='yesNobtnMain'>
                            {/* <Link to={"/login"}> */}
                                <Button className='btn yesNobtn' 
                                 onClick={handleYesClick}
                                 >Yes </Button>
                            {/* </Link> */}
                            <Button className='btn yesNobtn' 
                            onClick={onHide}
                            >No</Button>
                        </div>
                    </section>
                </Modal.Body>

            </Modal>
        </>
    )
}

export default Delete_Modal