import React, { useEffect, useRef, useState, useContext } from 'react'
import "./Profile.css";
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import { Button, ProgressBar } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Common_Button from '../Common-Component/Common_Button/Common_Button';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import PasswordChecklist from "react-password-checklist";
import classNames from "classnames";
import { ChangePassword } from "../../utils/apis/myAccount/myAccount";
import SuccessfullyModal from '../Common-Component/Commonmodal/SuccessfullyModal/SuccessfullyModal';
const Profile = () => {
  const [editshow, setEditshow] = useState(false);
  const [newAddress, setNewaddress] = useState(false);
  const [newEditAddress, setEditNewaddress] = useState(false);
  const [emailModal, setEmailmodal] = useState(false);
  const [passwordModal, setPasswordModal] = useState(false);
  const [activeTab, setActiveTab] = useState("1");


  const passwordClose = () => {
    setPasswordModal(true);
    setTimeout(() => {
      setPasswordModal(false);
      setActiveTab('1')
    }, 3000)
  }


  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset,
    trigger,
  } = useForm();


  const onSubmit = async (data) => {
    if(password !== passwordCurrent){
      if (password === passwordAgain) {
        const res = await ChangePassword(data);
        if (res?.success) {
          reset()
          // setModalShow(true);
          // hideModalWithDelay();
          passwordClose()
        } else {
          if (res?.message?.current_password) {
            setError('current_password', { message: res?.message?.current_password });
          }
          if (res?.message?.errors?.current_password) {
            setError('current_password', { message: res?.message?.errors?.current_password?.msg });
          }
        }
      } else {
        setError('current_password', { message: "Password must match" });
      }
    }else{
      setError('current_password', { message: "Current Password and New Password Not Same" });
    }
   

  }



  const [showPasswordCurrent, setShowPasswordCurrent] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordOne, setShowPasswordOne] = useState(false);
  const [iconOne, setIconOne] = useState(faEyeSlash);
  const [iconTwo, setIconTwo] = useState(faEyeSlash);
  const [iconCurrent, setIconCurrent] = useState(faEyeSlash);


  const togglePasswordVisibility = (field) => {
    if (field === "password") {
      setShowPassword(!showPassword);
      setIconOne(showPassword ? faEyeSlash : faEye);
    } else if (field === "reenterPassword") {
      setShowPasswordOne(!showPasswordOne);
      setIconTwo(showPasswordOne ? faEyeSlash : faEye);
    } else if (field === "current_password") {
      setShowPasswordCurrent(!showPasswordCurrent);
      setIconCurrent(showPasswordCurrent ? faEyeSlash : faEye);
    }
  };
  const [password, setPassword] = useState("")
  const [passwordCurrent, setPasswordCurrent] = useState("")
  const [passwordAgain, setPasswordAgain] = useState("")
  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label>Current Password</Form.Label>
          <div className='formfieldMain'>
            <Form.Control
              type={showPasswordCurrent ? "text" : "password"}
              placeholder="Enter Current Password*"
              {...register("current_password", {
                required: "current_password is required",
              })}
              onChange={e => setPasswordCurrent(e.target.value)}
            // {...register("current_password")}
            />
            <FontAwesomeIcon
              className='eyeIcon'
              icon={iconCurrent}

              onClick={() => togglePasswordVisibility("current_password")}
            />
            {errors?.current_password && (
              <sup className="text-danger">
                {errors?.current_password?.message}
              </sup>
            )}
            {/* <FontAwesomeIcon icon="fa-solid fa-eye" className='eyeIcon' /> */}
          </div>
        </Form.Group>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label>Enter New Password</Form.Label>
          <div className='formfieldMain'>
            <Form.Control
              type={showPassword ? "text" : "password"}
              placeholder="Enter Password*"

              {...register("new_password", {
                required: "New Password is required",
                minLength: {
                  value: 8,
                  message: "Password must be at least 8 characters long",
                },
                pattern: {
                  value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                  message: "Password must contain at least one uppercase letter, one lowercase letter, and one number",
                }
              })}
              onChange={e => setPassword(e.target.value)}
            />
             {/* {errors?.new_password && (
              <sup className="text-danger">
                {errors?.new_password?.message}
              </sup>
            )} */}
            <FontAwesomeIcon
              icon={iconOne}
              className='eyeIcon'
              onClick={() => togglePasswordVisibility("password")}
            />
          </div>
        </Form.Group>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label>Confirm New Password</Form.Label>
          <div className='formfieldMain'>
            <Form.Control
              type={showPasswordOne ? "text" : "password"}
              placeholder="Re-Enter Password*"

              {...register("re_password", {
                required: "Re-Password is required",
              })}
              onChange={e => setPasswordAgain(e.target.value)}
            />
            
            <FontAwesomeIcon
              icon={iconTwo}
              className='eyeIcon'
              onClick={() =>
                togglePasswordVisibility("reenterPassword")
              }
            />
            {password && (
              <PasswordChecklist
                rules={[
                  "minLength",
                  "specialChar",
                  "number",
                  "capital",
                  "match",
                ]}
                minLength={8}
                value={password}
                valueAgain={passwordAgain}
                onChange={(isValid) => { }}
                style={{
                  fontSize: "10px",
                  padding: "4px",
                  margin: "0",
                }}
              />
            )}

          </div>
        </Form.Group>

        <Button className='updatebtn' type='submit'>Update</Button>
      </Form>
      <SuccessfullyModal show={passwordModal} onHide={() => setPasswordModal(false)} text={"Password Updated Successfully"} />
    </>
  )
}

export default Profile;
