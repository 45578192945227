import React from "react";
import "./Sesonal_offers.css";
import Modal from "react-bootstrap/Modal";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
const Sesonal_offers = (props) => {
  return (
    <>
      <Modal
        {...props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="Sesonal_offers_modal logOutmodal mySwiper"
        // backdrop="static"
      >
        <Modal.Body>
          <section className="LogoutMain">
            <div className="succussFlex">
              <div className="slider-img-holder">
                <Swiper
                  slidesPerView={"auto"}
                  className="Sesonal_offers_swiper"
                  modules={[Autoplay, Pagination]}
                  breakpoints={{
                    0: {
                      slidesPerView: 1,
                      spaceBetween: 10,
                    },
                    370: {
                      slidesPerView: 1,
                      spaceBetween: 10,
                    },
                    485: {
                      slidesPerView: 2,
                      spaceBetween: 10,
                    },
                    575: {
                      slidesPerView: 2,
                      spaceBetween: 30,
                    },

                    768: {
                      slidesPerView: 2,
                      spaceBetween: 20,
                    },
                    992: {
                      slidesPerView: 2,
                      spaceBetween: 30,
                    },
                    1024: {
                      slidesPerView: 2,
                      spaceBetween: 30,
                    },
                    1200: {
                      slidesPerView: 2,
                      spaceBetween: 30,
                    },
                    1440: {
                      slidesPerView: 2,
                      spaceBetween: 30,
                    },
                    2000: {
                      slidesPerView: 2,
                      spaceBetween: 20,
                    },
                  }}
                  pagination={{ clickable: true }}
              
                >
                  <SwiperSlide>
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assest/image/Offer/OfferImg1.png"
                      }
                      className="offerImg"
                    />
                  </SwiperSlide>

                  <SwiperSlide>
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assest/image/Offer/OfferImg1.png"
                      }
                      className="offerImg"
                    />
                  </SwiperSlide>

                  <SwiperSlide>
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assest/image/Offer/OfferImg1.png"
                      }
                      className="offerImg"
                    />
                  </SwiperSlide>

                  <SwiperSlide>
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assest/image/Offer/OfferImg1.png"
                      }
                      className="offerImg"
                    />
                  </SwiperSlide>

                  <SwiperSlide>
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assest/image/Offer/OfferImg1.png"
                      }
                      className="offerImg"
                    />
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
          </section>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Sesonal_offers;
