import React, { useEffect, useRef, useState ,useContext} from "react";
import "./LogoutModal.css";
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import Cookies from "js-cookie";

import { Context } from "../../../../utils/context";
import { useNavigate } from "react-router-dom";
const LogoutModal = ({ show, onHide, setSignin, updateCartCount, text }) => {
    const navigate = useNavigate();

    const handleYesClick = async () => {
        // Log out user and navigate
        await Cookies.remove("coinz_kart_security");
         setSignin(false);
        await updateCartCount();
        navigate("/"); // Navigate after logout
        onHide(); // Hide the modal
    };
    return (
        <>
            <Modal
                // {...props}
                show={show}
                onHide={onHide}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="logOutmodal"
            >

                <Modal.Body>
                    <section className='LogoutMain'>
                        <div className='logoutinfo text-center'>
                            <p>{text}</p>
                        </div>

                        <div className='yesNobtnMain'>
                            {/* <Link to={"/login"}> */}
                                <Button className='btn yesNobtn'  onClick={handleYesClick}>Yes </Button>
                            {/* </Link> */}
                            <Button className='btn yesNobtn' onClick={onHide}>No</Button>
                        </div>
                    </section>
                </Modal.Body>

            </Modal>
        </>
    )
}

export default LogoutModal