import React, { useState, useEffect, useContext } from "react";
import { Container, Row, Col, Nav, Tab, NavDropdown, Button } from 'react-bootstrap';
import './MyOrders.css';
import Breadcrumbs from "../Common-Component/Breadcrumbs/Breadcrumbs";
import ProductCard from '../Common-Component/ProductCard/ProductCard';
import { faXmark, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OrdersFilter from '../Common-Component/Offcanvas/OrdersFilter/OrdersFilter';
import Cookies from "js-cookie";
import { useForm, Controller } from "react-hook-form";
import classNames from "classnames";
import { RegxExpression } from "../../utils/common";
import { getOrder } from "../../utils/apis/orderProcess/orderProcess";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { Context } from "../../utils/context";
import { Link, useNavigate } from "react-router-dom";
import { Country, State, City, Pincode, OrderStatus } from "../../utils/apis/master/Master";
import {
    allProductReviewGet,
    productReviewGet,
    productReviewPost,
  } from "../../utils/apis/Product/Product";
import EmptyAnimation from "../EmptyAnimation/EmptyAnimation";
const orders = [
    {
        id: 1,
        image: process.env.PUBLIC_URL + "/assest/image/Profile/productImg.png",
        image2: process.env.PUBLIC_URL + "/assest/image/Profile/productImg1.png",
        name: 'Nike Dri-FIT Legend Tee - Men\'s',
        date: '3.JUN.24',
        status: 'Ordered',
        price: '0000'
    },
    {
        id: 2,
        image: process.env.PUBLIC_URL + "/assest/image/Profile/productImg.png",
        image2: process.env.PUBLIC_URL + "/assest/image/Profile/productImg1.png",
        name: 'White Coffee Mug',
        date: '2.JUN.24',
        status: 'Ordered',
        price: '0000'
    },
    {
        id: 3,
        image: process.env.PUBLIC_URL + "/assest/image/Profile/productImg.png",
        image2: process.env.PUBLIC_URL + "/assest/image/Profile/productImg1.png",
        name: 'Lather Cover Executive Diary and 2 more items',
        date: '1.JUN.24',
        status: 'Cancelled',
        price: '0000'
    },
    {
        id: 4,
        image: process.env.PUBLIC_URL + "/assest/image/Profile/productImg.png",
        image2: process.env.PUBLIC_URL + "/assest/image/Profile/productImg1.png",
        name: 'Nike Dri-FIT Legend Tee - Men\'s',
        date: '25.MAY.24',
        status: 'Returned',
        price: '0000'
    },
    {
        id: 5,
        image: process.env.PUBLIC_URL + "/assest/image/Profile/productImg.png",
        image2: process.env.PUBLIC_URL + "/assest/image/Profile/productImg1.png",
        name: 'Blue T-shirt',
        date: '20.MAY.24',
        status: 'Shipped',
        price: '0000'
    },
    {
        id: 6,
        image: process.env.PUBLIC_URL + "/assest/image/Profile/productImg.png",
        image2: process.env.PUBLIC_URL + "/assest/image/Profile/productImg1.png",
        name: 'Red Mug',
        date: '19.MAY.24',
        status: 'Cancelled',
        price: '0000'
    },
    {
        id: 7,
        image: process.env.PUBLIC_URL + "/assest/image/Profile/productImg.png",
        image2: process.env.PUBLIC_URL + "/assest/image/Profile/productImg1.png",
        name: 'Green Diary',
        date: '18.MAY.24',
        status: 'Shipped',
        price: '0000'
    },
    {
        id: 8,
        image: process.env.PUBLIC_URL + "/assest/image/Profile/productImg.png",
        image2: process.env.PUBLIC_URL + "/assest/image/Profile/productImg1.png",
        name: 'Blue T-shirt',
        date: '17.MAY.24',
        status: 'Delivered',
        price: '0000'
    },
    {
        id: 9,
        image: process.env.PUBLIC_URL + "/assest/image/Profile/productImg.png",
        image2: process.env.PUBLIC_URL + "/assest/image/Profile/productImg1.png",
        name: 'Red Mug',
        date: '16.MAY.24',
        status: 'Returned',
        price: '0000'
    },
    {
        id: 10,
        image: process.env.PUBLIC_URL + "/assest/image/Profile/productImg.png",
        image2: process.env.PUBLIC_URL + "/assest/image/Profile/productImg1.png",
        name: 'Red Mug',
        date: '16.MAY.24',
        status: 'Delivered',
        price: '0000'
    },
    {
        id: 11,
        image: process.env.PUBLIC_URL + "/assest/image/Profile/productImg.png",
        image2: process.env.PUBLIC_URL + "/assest/image/Profile/productImg1.png",
        name: 'Red Mug',
        date: '16.MAY.24',
        status: 'Returned',
        price: '0000'
    }
];



const filterOptions = [
    { eventKey: '1', label: 'Shipped' },
    { eventKey: '2', label: 'Delivered' },
    { eventKey: '3', label: 'Cancelled' },
    { eventKey: '4', label: 'Returned' },
];

const timeOptions = [
    { eventKey: '11', label: 'Last 30 Days', value: '30day' },
    { eventKey: '12', label: 'Last 6 Mths.', value: '6months' },
    { eventKey: '13', label: '2023', value: '2023' },
    { eventKey: '14', label: '2022', value: '2022' },
    { eventKey: '15', label: '2020', value: '2020' },
    { eventKey: '16', label: 'Older', value: 'Older' },
];


const MyOrders = () => {
    const [activeTab, setActiveTab] = useState('All Orders');
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [rating, setRating] = useState(0);
    const [selectedOrderStatus, setSelectedOrderStatus] = useState([]);
    const [selectedOrderTime, setSelectedOrderTime] = useState([]);
    const [error, setError] = useState('');
    const handleSelectkey = (key, type) => {
        if (type === 'status') {
            setSelectedOrderStatus((prevKeys) =>
                prevKeys.includes(key) ? prevKeys.filter(k => k !== key) : [...prevKeys, key]
            );
        } else if (type === 'time') {
            setSelectedOrderTime((prevKeys) =>
                prevKeys.includes(key) ? prevKeys.filter(k => k !== key) : [...prevKeys, key]
            );
        }
    };


    const handleCancel = () => {
        setDropdownVisible(false);
        setSelectedOrderStatus([]);
        setSelectedOrderTime([]);
    };

    const handleSave = async () => {
        if (selectedOrderTime.length === 0) {
            // Display an error message or alert
            setError('Please select at least one order time.')
            console.error('Please select at least one order time.');
            return;
        }
        const res = await getOrder(selectedOrderTime);
        if (res?.success) {
            setDropdownVisible(false);
            setOrderData(res?.data);
        

        } else {
            console.error('Error fetching  data:', res?.error);
        }
       
        // Perform further actions with selectedOrderStatus and selectedOrderTime
    };


    const handleRating = (rate) => {
        setRating(rate);
    };

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleSelects = (k) => {
        if (k === "allOrders") {
            setActiveTab('All Orders');
            setActiveCount(orderData?.length);
        } else {
            const selectedStatus = orderStatus.find(status => status.id.toString() === k);
            const filteredOrders = orderData?.filter(order => order?.order_status?.id === selectedStatus?.id).length;
            setActiveTab(selectedStatus?.name);
            setActiveCount(filteredOrders);
        }
        // handleSave();
    };

    // const handleSelect = (key) => {
    //     setActiveKeys(prevKeys =>
    //         prevKeys.includes(key) ? prevKeys.filter(activeKey => activeKey !== key) : [...prevKeys, key]
    //     );
    // };

    const [orderStatus, setOrderStatus] = useState();
    const orderStatusget = async () => {

        const res = await OrderStatus();
        if (res?.success) {
            setOrderStatus(res?.data)
        } else {
            console.error('Error fetching  data:', res?.error);
        }
    }
    const [orderData, setOrderData] = useState()
    const orderGet = async () => {
        const res = await getOrder();
        if (res?.success) {
            setOrderData(res?.data);
         

        } else {
            console.error('Error fetching  data:', res?.error);
        }
    }

    const [activeCount, setActiveCount] = useState(orderData?.length);

    useEffect(() => {
        orderGet();
        orderStatusget();
    }, []);

    const [selectedName, setSelectedName] = useState("");
    const handleSaveValue = (name) => {
     
        setSelectedName(name);  
    };
    const items = [
        { id: 1, text: "PROFILE", link:"/profile" },
        { id: 2, text: selectedName || "ALL ORDERS" },
    ];

    const [reviewData, setreviewData] = useState()
    const reviewGet = async () => {
        const res = await productReviewGet(orderStatus?.product?.id);
        if (res?.success) {
            setreviewData(res?.data);
        } else {
            console.error('Error fetching  data:', res?.error);
        }
    }
    useEffect(() => {
        reviewGet();
    
    }, []);

    
   


    return (
        <section className='Myorders'>
            <Container className="my-4">
                <Row className='mb-4'>
                    <Col xl={6} md={6} xs={8}>
                        <Breadcrumbs items={items} />
                    </Col>
                    <Col xl={6} md={6} xs={4}>
                        <div className='sortingsec'>
                            <NavDropdown show={dropdownVisible}
                                onToggle={() => setDropdownVisible(!dropdownVisible)}
                                title={<p>FILTER <img src={`${process.env.PUBLIC_URL}/assest/image/Careers/bars.png`} className='barsImage' /></p>}
                                id="basic-nav-dropdown"
                            >
                                <div className='filterDropsec'>
                                 
                                    <div className='Filterdropdownmain'>
                                        <div className='orderstatus'>
                                            <p><b>ORDER TIME</b></p>
                                        </div>
                                        <Tab.Container id="left-tabs-example" activeKey={null}>
                                            <Nav variant="pills" className="flex-row filterDrop">
                                                {error && <div className="error-message" style={{ color: 'red' }}>{error}</div>}
                                                {timeOptions.map(option => (
                                                    <Nav.Item key={option.label}>
                                                        <Nav.Link
                                                            eventKey={option.label}
                                                            active={selectedOrderTime.includes(option.value)}
                                                            onClick={() => handleSelectkey(option.value, 'time')}
                                                        >
                                                            {option.label} <span><FontAwesomeIcon icon={selectedOrderTime.includes(option.value) ? faXmark : faPlus} className='x-mark' /></span>
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                ))}
                                            </Nav>
                                            <div className='btnMainsec'>
                                                <Button className='cancelbtn' onClick={handleCancel}>Cancel</Button>
                                                <Button className='savebtn' onClick={handleSave}>Save</Button>
                                            </div>
                                        </Tab.Container>
                                    </div>
                                </div>
                            </NavDropdown>
                        </div>

                        <div className='filtericonmain text-end'>
                            <p className='filter' onClick={handleShow}>FILTER <img src={`${process.env.PUBLIC_URL}/assest/image/Careers/bars.png`} className='barsImage' /></p>
                            {/* <FontAwesomeIcon icon="fa-solid fa-filter"/> */}
                        </div>
                    </Col>
                </Row>
                <div className='myorderstitle'>
                    <Row>
                        <Col xxl={2} xl={2} md={2} xs={2}>
                            <h2>{activeTab} ({orderData?.length})</h2>
                        </Col>
                        <Col xxl={2} xl={2} md={2} xs={2}>
                            <hr className="divider" />
                        </Col>
                        <Tab.Container id="left-tabs-example" defaultActiveKey="allOrders" onSelect={handleSelects}>
                            <Col xxl={8} xl={8} md={8} xs={8}>
                                <Nav variant="pills" className="flex-row orderRow">
                                    <Nav.Item key="allOrders">
                                        <Nav.Link eventKey="allOrders">All Orders ({orderData?.length})</Nav.Link>
                                    </Nav.Item>
                                    {orderStatus?.map((val, index) => (
                                        <Nav.Item key={index}>
                                            <Nav.Link eventKey={val?.id} onClick={() => handleSaveValue(val?.name)}>
                                                {val?.name} ({orderData?.filter(order => order?.order_status?.id === val?.id).length})
                                            </Nav.Link>
                                        </Nav.Item>
                                    ))}
                                </Nav>
                            </Col>
                            <Col md={12}>
                                <Tab.Content>
                                    <Tab.Pane eventKey="allOrders">
                                        <Row>
                                            {orderData?.length > 0 ? (
                                                orderData?.map((order, index) => (
                                                    <Col xl={3} md={4} xs={6} key={index}>
                                                        <ProductCard
                                                            orderStatus={order}
                                                            showRating={order?.order_status?.order_status === 'Delivered'}
                                                            handleRating={handleRating}
                                                            rating={rating}
                                                            review={order?.order_status?.id === 4 ? "Add Review" : ""}
                                                        />
                                                    </Col>
                                                ))
                                            ) : <EmptyAnimation />}

                                        </Row>
                                    </Tab.Pane>
                                    {orderStatus?.map((val) => (
                                        <Tab.Pane eventKey={val.id.toString()} key={val.id}>
                                            <Row>
                                                {orderData
                                                    ?.filter(order => order?.order_status?.id === val?.id)
                                                    ?.map((order, index) => (
                                                        <Col xl={3} md={4} xs={6} key={index}>
                                                            <ProductCard
                                                                orderGet={orderGet}
                                                                orderStatus={order}
                                                                showRating={order?.order_status?.order_status === 'Delivered'}
                                                                handleRating={handleRating}
                                                                rating={rating}
                                                                review={order?.order_status?.order_status === 'Delivered' ? "Add Review" : ""}
                                                            />
                                                        </Col>
                                                    ))
                                                }
                                                {orderData?.filter(order => order?.order_status?.id === val?.id).length === 0 && (

                                                    <Col>

                                                        <EmptyAnimation />
                                                        <div>No Order Found</div>

                                                    </Col>
                                                )}
                                            </Row>
                                        </Tab.Pane>
                                    ))}

                                </Tab.Content>
                            </Col>
                        </Tab.Container>

                    </Row>
                </div>
            </Container>
            <OrdersFilter placement="start" name={"start"} show={show} handleClose={handleClose} />
        </section>


    );
};

export default MyOrders;
