import React, { useState, useEffect, useContext } from "react";
import Table from 'react-bootstrap/Table';
import "./OrderTracking.css";
import Breadcrumbs from '../Common-Component/Breadcrumbs/Breadcrumbs';
import ViewTrackmodal from '../Common-Component/Commonmodal/ViewTrackmodal/ViewTrackmodal';
import { getOrder } from "../../utils/apis/orderProcess/orderProcess";
import { Context } from "../../utils/context";
import { colors } from "@mui/material";
const OrderTracking = () => {
    const items = [
        { id: 1, text: "HOME", link: "/home" },
        { id: 1, text: "ORDER TRACKING" },
    ]
    const {
        signin,
        setSignin, shimmerLoader, setShimmerLoader, SellerURL, formatDate } = useContext(Context);
    const [trackviewmodal, setTrackviewmodal] = useState(false);
    const [orderId, setOrderId] = useState(false);
    const [orderData, setOrderData] = useState()
    const orderGet = async () => {

        const res = await getOrder();
        if (res?.success) {
            setOrderData(res?.data)
        } else {
            console.error('Error fetching  data:', res?.error);
        }
    }

   
    useEffect(() => {
        orderGet();

    }, []);
    const handleclick = async (id) => {
        setOrderId(id)
        setTrackviewmodal(true)
    }
    return (
        <section className='OrderTracking'>
            <div className='container'>
                <Breadcrumbs items={items} />
                <div className='trackTable'>
                    <Table responsive striped bordered hover>
                        <thead>
                            <tr>
                                <th>S.No</th>
                                <th>Order No.</th>
                                <th>Order Date</th>
                                <th>Delivery Date</th>
                                <th>Total</th>
                                <th>Order Status</th>
                                <th>Payment Type</th>
                                <th>Payment Status</th>

                                <th>Order Progress</th>
                            </tr>
                        </thead>
                        <tbody>
                            {orderData?.length > 0 ? (
                                orderData.map((val, index) => (
                                    <tr>
                                        <td>{++index}</td>
                                        <td>{val?.invoice_number}</td>
                                        <td>{formatDate(val?.createdAt)}</td>
                                        <td>{formatDate(val?.updatedAt)}</td>
                                        <td>{val?.total_amount}</td>
                                        <td>{val?.order_status?.order_status}</td>
                                        <td>{val?.order?.payment_mode}</td>
                                        <td style={{ color: "#43AA00" }}> {val?.order?.payment_mode === 'online' || val?.order_status_id >= 4 ? 'Paid' : 'Unpaid'}
                                        </td>
                                    


                                        <td style={{ color: "#234C9E" }} onClick={() => handleclick(val?.id)}>View</td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="9" style={{ textAlign: 'center', fontWeight: 'bold' }}>
                                        No data available
                                    </td>
                                </tr>
                            )}

                        </tbody>
                    </Table>
                    <div className='showingentrytxt'>
                        {/* <p>Showing 3 of 3 entries</p> */}
                    </div>
                </div>
            </div>

            <ViewTrackmodal id={orderId} show={trackviewmodal} onHide={() => setTrackviewmodal(false)} />
        </section>
    )
}

export default OrderTracking