import React from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Link } from "react-router-dom";
import './Breadcrumbs.css'
const Breadcrumbs = ({ items }) => {
    return (
        <>
            <section className="ButtonMain">
                {/* <Breadcrumb>
                    {items.map((item, index) => (
                        <Breadcrumb.Item
                            active={item.active}
                            key={index}
                            linkAs={Link}
                            linkProps={item.link ? { to: item.link } : ''}
                            // linkProps={{ to:item.link }}
                        >
                            {item.text}
                        </Breadcrumb.Item>
                    ))}
                </Breadcrumb> */}
                <Breadcrumb>
                    {items.map((item, index) => (
                        <Breadcrumb.Item
                            active={item.active}
                            key={index}
                            // Conditionally set `linkAs` and `linkProps` only if `item.link` exists
                            {...(item.link ? { linkAs: Link, linkProps: { to: item.link } } : {})}
                        >
                            {item.text}
                        </Breadcrumb.Item>
                    ))}
                </Breadcrumb>
            </section>

        </>
    );
};

export default Breadcrumbs;
