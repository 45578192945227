import React, { useState, useContext, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
// import './Login.css';
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import { Link } from "react-router-dom";
import SuccessfullyModal from "../../Common-Component/Commonmodal/SuccessfullyModal/SuccessfullyModal";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import classNames from "classnames";
import { RegxExpression } from "../../../utils/common";
import {
  CheckExist,
  emailOtpGet,
  MobileOTPCheck,
  EmailOTPCheck,
} from "../../../utils/apis/myAccount/myAccount";
import PasswordSet from "./ForgetPasswordSet";
import { Context } from "../../../utils/context";

const ForgetPassword = () => {
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset,
    trigger,
  } = useForm();
  const [newpass, setNewpass] = useState(true);
  const [resetModal, setResetmodal] = useState(false);
  const [timer, setTimer] = useState(60);
  const resetClose = () => {
    setResetmodal(true);
    setTimeout(() => {
      setResetmodal(false);
      window.location = "/login";
    }, 3000);
  };

  const [otp, setOtp] = useState(["", "", "", ""]);

  const handleInputChange = (e, index) => {
    const value = e.target.value;
    if (isNaN(value) || value.length > 1) {
      return;
    }

    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    // Focus the next input field
    if (value && index < otp.length - 1) {
      document.getElementById(`otp-${index + 1}`).focus();
    }
  };

  const handleKeyDown = async (e, index) => {
    await clearErrors();
    if (e.key === "Backspace" && !otp[index] && index > 0) {
      document.getElementById(`otp-${index - 1}`).focus();
    }
  };
  const [isEmailReadOnly, setIsEmailReadOnly] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [showSetNewPassword, setShowSetNewPassword] = useState(false);
  const [showCheckYourEmail, setShowCheckYourEmail] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [showOtpFields, setShowOtpFields] = useState(false);

  const { signin, setSignin, shimmerLoader, setShimmerLoader } = useContext(
    Context
  );

  const [email, setEmail] = useState("");


  const onSubmit = async (data) => {

    const email = data.email;
    const otpString = otp.join('');
    data.otp = otpString;
    if (!isNaN(email)) {
      data.contact_no = email;
      const res = await MobileOTPCheck(data);
      if (res?.success) {
        setNewpass(false);
      } else {
        setError("otp", { message: "Invalid OTP" });
      }
    } else {
      const res = await EmailOTPCheck(data);
      if (res?.success) {
        setNewpass(false);
      } else {
        setError("otp", { message: "Invalid OTP" });
      }
    }
  };

  const handleVerifyClick = async (email) => {


    if (!email || email.trim() === "") {
      setError("email", { message: "Enter the E-mail or contact" });
      return;
    }
    await clearErrors();
    const data = { email: email };
    setShimmerLoader(true);
    const res = await CheckExist(data);
    if (res?.success) {
      //   emailOtpGet(data)
      setIsEmailReadOnly(true);
      setEmail(email);
      setShowOtpFields(true);
      setTimer(60);
    } else {
      setShimmerLoader(false);
      setError("email", { message: "User Not Found With This E-mail" });
      // setError('contact_no', { message: "" });
    }

  };
  useEffect(() => {
    if (showOtpFields && timer > 0) {
      const interval = setInterval(() => {
        setTimer(prevTimer => prevTimer - 1);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [showOtpFields, timer]);
  
  
  return (
    <div className="login-container">
      <div className="login-box">
        <div className="logosection">
          <img
            src={process.env.PUBLIC_URL + "/assest/image/logo/header-logo.png"}
          />
          <Link to={"/login"}>
            <p className="backtohome" onClick={() => setNewpass(true)}>
              Back
            </p>
          </Link>
        </div>

        <Row className="justify-content-center me-0 ms-0">
          <Col xxl={4} xl={4} md={8}>
            <div className="login-header">
              <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <Nav variant="pills">
                  <div className="row">
                    <div className="col-lg-12">
                      <Nav.Item>
                        <Nav.Link eventKey="first">
                          Reset Your Password
                        </Nav.Link>
                      </Nav.Item>
                    </div>
                  </div>
                </Nav>

                <Tab.Content className="tablogin">
                  <Tab.Pane eventKey="first">
                    {newpass ? (
                      <>
                        <div>
                          <p className="firstparatxt">
                            You will receive an email from us requesting you to
                            reset your password.
                          </p>
                          <Form
                            className="resetForm"

                          >
                            <Form.Group
                              className="mb-3"
                            >
                              <Form.Label>
                                Enter Email or Mobile Number
                              </Form.Label>
                              <div className="verify-field-holder">
                                <Form.Control
                                  type="text"
                                  name="email"
                                  placeholder="Enter Email or Mobile Number"
                                  {...register("email", {
                                    required: "Email or Mobile is required",
                                  })}
                                  onChange={(e) => {
                                    setIsEmailReadOnly(false);
                                    // Optionally, update the form value if needed
                                    // setValue("email", e.target.value);
                                }}
                                  className={classNames("", {
                                    "is-invalid": errors?.email,
                                    "is-valid": getValues("email"),
                                  })}
                                // readOnly={isEmailReadOnly}
                                />
                                {/* <div className="verify-text-holder" onClick={() => handleVerifyClick(getValues("email"))}>
                                  <p>Verify</p>
                                </div> */}
                                <button type="button" className="get-otp-btn-holder" onClick={() => handleVerifyClick(getValues("email"))}>
                                  Get Otp
                                </button>
                                {isEmailReadOnly && (
                                  <button
                                    type="button"
                                    className="verify-btn-holder"
                                    // onClick={() => handleVerifyClick(getValues("email"))}
                                  >
                                    Verify
                                  </button>
                                )}
                              </div>

                              {errors?.email && (
                                <div className="mt-md-0 mt-sm-3 mt-3">
                                  <sup className="text-danger  ">
                                    {errors?.email?.message}
                                  </sup>
                                </div>
                              )}
                            </Form.Group>

                            {showOtpFields && (
                              <Form.Group className="mb-3">
                                {timer > 0 && (
                                  <>
                                    <Form.Label>Enter OTP</Form.Label>
                                    <div className="Otp-field-holder">
                                      {otp.map((value, index) => (
                                        <Form.Control
                                          key={index}
                                          type="text"
                                          className="otp-field"
                                          maxLength="1"
                                          value={value}
                                          id={`otp-${index}`}
                                          onChange={(e) => handleInputChange(e, index)}
                                          onKeyDown={(e) => handleKeyDown(e, index)}
                                        />
                                      ))}
                                    </div>
                                  </>
                                )}
                                {timer > 0 ? (
                                  <p>Time remaining: {timer} seconds</p>
                                ) : (
                                  <>
                                  <p className="text-danger">OTP expired. <button type="button" className="resend-otp-btn-holder" onClick={() => handleVerifyClick(getValues("email"))}>
                                  Resend Otp
                                </button></p>
                                  
                                </>
                                  // <div className="verify-text-holder" onClick={() => handleVerifyClick(getValues("email"))}>
                                  //   <p className="text-danger">OTP expired. Please request a new OTP.</p>
                                  // </div>
                                )}
                                {errors?.otp && (
                                  <div className="mt-md-0 mt-sm-3 mt-3">
                                    <sup className="text-danger">
                                      {errors?.otp?.message}
                                    </sup>
                                  </div>
                                )}
                              </Form.Group>
                            )}

                            <div className="submitbtnsec text-center">
                              <Button type="submit" className="btn" onClick={handleSubmit(onSubmit)}>
                                Submit
                              </Button>
                              {/* <Button type="button" className="btn" onClick={() => setNewpass(false)}>Submit</Button> */}
                            </div>

                            <div className="canceltxtsec text-center">
                              <p>Cancel</p>
                            </div>
                          </Form>
                        </div>
                      </>
                    ) : (
                      <>
                        <PasswordSet email={email} />
                      </>
                    )}
                  </Tab.Pane>
                </Tab.Content>
                <SuccessfullyModal
                  show={resetModal}
                  onHide={() => setResetmodal(false)}
                  text={"Password Reset Successfully"}
                />
              </Tab.Container>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ForgetPassword;
