import React, { useState, useEffect, useContext } from "react";
import { Col, Row } from "react-bootstrap";

import SuccessfullyModal from "../../Common-Component/Commonmodal/SuccessfullyModal/SuccessfullyModal";
import { Checkout } from "../../../utils/apis/orderProcess/orderProcess";
import {
  postAddress,
  getAddress,
  deleteAddress,
  getSingleAddress,
  postorder,
} from "../../../utils/apis/orderProcess/orderProcess";

import useRazorpay from "react-razorpay";
import { useForm, Controller } from "react-hook-form";
import classNames from "classnames";
import { RegxExpression } from "../../../utils/common";

import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { Context } from "../../../utils/context";
import { Link, useNavigate } from "react-router-dom";
import {
  Country,
  State,
  City,
  Pincode,
} from "../../../utils/apis/master/Master";

import {
  AddToCart,
  getCart,
  deleteCart,
} from "../../../utils/apis/addToCart/addToCart";
const Order_review = ({
  handleNext,
  ShippingId,
  address_id,
  giftdata,
  giftdataArray,
  paymentMethod,
  handlePrevious,
  setAddressSelect,
  addressSelect,
}) => {
  const [orderplace, setOrderplace] = useState(false);
  // const cart = [
  //     { id: 1, image: process.env.PUBLIC_URL + "/assest/image/Profile/productImg.png", product_title: "Nike Dri-FIT Legend Tee - Men's", selling_price: 3000, mrp: 4500 },
  // ]
  const navigate = useNavigate();
  const placeOrder = () => {
    setOrderplace(true);
    setTimeout(() => {
      setOrderplace(false);
      window.location = "/home";
    }, 3000);
  };
  const [totalGiftInduasalPrice, setTotalGiftInduasalPrice] = useState(0);
  const [totalCustomePrice, setTotalcustomePrice] = useState(0);
  const {
    IMG_URL,
    signin,
    setCartCount,
    setTrigger,
    updateCartCount,
  } = useContext(Context);
  const hideModalWithDelay = () => {
    setTimeout(() => {
      setOrderplace(false);
      navigate("/home");
    }, 3000);
  };

  const [Razorpay, openRazorpay] = useRazorpay();
  const [razorid, setRazorId] = useState();
  const handleRazorpay = async (storedValue) => {
    const options: RazorpayOptions = {
      key: "rzp_test_I05KCFb1v5JJRc",
      amount: Math.round(storedValue) * 100,
      currency: "INR",
      name: "Hiclousia",
      description: "Test Transaction",
      image:
        "http://localhost:3000/static/media/hippicart-logo.427718645d3015dbf003.png",

      handler: async (res) => {
        await setRazorId(res.razorpay_payment_id);
        finalFunction(res.razorpay_payment_id);
      },
      prefill: {
        name: "Piyush Garg",
        email: "youremail@example.com",
        contact: "9999999999",
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#052c65",
      },
    };

    const rzpay = new Razorpay(options);
    rzpay.open();
  };
  const finalFunction = async (razor = null) => {
    const data = {
      shipping_address_id: ShippingId,
      billing_address_id: address_id,
      amount: cart?.message?.total,
      to: giftdata?.to,
      from: giftdata?.from,
      message: giftdata?.message,
      gift_status: giftdata?.gift_status,
      gift_details: giftdataArray,
      gift_price_total: totalGiftInduasalPrice,
      payment_mode: razor ? "online" : "cod",
    };

    if (razor) {
      data.payment_id = razor;
    }
    const res = await postorder(data);
    if (res?.success) {
      setOrderplace(true);
      setTimeout(() => {
        setOrderplace(false);
        window.location = "/home";
      }, 3000);
    }
  };

  const [cart, setCart] = useState([]);
  const [billingdata, setBillingData] = useState([]);
  const [shippingdata, setShippingData] = useState([]);
  const cartGet = async () => {
    const res = await getCart();
    if (res?.success) {
      setCart(res);
    } else {
      console.error("Error fetching data:", res?.error);
    }
  };
  const singleBillingAddress = async () => {
    const res = await getSingleAddress(address_id);
    if (res?.success) {
      setBillingData(res?.data);
    } else {
      console.error("Error fetching data:", res?.error);
    }
  };
  const singleshippingAddress = async () => {
    const res = await getSingleAddress(ShippingId);
    if (res?.success) {
      setShippingData(res?.data);
    } else {
      console.error("Error fetching data:", res?.error);
    }
  };

  useEffect(() => {
    cartGet();
    singleBillingAddress();
    singleshippingAddress();
  }, [giftdata, giftdataArray]);

  useEffect(() => {
    let total;
    if (giftdataArray && giftdataArray?.length > 0) {
      total = giftdataArray
        ?.filter((cartDetail) => cartDetail?.gift_status === 1)
        .reduce((total, cartDetail) => {
          const giftPrice = parseFloat(
            cartDetail?.gift_price * cartDetail?.quantity
          );
          return total + giftPrice;
        }, 0);
    } else if (giftdata) {
      // total = cart?.data?.reduce((total, cartDetail) => {
      //     const giftPrice = parseFloat(Number(cartDetail?.product?.product_detail?.gift_price) * cartDetail?.quantity);
      //     return total + giftPrice;
      // }, 0);
      total = cart?.message?.giftprice;
    }
    const custometotal = cart?.data?.reduce((total, cartDetail) => {
      const giftPrice = parseFloat(
        cartDetail?.customise?.custom_price * cartDetail?.quantity
      );
      return total + giftPrice;
    }, 0);

    setTotalcustomePrice(custometotal);
    setTotalGiftInduasalPrice(total);
  }, [giftdataArray, giftdata, cart]);

  return (
    <>
      <section className="stepp-inner-main">
        <div className="orde-status-dv">
          <div className="orderReviewmain">
            <div className=" colwidth">
              <p>Order Number</p>
              <p>0005-12122023</p>
            </div>
            <div className=" colwidth">
              <p>Status</p>
              <p>Ordered</p>
            </div>
            <div className=" colwidth">
              <p>Date</p>
              <p>
                {new Date().toLocaleDateString("en-US", {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                })}
              </p>
            </div>
            <div className=" colwidth">
              <p>Total</p>
              <p>
                ₹ {" "}
                {(
                    (isNaN(parseFloat(cart?.message?.total_selling))
                      ? 0
                      : parseFloat(cart?.message?.total_selling)) +
                    (isNaN(totalGiftInduasalPrice)
                      ? 0
                      : totalGiftInduasalPrice) +
                    (isNaN(totalCustomePrice) ? 0 : totalCustomePrice) +
                    (Number(totalGiftInduasalPrice || 0) +
                      Number(totalCustomePrice || 0) +
                      Number(cart?.message?.total_selling || 0)) *
                      Number(cart?.message?.taxpercent || 0)
                  ).toFixed(2)}
              </p>
            </div>
            <div className=" colwidth">
              <p>Payment method</p>
              <p>{paymentMethod}</p>
            </div>
          </div>
        </div>

        <div className="orderr-dettt">
          <h6 className="jhber">Order Details</h6>

          <div className="row">
            {cart?.data?.map((post, index) => (
              <>
                <div
                  className="col-lg-6 col-md-6 col-sm-8 col-10 detailssss-flex mt-3"
                  key={post.id}
                >
                  <div className="img-div">
                    <img
                      className="userimgg"
                      src={
                        IMG_URL +
                        post?.product_variant?.product_images[0]?.image
                      }
                      alt="Product"
                    />
                  </div>
                  <div className="contdivvv">
                    <p className="name">{post?.product?.name}</p>
                    {/* <p className='sizenumm'>38</p> */}
                  </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-4 col-2 my-auto">
                  <p className="mb-0 Prodprice">
                    ₹ {post?.product_variant?.s_price * post?.quantity}
                  </p>
                </div>
              </>
            ))}
          </div>

          <div className="row orde-status-dv">
            <div className="col-lg-8"></div>
            <div className="col-lg-4 text-end">
              <Row>
                <Col lg={6} md={10} xs={8}>
                  <p className="mb-0" style={{ fontWeight: "600" }}>
                    Subtotal
                  </p>
                </Col>

                <Col lg={6} md={2} xs={4}>
                  <p className="mb-0">
                    <strong>₹ {cart?.message?.total_selling}</strong>
                  </p>
                </Col>
              </Row>
            </div>
          </div>

          <div className="totalprieee">
            <div className="row mb-4">
              <div className="col-lg-8 col-6">
                <h6>Gift Wrap Price</h6>
              </div>
              <div className="col-lg-4">
                <div className="row flatrateMain">
                  <div className="col-lg-6 col-6">
                    <h6 className="flatRate">Total</h6>
                  </div>
                  <div className="col-lg-6 col-6">
                    <h6 className="itemPrice">
                      ₹ {totalGiftInduasalPrice || 0.0}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
            {/* {totalCustomePrice != NaN && ( */}
            <div className="row mb-4">
              <div className="col-lg-8 col-6">
                <h6>customise Price</h6>
              </div>
              <div className="col-lg-4">
                <div className="row flatrateMain">
                  <div className="col-lg-6 col-6">
                    <h6 className="flatRate">Total</h6>
                  </div>
                  <div className="col-lg-6 col-6">
                    <h6 className="itemPrice">₹ {totalCustomePrice || 0}</h6>
                  </div>
                </div>
              </div>
            </div>
            {/* )} */}
            <div className="row mb-4">
              <div className="col-lg-8 col-6">
                <h6>Total Tax</h6>
              </div>
              <div className="col-lg-4">
                <div className="row flatrateMain">
                  <div className="col-lg-6 col-6">
                    <h6 className="flatRate">Total</h6>
                  </div>
                  <div className="col-lg-6 col-6">
                    <h6 className="itemPrice">
                      ₹ {" "}
                      {(
                        (Number(totalGiftInduasalPrice || 0) +
                          Number(totalCustomePrice || 0) +
                          Number(cart?.message?.total_selling || 0)) *
                        Number(cart?.message?.taxpercent || 0)
                      ).toFixed(2)}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-lg-8 col-6">
                <h6>Payment Method</h6>
              </div>
              <div className="col-lg-4 col-6">
                <div className="row flatrateMain">
                  <div className="col-lg-6 col-6">
                    <h6 className=" flatRate">{paymentMethod}</h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="row totalbg mb-3">
              <div className="col-lg-8 col-6">
                <h6>
                  <b>Total</b>
                </h6>
              </div>

              <div className="col-lg-4 col-6">
                <h6 className="totalorderPrice">
                  ₹ 
                  {(
                    (isNaN(parseFloat(cart?.message?.total_selling))
                      ? 0
                      : parseFloat(cart?.message?.total_selling)) +
                    (isNaN(totalGiftInduasalPrice)
                      ? 0
                      : totalGiftInduasalPrice) +
                    (isNaN(totalCustomePrice) ? 0 : totalCustomePrice) +
                    (Number(totalGiftInduasalPrice || 0) +
                      Number(totalCustomePrice || 0) +
                      Number(cart?.message?.total_selling || 0)) *
                      Number(cart?.message?.taxpercent || 0)
                  ).toFixed(2)}
                </h6>
                {/* <h6 className='totalorderPrice'>₹.{cart?.message?.total+totalGiftInduasalPrice+totalCustomePrice}</h6>  */}
                {/* <h6 className='totalorderPrice'>₹.{parseFloat(cart?.message?.total ?? 0) +totalGiftInduasalPrice ?? 0+totalCustomePrice ?? 0}</h6> */}
              </div>
            </div>

            {/* <p className='earnPoints'>You will Earn: 0 Points</p> */}

            <div className="row mb-4">
              <Col lg={6} md={6}>
                <h6>
                  <b>Billing Address</b>
                </h6>
                <hr></hr>

                <p className="finaladddd">
                  {billingdata?.company}, {billingdata?.address},{" "}
                  {billingdata?.city_id?.label}, {billingdata?.state_id?.label},{" "}
                  {billingdata?.country_id?.label} -{" "}
                  {billingdata?.pincode_id?.label}
                </p>
              </Col>
              <Col lg={6} md={6}>
                <h6>
                  <b>Shipping Address</b>
                </h6>
                <hr></hr>
                <p className="finaladddd">
                  {shippingdata?.company}, {shippingdata?.address},{" "}
                  {shippingdata?.city_id?.label},{" "}
                  {shippingdata?.state_id?.label},{" "}
                  {shippingdata?.country_id?.label} -{" "}
                  {shippingdata?.pincode_id?.label}
                </p>
              </Col>
            </div>
          </div>
        </div>

        <div className="save-btn-div mt-5">
          <button type="button" className="back-btn" onClick={handlePrevious}>
            Back
          </button>
          {paymentMethod == "cod" ? (
            <button
              type="button"
              className="placeOrderbtn"
              onClick={() => finalFunction()}
            >
              Place Order
            </button>
          ) : (
            <button
              type="button"
              className="placeOrderbtn"
              onClick={() => handleRazorpay(cart?.message?.total)}
            >
              Place Order
            </button>
          )}
          <p className="editforgettxt">
            <Link to={"/mycart"}>
              <span>Forgot an Item?</span> <span>Edit Your Cart</span>
            </Link>
          </p>
        </div>
      </section>

      <SuccessfullyModal
        show={orderplace}
        onHide={() => setOrderplace(false)}
        text={"Order Placed Successfully"}
      />
    </>
  );
};

export default Order_review;
