import React, { useEffect, useRef, useState, useContext } from 'react'
import "./BlogDetails.css";
import { Col, Row } from 'react-bootstrap';
import Breadcrumbs from '../../Common-Component/Breadcrumbs/Breadcrumbs';
import { linkClasses } from '@mui/material';
import { Context } from "../../../utils/context";
import { blogDetails ,blog} from "../../../utils/apis/Blog/Blog";
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import parse from "html-react-parser";
const BlogDetails = () => {
    const articlesData = [
        {
            id: 1,
            imgSrc: process.env.PUBLIC_URL + "/assest/image/home/articlesImg2.png",
            title: "Luxury Articles to Gift",
            description: "Indulge in the art of gifting with our exquisite selection of luxury articles, meticulously curated to elevate any occasion.",
            date: "Jun 02, 2024"
        },
        {
            id: 2,
            imgSrc: process.env.PUBLIC_URL + "/assest/image/Blogs/blogImg1.png",
            title: "Luxury Articles to Gift",
            description: "Indulge in the art of gifting with our exquisite selection of luxury articles, meticulously curated to elevate any occasion.",
            date: "Jun 02, 2024"
        },
        {
            id: 2,
            imgSrc: process.env.PUBLIC_URL + "/assest/image/Blogs/blogImg2.png",
            title: "Luxury Articles to Gift",
            description: "Indulge in the art of gifting with our exquisite selection of luxury articles, meticulously curated to elevate any occasion.",
            date: "Jun 02, 2024"
        },
        {
            id: 2,
            imgSrc: process.env.PUBLIC_URL + "/assest/image/Blogs/blogImg1.png",
            title: "Luxury Articles to Gift",
            description: "Indulge in the art of gifting with our exquisite selection of luxury articles, meticulously curated to elevate any occasion.",
            date: "Jun 02, 2024"
        },

    ];

   
    const [data, setData] = useState();
    const { id } = useParams();
    const { IMG_URL } = useContext(Context);
    const getAllData = async () => {
        const res = await blogDetails(id);
        if (res?.success) {
            setData(res?.data);
        } else {
            console.error('Error fetching  data:', res?.error);
        }
    }
    const [blogData, setBlogData] = useState();
    const getAllBlogData = async () => {
        const res = await blog();
        if (res?.success) {
            setBlogData(res?.data);
        } else {
            console.error('Error fetching  data:', res?.error);
        }
    }

    useEffect(() => {
        getAllData();
        getAllBlogData();
    }, [id]);

    const items = [
        { id: 1, text: "HOME", link: "/home" },
        { id: 1, text: "BLOG", link: "/blogs" },
        { id: 1, text: data?.name },

    ]

    return (
        <section className='blogDetails'>
            <div className='container'>
                <Breadcrumbs items={items} />
                <div className='BlogdetailsMain'>
                    <Row>
                        <Col lg={4} md={4}>
                            <div className='leftSidetxt'>
                                <div className='leftSidehead'>
                                    <h3>{data?.name}</h3>
                                    <p>{typeof data?.description === 'string' ? parse(data?.description) : ""}</p>
                                    {/* <p>Posted date: Jun 02, 2024</p> */}
                                    <p>Posted date:{data?.createdAt ? new Date(data.createdAt).toLocaleDateString('en-US', {
                                        year: 'numeric',
                                        month: 'short',
                                        day: '2-digit'
                                    }) : ''}</p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={8} md={8}>
                            <div className='rightSidetxt'>
                                <img src={IMG_URL + data?.image} className='detailsImgg' />
                            </div>
                        </Col>
                    </Row>



                </div>

                <Row>
                    <Col lg={9} md={7}>
                        <div className='blogddd'>
                            {typeof data?.long_description === 'string' ? parse(data?.long_description) : ""}
                            {/* <div className='BlogInfomain'>
                                <p>When it comes to gifting, few things rival the timeless elegance and sophistication of luxury home décor items. Whether you're celebrating a special occasion, expressing gratitude, or simply adding a touch of opulence to your loved one's living space, luxury home décor gifts are sure to leave a lasting impression. Here are some exquisite ideas to inspire your next gift-giving gesture:</p>
                            </div>

                            <div className='headconinfo'>
                                <h3>1. Stunning Flower Vases</h3>
                                <p>Elevate any floral arrangement with a statement flower vase crafted from the finest materials. Opt for crystal vases adorned with intricate patterns or hand-painted porcelain vases for a touch of Old-World charm. A luxurious flower vase not only enhances the beauty of fresh blooms but also serves as a timeless keepsake</p>
                            </div>

                            <div className='headconinfo'>
                                <h3>2. Elegant Pottery:</h3>
                                <p>Gift a piece of artistry with handcrafted pottery that exudes elegance and craftsmanship. Choose from delicate porcelain tea sets, artisanal ceramic bowls, or intricately designed serving platters to add a touch of sophistication to any dining table. Each piece tells a story of tradition, skill, and creativity, making it a cherished addition to any home.</p>
                            </div>

                            <div className='headconinfo'>
                                <h3>3. Fine Crockery Sets</h3>
                                <p>Elevate any floral arrangement with a statement flower vase crafted from the finest materials. Opt for crystal vases adorned with intricate patterns or hand-painted porcelain vases for a touch of Old-World charm. A luxurious flower vase not only enhances the beauty of fresh blooms but also serves as a timeless keepsake</p>
                            </div>

                            <div className='headconinfo'>
                                <h3>4. Decorative Figurines</h3>
                                <p>Elevate any floral arrangement with a statement flower vase crafted from the finest materials. Opt for crystal vases adorned with intricate patterns or hand-painted porcelain vases for a touch of Old-World charm. A luxurious flower vase not only enhances the beauty of fresh blooms but also serves as a timeless keepsake.</p>
                            </div>


                            <div className='headconinfo'>
                                <h3>5. Statment Wall Art</h3>
                                <p>Elevate any floral arrangement with a statement flower vase crafted from the finest materials. Opt for crystal vases adorned with intricate patterns or hand-painted porcelain vases for a touch of Old-World charm. A luxurious flower vase not only enhances the beauty of fresh blooms but also serves as a timeless keepsake.</p>
                            </div> */}


                        </div>
                    </Col>
                    <Col lg={3} md={5}>
                        <div className='recentUpload'>
                            <div className='recentuploadHead'>
                                <h3>Recent Uploads</h3>
                            </div>
                            <div className='recentBlog'>
                                {blogData?.recent_post?.map(article => (
                                     <Link to={`/blog-details/${article?.id}`}>
                                    <div className='articles2' key={article.id}>
                                        <img src={IMG_URL+article?.image} alt={article.name} />
                                        <div className='overlay-color'></div>
                                        <div className='imageinfo3'>
                                            <h3>{article?.name}</h3>
                                            {/* <p>{article.description}</p> */}
                                            <p>Posted date: <b>{article?.createdAt ? new Date(data?.createdAt).toLocaleDateString('en-US', {
                                        year: 'numeric',
                                        month: 'short',
                                        day: '2-digit'
                                    }) : ''}</b></p>
                                        </div>
                                    </div>
                                    </Link>
                                ))}
                            </div>


                        </div>
                    </Col>
                </Row>
            </div>


        </section>
    )
}

export default BlogDetails